.market {
    max-width: 100%;
    height: 100%;
    min-height: calc(100vh);
    /* min-height: calc(100vh - 114px); */
    color: #666;
    background: #f5f7fa;
    font-family: IBM Plex Sans;
    margin: 0 auto;
    padding-bottom: 50px;
    padding-top: 64px;
    animation: start .4s ease-out;
}

.market.dark {
    background: #1f2029;
}

.market-box {
    width: 100%;
    max-width: 1200px;
}

.market-top1 {
    display: inline-flex;
    gap: 13px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 5px;
}

.market-top1-btn {
    position: relative;
    display: block;
    padding: 10px 16px;
    background: #fff;
    border-radius: var(--px-30);
    color: #000;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    /* width: 60px; */
}

.market-top1-btn.dark {
    border: 1px solid #cccccc16;
    background-color: #cccccc16;
    color: #fff;
}

.market-top1-btn.current {
    background: var(--main-color) !important;
    border: 1px solid var(--main-color);
    color: #fff;
    transition: all .2s ease-in-out;
}

.market-top2 {
    margin: auto 24px;
    display: flex;
    justify-content: space-between;
}

.market-title {
    height: 44px;
    color: #121214;
    font-weight: 600;
    font-size: 20px;
    line-height: 44px;
    margin: 16px 0;
}

.market-title.dark {
    color: #fff;
}

.market-search {
    padding-right: 12px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 288px;
    height: 44px;
    margin: 16px 0;
    line-height: 16px;
    background: #fff;
    border: 1px solid #d5dae0;
    border-radius: 8px;
}

.market-search.dark {
    background: #2a2c37;
    border: 1px solid #cccccc16;
}

.market-search-left {
    height: 13px;
    padding: 0 0 0 18px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.market-search-input {
    padding-left: 12px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    height: 40px;
    max-height: calc(100% - 4px);
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    font-family: IBM Plex Sans;
    border: none;
    outline: none;
}

.market-search-input.dark {
    background: #2a2c37;
    color: #fff;
}

.market-search-input-x {
    color: #cdcdcd;
    cursor: pointer;
}

.market-top3 {
    position: sticky;
    top: 0;
    z-index: 100;
    overflow: hidden;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #f3f5f7;
}

.market-top3.dark {
    border-bottom: 1px solid #cccccc16;
}

.market-top3-box {
    position: relative;
    display: flex;
    box-sizing: border-box;
    height: 48px;
    background: #f8fafc;
}

.market-top3-box.dark {
    background: #cccccc16;
}

.market-top3-tab {
    font-size: 14px;
    white-space: nowrap;
    padding: 12px 24px;
    color: #81858c;
    font-weight: 400;
    cursor: pointer;
    font-family: IBM Plex Sans;
    font-style: normal;
    line-height: 24px;
}

.market-top3-tab.current {
    color: #121214;
    font-weight: 600;
    background: #fff;
}

.market-top3-tab.current.dark {
    color: #fff;
    background: #717e8812;
}

.market-list {
    background-color: #fff;
    padding: 0;
    border-top: 1px solid #f3f5f7;
}

.market-list.dark {
    background-color: #cccccc16;
    border-top: 1px solid #cccccc16;
}

.market-list-table {
    width: 100%;
    font-family: "IBM Plex Sans", -apple-system, blinkmacsystemfont, "PingFang SC", "Microsoft YaHei", roboto, arial, sans-serif;
    border-spacing: unset;
}

.market-list-table thead tr {
    color: #81858c;
}

.market-list-table thead tr th {
    text-align: left;
    font-family: "IBM Plex Sans", -apple-system, blinkmacsystemfont, "PingFang SC", "Microsoft YaHei", roboto, arial, sans-serif;
    vertical-align: middle;
    padding: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    border-bottom: 1px solid #f3f5f7;
}

.market-list-table.dark thead tr th {
    border-bottom: 1px solid #2a2c37;
}

.market-list-table thead tr:first-child th:first-child {
    padding-left: 24px;
    width: 20%;
    max-width: 20%;
}

.market-list-table thead tr:first-child th:nth-child(2),
.market-list-table thead tr:first-child th:nth-child(3),
.market-list-table thead tr:first-child th:nth-child(6) {
    width: 15%;
    max-width: 15%;
    cursor: pointer;
}

.market-list-table thead tr:first-child th:nth-child(4),
.market-list-table thead tr:first-child th:nth-child(5) {
    width: 15%;
    max-width: 15%;
}

.market-list-table thead tr:first-child th:nth-child(7) {
    width: 5%;
    max-width: 5%;
}

.index_sort-btn__eWsx8 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 10px;
    margin-left: 6px;
}

.market-list-thead-sort {
    display: flex;
    align-items: center;
    color: #81858c;
    font-weight: 400;
    font-size: 12px;
    font-family: IBM Plex Sans;
    font-style: normal;
    line-height: 14px;
    line-height: 18px;
}

.market-list-tbody {
    position: relative;
}

.market-list-table tbody tr td {
    font-family: "IBM Plex Sans", -apple-system, blinkmacsystemfont, "PingFang SC", "Microsoft YaHei", roboto, arial, sans-serif;
    border-bottom: 1px solid #f3f5f7;
    padding: 1rem;
    padding-left: 12px;
    padding-right: 12px;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: #121214;
}

.market-list-table.dark tbody tr td {
    border-bottom: 1px solid #cccccc16;
    color: #fff;
}

.market-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .market-list-table tbody tr td:first-child {
    padding-left: 24px;
    width: 20%;
    max-width: 20%;
}

.market-list-table tbody tr:first-child td:nth-child(2),
.market-list-table tbody tr:first-child td:nth-child(3),
.market-list-table tbody tr:first-child td:nth-child(4),
.market-list-table tbody tr:first-child td:nth-child(5),
.market-list-table tbody tr:first-child td:nth-child(6) {
    width: 15%;
    max-width: 15%;
}

.market-list-table tbody tr:first-child td:nth-child(7) {
    width: 5%;
    max-width: 5%;
} */

.market-list-tbody1 {
    flex-basis: 150px;
    width: 150px;
    max-width: 150px;
    justify-content: flex-start;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #121214;
    font-weight: 600;
    font-family: IBM Plex Sans;
    font-style: normal;
}

.market-list-tbody1.link {
    padding-left: 20px;
}

.market-list-tbody1.dark {
    color: #fff;
}

.market-list-tbody1-ico {
    margin-right: 8px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex: 0 0 12px;
    color: #b5b5b5;
    cursor: pointer;
}

.market-list-tbody1-ico.link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.market-list-tbody1-img {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-right: 7px;
    width: 20px;
    height: 20px;
    flex-basis: 20px;
    display: inline-block;
}

.market-list-tbody1-img.dark {
    background-color: #fff;
}

.red {
    color: var(--red-color) !important;
}

.green {
    color: var(--green-color) !important;
}

.market-list-tbody-trade {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
    font-size: 18px;
}

.display-none-1200 {
    display: none;
}

.display-none-right {
    display: none;
}

/* 페이징 */

.paging {
    text-align: right;
    padding: 20px 24px;
    margin-top: 12px;
}

.pagebtn {
    height: 30px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    background-color: white;
}

.yellow {
    background-color: #f7a600;
}

.market-paging {
    text-align: right;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    padding: 20px 24px;
    width: calc(100% - 48px);
}

.market-paging-pages>span {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    min-width: auto;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid #d5dae0;
    border-radius: 4px;
    outline: 0px;
    cursor: pointer;
    user-select: none;
    padding: 0px;
    transition: none 0s ease 0s;
    font-size: 12px;
    line-height: 22px;
    color: #121214;
}

.market-paging-pages.dark>span {
    color: #fff;
    border: 1px solid #444444;
}

.market-paging-btn {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    min-width: auto;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid #d5dae0;
    border-radius: 4px;
    outline: 0px;
    cursor: pointer;
    user-select: none;
    padding: 0px;
    transition: none 0s ease 0s;
    font-size: 12px;
    line-height: 22px;
    color: #121214;
}

.market-paging-btn.dark {
    border: 1px solid #444444;
    color: #fff;
}

.market-paging-pages>span.active {
    background-color: var(--main-color);
    font-weight: bold;
    color: #fff;
}

.market-paging-pages.dark>span.active {
    background-color: var(--main-color);
    font-weight: bold;
    color: #fff;
}

.orange {
    color: var(--main-color);
}

.market-item-bg.up {
    animation: market_active_up .5s ease-in-out;
}

.market-item-bg.down {
    animation: market_active_down .5s ease-in-out;
}

@keyframes market_active_up {
    from {
        background-color: #9bddb023;
    }

    to {
        background-color: inherit;
    }
}

@keyframes market_active_down {
    from {
        background-color: #e0b3b327;
        color: #fff;
    }

    to {
        background-color: inherit;
        color: inherit;
    }
}

.market-item-bg.dark {
    /* animation: market_active_dark .5s ease-in-out; */
}

/* .market-item-bg {
    animation: market_active .5s ease-in-out;
}

.market-item-bg.dark {
    animation: market_active_dark .5s ease-in-out;
}


@keyframes market_active {
    from {
        background-color: #f1f1f1;
    }

    to {
        background-color: inherit;
    }
}

@keyframes market_active_dark {
    from {
        background-color: #76818b3d;
        color: #fff;
    }

    to {
        background-color: inherit;
        color: inherit;
    }
} */





@media (max-width:1300px) {
    .market-box {
        max-width: 1000px;
    }
}

@media (max-width:1070px) {
    .market-box {
        max-width: 750px;
    }

    .display-none-1000 {
        display: none;
    }
}

@media (max-width:820px) {
    .market {
        padding: 0 20px;
    }

    .market-box {
        max-width: 100%;
        padding-top: 84px;
    }

    .market-search {
        width: 180px;
    }

    .display-none-1200 {
        display: flex;
        justify-content: end;
        cursor: pointer;
    }

    .display-none-right {
        display: block;
        justify-content: end;
        text-align: end;
    }

    .display-none-800 {
        display: none;
    }
}

@media (max-width:450px) {
    .market {
        padding: 0 5px;
    }

    .market-box {
        max-width: 100%;
    }

    .market-search {
        width: 130px;
    }

    .market-top1 {
        width: auto;
    }

    .market-top2 {
        margin: auto 15px;
    }

    .market-list-table thead tr:first-child th:first-child {
        width: 65%;
        max-width: 65%;
    }
}