.tradingview_b6728 div {
    width: 100%;
}


.futures {
    width: 100%;
    min-height: calc(100vh);
    /* min-height: calc(100vh - 64px); */
    display: flex;
    padding-top: 64px;
    padding-bottom: 30px;
    gap: 5px;
    animation: start .4s ease-out;
}

.futures.dark {
    background: #1f2029;
    color: #a5a5a5;
}

.futures-box {
    padding: 0 5px;
    width: 100%;
    display: flex;
    gap: 5px;
    position: relative;
}

.futures-section1 {
    height: 865px;
    position: relative;
    width: 215px;
}

.futures-section1-ico {
    display: none;
    cursor: pointer;
}

.futures-section1-check {
    display: none;
}

.futures-section2 {
    width: calc(100% - 190px);
    height: 866px;
    display: flex;
    gap: 10px;
}

.futures-section2-check {
    width: 100%;
    height: 866px;
    display: flex;
    gap: 5px;
}

.futures-section3 {
    width: calc(100% - 350px);
    height: 866px;
}

.futures-section4 {
    height: 866px;
    width: 350px;
    position: relative;
}

.futures-section5 {
    width: 100%;
    height: 700px;
    display: flex;
    gap: 10px;
}

.futures-section6 {
    width: calc(100% - 350px);
    height: 100%;
}

.futures-section6-check {
    width: 100%;
    height: 100%;
}

.futures-section7 {
    height: 100%;
    width: 350px;
}

.futures-section7-check {
    display: none;
}

.futures-section8 {
    width: calc(100% - 2px);
    margin: 20px 0;
}

.futures-section1-box {
    height: 100%;
    padding: 0 5px;
}

.futures-section1-box.dark {
    height: 100%;
    padding: 0 5px;
    overflow-y: scroll;
}

.futures-section1-box::-webkit-scrollbar {
    width: 5px;
}

.futures-section1-box::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.futures-section1-box-position {
    position: absolute;
    width: calc(100% - 10px);
    height: auto;
    z-index: 10;
    background-color: #fff;
    top: 0px;
    padding-top: 24px;
}

.futures-section1-box-position.dark {
    background-color: #1f2029;
    color: #fff;
}

.futures-section1-box-1 {
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;
    position: relative;
}

.futures-section1-box-1.dark {
    border-bottom: 1px solid #cccccc16;
}

.futures-section1-box-1-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.futures-section1-box-1-img {
    width: 14px;
    padding-left: 10px;
}

.futures-section1-box-1-3 {
    margin-left: 10px;
    font-size: 12px;
    width: 100%;
    text-align: left;
}

.futures-section1-box-1-4 {
    font-size: 13px;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.futures-section1-box-2 {
    padding-top: 6px;
    width: 99%;
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
}

.futures-section1-box-2 input {
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    padding: 5px;
    padding-left: 31px;
    width: 100%;
    font-size: 12px;
}

.futures-section1-box-2.dark input {
    background: #2a2c37;
    border: 1px solid #2a2c37;
    color: #fff;
}

.futures-section1-box-2-ico {
    position: absolute;
    top: 15px;
    left: 10px;
    color: #5f5f5f;
    font-size: 12px;
}

.futures-section1-box-3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-top: 84px;
}

.futures-section1-box-3 .futures-3-table {
    width: 100%;
    font-size: 13px;
    background-color: #fff;
    z-index: 9;
}

.futures-section1-box-3.dark .futures-3-table {
    background: #1f2029;
    color: #fff;
}

.futures-section1-box-3 .futures-3-head {
    display: flex;
    height: 35px;
    border-bottom: 1px solid #e2e2e2;
    width: calc(100% - 12px);
}

.futures-section1-box-3.dark .futures-3-head {
    border-bottom: 1px solid #cccccc16;
}

.futures-section1-box-3 .futures-3-th {
    width: 25%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: end;
}

.futures-section1-box-3 .futures-3-th span:first-child {
    cursor: pointer;
}

.futures-section1-box-3 .futures-3-th:first-child {
    justify-content: left;
}

.futures-section1-box-3 .futures-3-body .ReactVirtualized__List::-webkit-scrollbar {
    width: 5px;
}

.futures-section1-box-3 .futures-3-body .ReactVirtualized__List::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.futures-section1-box-3 .futures-3-tr {
    display: flex;
    height: 45px;
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
    position: relative;
}

.futures-section1-box-3.dark .futures-3-tr {
    border-bottom: 1px solid #cccccc16;
}

.futures-section1-box-3 table tbody tr:first-child {
    padding-top: 40px;
}

.futures-section1-box-3 .futures-3-tr:hover {
    background-color: #f3f5f7;
}

.futures-section1-box-3 .futures-3-tr.on {
    background-color: #ededed;
    border-radius: 2px;
}

.futures-section1-box-3 .futures-3-tr.click {
    background-color: #f3f5f7;
}

.futures-section1-box-3 .futures-3-tr.dark:hover {
    background-color: #292835;
}

.futures-section1-box-3 .futures-3-tr.on.dark {
    background-color: #313341;
    border-radius: 2px;
}

.futures-section1-box-3 .futures-3-tr.click.dark {
    background-color: #292835;
}

.futures-item-body {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #121214;
    font-weight: 600;
    font-family: IBM Plex Sans;
    font-style: normal;
    width: 50%;
    position: relative;
}

.futures-item-body.dark {
    color: #bbbaba;
}

.futures-item-body-ico {
    color: #b5b5b5;
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 23px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-item-box-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.futures-item-box-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.futures-mid-top {
    width: 100%;
    height: 90px;
}

.futures-mid-top-1 {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e2e2e2;
}

.futures-mid-top-1.dark {
    border-bottom: 1px solid #cccccc16;
    color: #fff;
}

.futures-mid-top-1 table {
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;
}

.futures-mid-top-1 table thead {
    width: 100%;
}

.futures-mid-top-1 table thead tr {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

.futures-mid-top-1 table thead tr th:first-child {
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 10px;
}

.futures-mid-top-1 table thead tr th:nth-child(2) {
    width: calc(100% - 345px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 10px;
    color: #5f5f5f;
    gap: 12px;
}

.futures-mid-top-1.dark table thead tr th:nth-child(2) {
    color: #a7a7a7;
}

.futures-mid-top-1 table thead tr th:nth-child(3) {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    color: #5f5f5f;
}

.futures-mid-top-1-1 {
    width: 150px;
    padding-left: 20px;
    font-size: 17px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.futures-mid-top-1-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
}

.futures-mid-top-1-3 {
    width: 100%;
}

.futures-mid-top-1-4 {
    width: 50%;
}

.futures-mid-top-table-th-1 {
    border-bottom: 1px dashed #575e69;
    padding: 3px 0;
    overflow: hidden;
}

.futures-mid-top-table-th-2 {
    padding: 3px 0;
    overflow: hidden;
}

.futures-mid-top-table-th-span {
    color: #000;
    text-align: left;
    width: 70px;
}

.futures-mid-top-table-th-span.dark {
    color: #fff;
}

.futures-mid-top-table-th-span-1 {
    text-align: right;
    color: #000;
}

.futures-mid-top-table-th-span-1.dark {
    text-align: right;
    color: #fff;
}

.futures-mid-top-2 {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
}

.futures-mid-top-2-btn {
    width: 18%;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    padding: 5px 0;
    cursor: pointer;
}

.futures-mid-top-2-btn.dark {
    border: 1px solid #cccccc16;
}

.futures-mid-top-2-btn.active {
    background-color: #e2e2e2;
}

.futures-mid-top-2-btn.active.dark {
    background-color: #cccccc16;
}

.futures-mid-top-3 {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}

.futures-mid-top-3.dark {
    border-bottom: 1px solid #cccccc16;
}

.futures-mid-top-3-box {
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #121214;
    height: 100%;
}

.futures-mid-top-3-box.dark {
    color: #fff;
}

.futures-mid-top-3-box.active {
    color: var(--main-color);
}

.futures-mid-top-3-box-1 {
    text-align: right;
    padding-right: 2px;
    display: flex;
    align-items: center;
    justify-content: end;
    color: #121214;
}

.futures-mid-top-3-ico {
    color: #357ce1;
    font-size: 11px;
}

.futures-mid-top-3-box-select {
    text-align: left;
    border: none;
    font-size: 12px;
}

.futures-mid-top-3-box-select.dark {
    background: #cccccc16;
    border: 1px solid #cccccc16;
    color: #fff;
}

.futures-mid-top-3-box-select option {
    font-size: 12px;
    text-align: left;
}

.futures-mid-top-3-box-select.dark option {
    background: #2a2c37;
}

.futures-mid-top-11 {
    width: 100%;
    height: auto;
    margin-bottom: 5px;
}

.futures-mid-top-11 table {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

.futures-mid-top-11 table thead {
    width: 100%;
}

.futures-mid-top-11 table thead tr:first-child {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 11px;
    border-bottom: 1px solid #c0c0c0;
}

.futures-mid-top-11 table thead tr:nth-child(2) {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 11px;
    border-bottom: 1px solid #c0c0c0;
    padding: 5px 0;
}

.futures-mid-top-11 table thead tr:first-child th:first-child {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 10px;
}

.futures-mid-top-11 table thead tr:first-child th:nth-child(2) {
    width: 70%;
    display: block;
    text-align: left;
    font-size: 10px;
    color: #5f5f5f;
}

.futures-mid-top-11 table thead tr:nth-child(2) th {
    width: 100%;
    text-align: left;
}

.futures-mid-1 {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    font-weight: 500;
}

.futures-mid-2 {
    width: 20%;
    height: 100%;
    font-size: 10px;
    padding-top: 10px;
}

.futures-mid-3 {
    width: 80%;
    height: 100%;
}

.futures-left-mid-1-trade {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    font-size: 12px;
}

.futures-left-mid-1-trade-1 {
    text-align: center;
    width: 100%;
}

.futures-orderbook {
    height: 100%;
    border-left: 1px solid #f3f3f3;
}

.futures-orderbook.dark {
    border-left: 1px solid #cccccc16;
}

.futures-orderbook-1 {
    width: 100%;
    height: 290px;
    display: flex;
    align-items: end;
    justify-content: center;
}

.futures-orderbook-2 {
    width: 100%;
    height: calc(100% - 580px);
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.futures-orderbook-2.dark {
    border-top: 1px solid #cccccc16;
    border-bottom: 1px solid #cccccc16;
}

.futures-orderbook-3 {
    width: 100%;
    height: 290px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.futures-orderbook-box {
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    gap: 5px;
    border: 1px solid #fff;
    cursor: pointer;
}

.futures-orderbook-box.dark {
    border: 1px solid #1f2029;
}

.futures-orderbook-box-1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    position: relative;
    width: 44%;
}

.futures-orderbook-box-2 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 12px;
    position: relative;
    width: 28%;
    padding-right: 5px;
}

.futures-orderbook-box-3 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 13px;
    position: relative;
    width: 28%;
    padding-right: 5px;
}

.futures-orderbook-bg {
    position: absolute;
    height: 100%;
}

.futures-orderbook-2-1 {
    height: 20px;
    display: flex;
    align-items: center;
}

.futures-orderbook-2-3 {
    width: 44%;
    text-align: center;
    font-size: 11px;
}

.futures-orderbook-2-3-1 {
    width: 28%;
    text-align: right;
    font-size: 11px;
    padding-right: 5px;
}

.futures-orderbook-2-2 {
    height: 30px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    /* justify-content: center; */
}

.futures-orderbook-2-4 {
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.futures-orderbook-2-4-1 {
    padding-top: 2px;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #f5ac1d;
}

.futures-orderbook-2-4-1 span {
    border-bottom: 1px dashed #f5ac1d;
}

.futures-section8-top {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    position: relative;
}

.futures-section8-top.dark {
    border-bottom: 1px solid #cccccc16;
}

.futures-section8-top-1 {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
}

.futures-section8-top-2 {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    min-width: 85px;
}

.futures-section8-top-1-1 {
    width: 19%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    transition: all .1s ease-in-out;
}

.futures-section8-top-1-1.active {
    border-bottom: 2px solid var(--main-color);
}

.futures-section8-top-1-2 {
    width: 5%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-section8-top-1-2 input {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.futures-section8-top-1-3 {
    width: 105px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    padding: 0 10px;
    margin-right: 5px;
}

.futures-section8-top-1-3:hover {
    border: 1px solid var(--main-color);
    background-color: var(--main-color);
    color: #fff;
}

.futures-section8-bottom {
    width: 100%;
}

/* .futures-section8-bottom table {
    width: 100%;
}

.futures-section8-bottom table thead {
    width: 100%;
    height: 30px;
    font-size: 13px;
    font-weight: 500;
    color: #575e69;
}

.futures-section8-bottom table tbody {
    width: 100%;
    height: 30px;
    font-size: 12px;
    font-weight: 500;
    color: #575e69;
}

.futures-section8-bottom table thead tr th {
    width: 10%;
    text-align: center;
} */


.position {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    font-weight: 500;
    font-size: 13px;
    color: #575e69;
}

.position-1 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    gap: 2px;
}

.position-2 {
    width: 20%;
    height: auto;
    text-align: center;
}

.position-3 {
    margin-top: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.position-4 {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    margin-top: 5px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.position-4.dark {
    background-color: #cccccc16;
    color: #fff;
}

.position-4-1 {
    width: 100%;
    height: 30px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    font-size: 11px;
}

.position-table-bg-1-1 {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    background-color: #cb5057;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.position-table-bg-1-2 {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    background-color: #81262b;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-order {
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
}

.futures-order1 {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
}

.futures-order2 {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
    height: auto;
}

.futures-order-1-top {
    width: 100%;
    height: 30px;
    color: #fff;
    background-color: #24ae64;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-order-1-top.dark {
    background-color: #1c5e5e;
}

.futures-order-2-top {
    width: 100%;
    height: 30px;
    color: #fff;
    background-color: crimson;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-order-2-top.dark {
    background-color: #502d2d;
}

.futures-order-1 {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.futures-order-11 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-order-3 {
    width: 100%;
    background-color: #ebebeb;
    color: #121214;
}

.futures-order-3.dark {
    background-color: #cccccc16;
    color: #fff;
}

.futures-order-3 thead {
    width: 100%;
    height: 20px;
    font-size: 12px;
}

.futures-order-3 tr {
    width: 100%;
    height: 20px;
}

.futures-order-3 tr th:first-child,
.futures-order-3 tr th:nth-child(2) {
    width: 20%;
}

.futures-order-3 tr th:nth-child(3),
.futures-order-3 tr th:nth-child(4) {
    width: 23%;
}

.futures-order-3 tr th:nth-child(5) {
    width: 14%;
}

.futures-order-3-buy {
    font-family: Tahoma;
    color: #fff;
    background: linear-gradient(to bottom, #318ce7, #166ec5);
    height: 28px;
}

.futures-order-3-sell {
    font-family: Tahoma;
    color: #fff;
    background: linear-gradient(to bottom, #b75648, #89392d);
    height: 28px;
}

.futures-alarm {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 14px;
}

.futures-alarm-box {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.futures-alarm-1 {
    margin: 0 5px 0 15px;
}

.futures-alarm-box input {
    width: 50px;
}

.futures-multi {
    width: 100%;
    margin-top: 5px;
    font-size: 14px;
}

.futures-multi-1 {
    width: 100%;
    height: 40px;
    font-size: 11px;
    color: #575e69;
    text-align: left;
}

.futures-multi-2 {
    width: 100%;
    height: 50px;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
}

.futures-multi-2-1 {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    text-align: left;
}

.futures-multi-2-2 {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: end;
}

.futures-multi-2-1-tit {
    width: 25%;
    color: var(--main-color);
}

.futures-multi-2-1-select {
    margin-top: 10px;
    width: 50%;
}

.futures-multi-2-2-tit {
    width: 20%;
    text-align: center;
}

.futures-multi-2-2-txt {
    margin-top: 10px;
    text-align: center;
    color: var(--main-color);
}

.futures-section4-top {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid #e2e2e2;
}

.futures-section4-top.dark {
    border-bottom: 1px solid #cccccc16;
}

.futures-section4-top-btn {
    width: 15%;
    font-size: 11px;
    font-weight: bold;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.futures-section4-top-btn.dark {
    border: 1px solid #cccccc16;
}

.futures-section4-top-btn.active {
    background-color: #f3f5f7;
    color: var(--main-color);
}

.futures-section4-top-btn.active.dark {
    background-color: #cccccc16;
}

.futures-section4-top-btn-1 {
    width: 70%;
    font-size: 14px;
    font-weight: bold;
    color: var(--main-color);
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}

.futures-section4-top-btn-1.dark {
    border: 1px solid #cccccc16;
}

.futures-section4-top-btn-1.active {
    background-color: #f3f5f7;
}

.futures-section4-top-btn-1.active.dark {
    background-color: #cccccc16;
}

.futures-section4-top-1 {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2px;
}

.futures-section4-top-1-btn {
    width: 33.3%;
    font-size: 13px;
    height: 30px;
    border-bottom: 2px solid #c0c0c0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}


.futures-section4-top-1-btn:hover {
    border-bottom: 2px solid var(--main-color);
}

.futures-section4-top-1-btn.active {
    height: 30px;
    border-bottom: 2px solid var(--main-color);
    background: var(--main-color);
    font-weight: 600;
    border-radius: 4px 4px 0px 0px;
    transition: all .2s ease-in-out;
    color: #fff;
}

.futures-section4-top-1-btn.active.dark {
    color: #fff;
}

.futures-section4-top-2 {
    background: #f3f5f7;
    width: 100%;
    height: 30px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    margin-top: 10px;
    font-size: 11px;
    padding: 3px 0;
}

.futures-section4-top-2.dark {
    background: #cccccc16;
}

.futures-section4-top-2-ico {
    color: #5f5f5f;
    font-size: 16px;
    padding-left: 15px;
    width: 5%;
}

.futures-section4-top-2-1 {
    width: 35%;
    text-align: left;
}

.futures-section4-top-2-2 {
    font-size: 15px;
    width: 60%;
    text-align: end;
    padding-right: 15px;
}

.futures-section4-top-2-2 span {
    color: #121212;
}

.futures-section4-top-2-2.dark span {
    color: #fff;
}

.futures-section4-top-3-1 {
    background: #f3f5f7;
    border: 1px solid #e5e5e5;
    width: calc(100% - 2px);
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-top: 10px;
    font-size: 16px;
    padding: 3px 0;
    cursor: pointer;
    position: relative;
}

.futures-section4-top-3-1.dark {
    background: #cccccc16;
    border: 1px solid #cccccc16;
}

.futures-section4-top-3-2.filter {
    filter: blur(2px);
}

.futures-section4-top-3-2 {
    background: #fff;
    border: 1px solid #ebebeb;
    width: calc(100% - 2px);
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-top: 10px;
    font-size: 16px;
    padding: 3px 0;
    cursor: pointer;
    position: relative;
}

.futures-section4-top-3-2.dark {
    background: #cccccc16;
    border: 1px solid #cccccc16;
}

.futures-section4-top-3-1-ico {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 5px;
    color: var(--main-color);
}

.futures-section4-top-4 {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 15px;
}

.futures-section4-top-4-1 {
    width: 50%;
    height: 100%;
    border: 1px solid #e5e5e5;
    /* border: 1px solid #24ae64; */
    border-radius: 5px;
}

.futures-section4-top-4-1.dark {
    background: #2a2c37;
    border: 1px solid #cccccc16;
}

.futures-section4-top-4-2 {
    width: 50%;
    height: 100%;
    border: 1px solid #e5e5e5;
    /* border: 1px solid crimson; */
    border-radius: 5px;
}

.futures-section4-top-4-2.dark {
    background: #2a2c37;
    border: 1px solid #cccccc16;
}

.futures-section4-top-4-3 {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 3px;
}

.futures-section4-top-4-1-1 {
    font-size: 13px;
}

.futures-section4-top-4-1-2 {
    font-size: 19px;
    color: var(--green-color);
    width: 98%;
    border: 0;
    text-align: center;
    background-color: #fff;
}

.futures-section4-top-4-1-2.dark {
    background-color: #2a2c37;
}

.futures-section4-top-4-1-3 {
    font-size: 19px;
    color: var(--red-color);
    width: 98%;
    border: 0;
    text-align: center;
    background-color: #fff;
}

.futures-section4-top-4-1-3.dark {
    background-color: #2a2c37;
}

.futures-section4-top-5 {
    width: 100%;
    margin-top: 10px;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.futures-section4-top-5-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
}

.futures-section-top-5-1-1 {
    width: 33%;
    height: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.futures-section-top-5-1-2 {
    width: 17%;
    height: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-section-top-5-1-3 {
    width: 50%;
    height: 35px;
    background-color: #f3f5f7;
    border-radius: 3px;
    text-align: end;
    padding-right: 10px;
    border: none;
    font-size: 15px;
}

.futures-section-top-5-1-3.dark {
    background-color: #cccccc16;
    color: #fff;
}

.futures-section-top-5-1-4 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: end;
    font-size: 11px;
    opacity: 0.8;
    padding-top: 3px;
    gap: 5px;
}

.futures-section4-top-5-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    gap: 5px;
    font-size: 11px;
}

.futures-section4-top-5-2-1 {
    width: 25%;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-section4-top-5-2-1.dark {
    border: 1px solid #cccccc16;
}

.futures-section4-top-5-2-1:hover {
    background-color: #f3f5f7;
}

.futures-section4-top-5-2-1.dark:hover {
    background-color: #717e8812;
}

.futures-section4-top-5-2-2 {
    width: 20%;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.futures-section4-top-5-2-2.dark {
    border: 1px solid #cccccc16;
}

.futures-section4-top-5-2-2:hover {
    background-color: #f3f5f7;
}

.futures-section4-top-5-2-2.dark:hover {
    background-color: #717e8812;
}

.futures-section4-top-5-3 {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 15px;
}

.futures-section4-top-5-3-1 {
    width: 50%;
    height: 100%;
    border: 1px solid var(--green-color);
    border-radius: 5px;
    cursor: pointer;
}

.futures-section4-top-5-3-2 {
    width: 50%;
    height: 100%;
    border: 1px solid var(--red-color);
    border-radius: 5px;
    cursor: pointer;
}

.futures-section4-top-5-3-1-1 {
    font-size: 15px;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green-color);
    color: #fff;
}

.futures-section4-top-5-3-1-1.dark {
    background-color: var(--green-color);
}

.futures-section4-top-5-3-1-2 {
    font-size: 15px;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--red-color);
    color: #fff;
}

.futures-section4-top-5-3-1-2.dark {
    background-color: var(--red-color);
}

.futures-section4-top-5-4 {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.futures-section4-top-5-4-1 {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 11px;
}

.futures-section4-top-5-4-2 {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-section4-top-5-4-3 {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-section4-top-6 {
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 5px;
}

.futures-section4-top-6-1 {
    width: 33.3%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-section4-top-6-2 {
    width: 100%;
}

.futures-section4-top-6-1-ico {
    font-size: 14px;
    color: #5f5f5f;
}

.futures-section4-top-6-1-tit {
    font-size: 13px;
}

.futures-section4-top-6-1-tit.active {
    color: var(--main-color);
}

.futures-section4-top-7 {
    width: 100%;
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.futures-section4-top-7-select {
    width: 70%;
    outline: none;
    border: 1px solid #e5e5e5;
}

.futures-section4-top-7-select.dark {
    background: #2a2c37;
    color: #ababab;
    border: 1px solid #cccccc16;
    ;
}

.futures-section4-top-7-input {
    width: 16px;
    height: 16px;
    border: 1px solid #ebebeb;
    cursor: pointer;
    margin-right: 7px;
}

.futures-section4-top-7-tit {
    text-align: left;
    font-size: 14px;
}

.futures-section4-top-7-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.futures-section4-top-7-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.futures-section4-top-8 {
    width: 100%;
    height: auto;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.futures-section4-top-8.dark {
    border: 1px solid #cccccc16;
}

.futures-section4-top-8-1 {
    width: 50%;
}

.futures-section4-top-8-2 {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 11px;
}

.futures-section4-top-8-3 {
    text-align: left;
    padding-left: 5px;
}

.futures-section4-top-8-4 {
    text-align: right;
    padding-right: 5px;
}

.futures-section4-top-9 {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 13px;
}

.futures-section4-top-9-1 {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
}

.futures-section4-top-9-2 {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    text-align: right;
}

.futures-section4-top-10 {
    width: 100%;
    height: auto;
    margin-top: 10px;
    font-size: 13px;
}

.futures-section4-top-10-1 {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-section4-top-10-1-1 {
    width: 50%;
    height: 100%;
    text-align: left;
}

.futures-section4-top-10-1-2 {
    width: 25%;
    height: 100%;
    text-align: right;
}

.futures-dual {
    width: 100%;
    height: calc(100% - 50px);
}

.futures-dual-top {
    width: 100%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.futures-dual-bottom {
    width: 100%;
    height: calc(100% - 160px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    border-top: 1px solid #ebebeb;
}

.futures-dual-bottom.dark {
    border-top: 1px solid #cccccc16;
}

.futures-dual-bottom-1 {
    width: 50%;
    height: 100%;
}

.futures-dual-bottom-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.futures-dual-bottom-top-1 {
    width: 35%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-dual-bottom-top-2 {
    width: 65%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-dual-bottom-bottom {
    width: 100%;
    height: calc(100% - 20px);
}

.futures-dualup {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    height: 23px;
    font-size: 12px;
    position: relative;
    cursor: pointer;
}

.futures-dualup1 {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    height: 23px;
    font-size: 12px;
    position: relative;
    cursor: pointer;
}

.futures-dualup-1 {
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.futures-dualup-2 {
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.futures-dualup-bg {
    position: absolute;
    height: 100%;
}

.futures-dual-top-img-1 {
    width: 270px;
    height: 145px;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
}

.futures-dual-top-img-2 {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    width: 22px;
    transform-origin: 50% 90.7%;
    transition: all .1s;
    z-index: 2;
}

.futures-dual-top-1 {
    position: absolute;
    left: 10px;
    top: 20px;
    font-size: 13px;
    text-align: left;
}

.futures-dual-top-2 {
    position: absolute;
    right: 10px;
    top: 20px;
    font-size: 13px;
    text-align: right;
}

.futures-dual-top-3 {
    position: absolute;
    bottom: 30px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: bold;
}

.futures-speed {
    width: 100%;
    height: calc(100% - 50px);
}

.futures-speed-top {
    width: 100%;
    height: 30px;
}

.futures-speed-top-1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.futures-speed-top-1-1 {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-speed-top-1-2 {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-speed-mid {
    width: 100%;
    height: calc(100% - 30px);
}

.futures-speed-mid-1 {
    width: 100%;
    height: 283px;
    margin-bottom: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.futures-speed-mid-2 {
    width: 100%;
    height: calc(100% - 580px);
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 14px;
    gap: 10px;
    padding-left: 10px;
}

.futures-speed-mid-3 {
    width: 100%;
    height: 283px;
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.futures-speed-mid-2-1 {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-speed-mid-2-2 {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    font-weight: bold;
}

.futures-speed-mid-2-1-btn {
    width: 90%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #357ce1;
    border-radius: 1px;
    color: #fff;
}

.futures-speedshort {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 13px;
    margin-top: 3px;
    position: relative;
}

.futures-speedshort-1 {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-speedshort-2 {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-speedshort-1-btn {
    border: 1px solid crimson;
    width: 80%;
    height: 100%;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-speedlong {
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    margin-top: 3px;
    position: relative;
}

.futures-speedlong-1 {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-speedlong-2 {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-speedlong-1-btn {
    border: 1px solid #24ae64;
    width: 80%;
    height: 100%;
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.futures-section6-ico {
    font-size: 16px;
    padding-top: 1px;
}

.futures-section6-ico.current {
    font-size: 16px;
    padding-top: 1px;
    color: #ebb54b;
}

.display-none-700-flex {
    display: none;
}

.display-none-700-block {
    display: none;
}

.futures-section9 {
    width: 100%;
    height: auto;
}

.futures-section10 {
    width: 100%;
    height: auto;
    display: flex;
    gap: 5px;
}

.futures-margin-modal {
    background: rgba(0, 0, 0, .3);
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    text-align: left;
}

.futures-margin-modal-1 {
    max-width: 500px;
    width: 100% !important;
    padding: 0 24px;
    margin: auto;
}

.futures-margin-modal-1-1 {
    background: #fff;
    padding: 36px;
    border-radius: var(--px-15);
    position: relative;
}

.futures-margin-modal-1-1.dark {
    background: #1f2029;
    color: #fff;
    border: 1px solid #cccccc16;
}

.futures-margin-modal-1-1-tit {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.2;
}

.futures-margin-modal-1-1-wrap {
    margin-top: 12px;
    width: 100% !important;
    display: inline-grid !important;
    background: #fff;
    border-radius: var(--px-15);
    position: relative;
    text-align: left;
}

.futures-margin-modal-1-1-wrap.dark {
    background: #1f2029;
}

.futures-margin-modal-1-1-txt {
    width: calc(100% - 48px);
    display: inline-flex;
    align-items: center;
    gap: 6px;
    border-radius: 10px;
    padding: 12px 24px;
    position: relative;
}

.futures-margin-modal-1-1-txt.dark {
    border: 1px solid #cccccc16;
}

.futures-margin-modal-1-1-txt input {
    width: 100%;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
    appearance: none;
    opacity: .5;
}

.futures-margin-modal-1-1-box {
    position: absolute;
    top: 50px;
    right: -1px;
    z-index: 100;
    background: #fff;
    padding: 24px;
    border-radius: var(--px-15);
    box-shadow: rgb(18 18 18 / 8%) 0px 5px 12px;
}

.futures-margin-modal-1-1-btn {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-size: 16px !important;
    margin-top: 24px;
    text-align: center;
}

.futures-margin-modal-1-1-btn-1 {
    background: var(--main-color) !important;
    width: 100%;
    color: #fff !important;
    padding: 12px 16px;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    font-size: 14px;
    cursor: pointer;
}

.futures-margin-modal-1-1-btn-2 {
    background: #2a2c37 !important;
    width: 100%;
    color: #fff !important;
    padding: 12px 16px;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    font-size: 14px;
    cursor: pointer;
}

.futures-margin-modal-1-2 {
    background: #fff;
    padding: 36px 20px;
    border-radius: var(--px-15);
    position: relative;
}

.futures-margin-modal-1-2.dark {
    background: #1f2029;
    color: #fff;
    border: 1px solid #cccccc16;
}

.futures-margin-modal-1-2-txt {
    width: calc(100% - 48px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: 1px solid #f7f7f7;
    border-radius: var(--px-30);
    padding: 12px 24px;
    position: relative;
    margin-top: 15px;
}

.futures-margin-modal-1-2-txt.dark {
    border: 1px solid #cccccc16;
}

.futures-margin-modal-1-2-txt:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.futures-margin-modal-1-2-txt input {
    width: 100%;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
    appearance: none;
    opacity: .5;
}

.futures-margin-modal-1-2-btn-1 {
    background: var(--main-color) !important;
    width: 100%;
    color: #fff !important;
    padding: 12px 10px;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    font-size: 14px;
    cursor: pointer;
}

.futures-margin-modal-1-2-btn-2 {
    background: #2a2c37 !important;
    width: 100%;
    color: #fff !important;
    padding: 12px 10px;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    font-size: 14px;
    cursor: pointer;
}




.futures-margin-output {
    margin-top: 5px;
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.f-trade-history {
    width: 100%;
    height: calc(100% - 90px);
    border: 1px solid #e2e2e2;
}

.f-trade-history.dark {
    border: 1px solid #cccccc16;
}

.f-trade-history-1 {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
}

.f-trade-history-1-img {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.f-trade-history-1-img span {
    font-size: 14px;
}

.f-trade-history-1-date {
    font-size: 13px;
    text-align: center;
    border: 0px;
    background: #f9f9f9;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.f-trade-history-1-date.dark {
    background: #2a2c37;
}

.f-trade-history-1-btn {
    border: none;
    transition: all .2s ease-in-out;
    cursor: pointer;
    padding: 5px 16px;
    font-size: 13px;
    background: var(--main-color) !important;
    color: #fff !important;
    border-radius: var(--px-30);
}

.f-trade-history-2 {
    width: 100%;
    height: 553px;
    /* overflow-y: scroll; */
}

.f-trade-history-2::-webkit-scrollbar {
    width: 5px;
}

.f-trade-history-2::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.f-trade-history-2-1 {
    border-collapse: collapse;
    font-size: 12px;
    color: #121214;
    border: 1px solid #e5e5e5;
    font-weight: 400;
    width: calc(100% - 2px);
    position: relative;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.f-trade-history-2-1.dark {
    border: 1px solid #cccccc16;
    background-color: #cccccc16;
    color: #fff;
}

.f-trade-history-2-1-1 {
    width: 17%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.f-trade-history-2 table tbody tr {
    border-bottom: 1px solid #f1f1f1;
}

.f-trade-history-2.dark table tbody tr {
    border-bottom: 1px solid #cccccc16;
}

.f-trade-history-2 table tbody tr td {
    width: 14%;
    height: 35px;
}

.f-trade-history-1-date.dark .react-datepicker__input-container input {
    background: #2a2c37;
    color: #fff;
}

.f-trade-history-norecrods {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
}

.f-trade-history-norecrods img {
    width: 150px;
    margin-bottom: 12px;
}

.f-trade-history-other {
    background-color: #f5f5f5;
}

.f-trade-history-other.dark {
    background-color: #2a2c37;
    color: #fff;
}

.f-trade-history-other th {
    width: 16%;
    height: 25px;
}

.futures-section4-top1 {
    width: 100%;
    height: 90px;
}

.position-box {
    width: calc(100% - 10px);
    max-height: 300px;
    height: auto;
    overflow-y: scroll;
    padding: 0 5px;
}

.position-box::-webkit-scrollbar {
    width: 5px;
}

.position-box::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.orderbook-error {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
}

.orderbook-error svg {
    font-size: 30px;
    cursor: pointer;
    color: gray;
}

.hide {
    display: none;
}

.futures-margin-modal-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 13px;
}

.futures-margin-modal-2-1 {
    width: 45px;
    height: 25px;
    margin-top: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.futures-margin-modal-2-1.dark {
    border: 1px solid #cccccc16;
}

.futures-margin-modal-2-1:hover {
    background-color: #efeeee;
}

.futures-margin-modal-2-1.dark:hover {
    background-color: #717e8812;
}

.futures-reverage-input {
    width: 90px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(245 172 29 / 20%) 0px 0px 24px;
    border: 1px solid rgb(245 172 29 / 25%);
    transition: all .2s ease-in-out;
}

.futures-reverage-input div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
}

.futures-section4-liq-annotation {
    font-size: 10px;
    padding: 3px;
    text-align: left;
    color: gray;
}

.futures-section4-liq-annotation.dark {
    color: inherit;
}
































/* 반응형 */

@media (max-width:1550px) {
    .futures-section1 {
        position: absolute;
        top: 19px;
        left: 0;
        background-color: #fff;
        z-index: 100;
        border: 1px solid #c1c1c1;
    }

    .futures-section1.dark {
        background-color: #1f2029;
        border: 1px solid #cccccc16;
    }

    .futures-section1-ico {
        display: block;
        position: absolute;
        top: 5px;
        right: 15px;
        color: #121214;
        font-size: 20px;
        z-index: 100;
    }

    .futures-section1-ico.dark {
        color: #fff;
    }

    .futures-section2 {
        width: 100%;
    }
}


@media (max-width:1300px) {
    .futures-section6 {
        width: 100%;
        height: auto;
    }

    .futures-section7 {
        width: 50%;
        min-width: 50%;
    }

    .futures-section4 {
        width: 50%;
        height: auto;
        min-width: auto;
    }

    .display-none-1300 {
        display: none !important;
    }
}

@media (max-width:1000px) {
    .display-none-1000 {
        display: none !important;
    }

    .futures-mid-top {
        width: 100%;
        height: 55px;
    }

    .futures-section8-top-1-1 {
        width: 30%;
    }

    .futures-order1 {
        flex-wrap: wrap;
        gap: 10px;
    }

    .futures-order-1 {
        width: 100%;
    }

    .futures-section8 {
        height: auto;
        margin-bottom: 20px;
    }

    .position {
        display: block;
        padding: 5px 10px 10px;
        width: calc(100% - 22px);
        border: 1px solid #e7e5e5;
        border-radius: 10px;
        margin: 10px 0;
    }

    .position-1 {
        width: 100%;
    }

    .futures-order-1-top {
        height: 20px;
        font-size: 12px;
    }

    .futures-order-2-top {
        height: 20px;
        font-size: 11px;
    }

    .futures-order-3 thead {
        font-size: 11px;
    }

    .futures-order-3 thead {
        font-size: 11px;
    }

    .futures-order-3-buy {
        height: 20px;
        font-size: 11px;
    }

    .futures-order-3-sell {
        height: 20px;
        font-size: 11px;
    }
}

@media (max-width:700px) {
    .display-none-700 {
        display: none !important;
    }

    .display-none-700-flex {
        display: flex !important;
    }

    .display-none-700-block {
        display: block !important;
    }

    .futures-mid-3 {
        width: 100%;
    }

    .futures-orderbook {
        border-left: none;
    }

    .futures-orderbook-bg {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .futures-orderbook-box-1 {
        width: 33.3%;
        justify-content: center;
    }

    .futures-orderbook-box-2 {
        width: 33.3%;
        justify-content: end;
    }

    .futures-orderbook-2-3 {
        width: 33.3%;
        text-align: center;
    }

    .futures-orderbook-2-3-1 {
        width: 33.3%;
        text-align: center;
    }

    .futures-mid-top-3 {
        justify-content: end;
        height: 40px;
    }

    .futures-section10 {
        gap: 2px;
    }

    .futures-section8-top-1-1 {
        width: 40%;
    }

    .futures-section4-top-9 {
        border-top: 1px solid #c1c1c1;
    }

    .futures-section4-top {
        border-bottom: none;
    }

    .futures-mid-top {
        width: 100%;
        height: auto;
    }

    .futures-section4-top-4-1-2 {
        font-size: 15px;
    }

    .futures-section4-top-4-1-3 {
        font-size: 15px;
    }

    .futures-section4-top-2-2 {
        font-size: 13px;
    }

    .futures-section-top-5-1-3 {
        font-size: 13px;
    }

    .futures-section4-top-2 {
        gap: 5px;
    }

    .futures-section4-top-3-1 {
        height: 30px;
        font-size: 14px;
    }

    .futures-section4-top-3-2 {
        height: 30px;
        font-size: 14px;
    }
}

@media (max-width:500px) {
    .futures-display-none-500 {
        display: none;
    }

    .futures-section4-top-2-2 {
        font-size: 12px;
        padding-right: 8px;
        width: 55%;
    }

    .futures-section4-top-4-1-1 {
        font-size: 11px;
    }

    .futures-section4-top-4-1-2 {
        font-size: 12px;
    }

    .futures-section4-top-4-1-3 {
        font-size: 12px;
    }

    .futures-section4-top-4 {
        height: 45px;
        gap: 5px;
    }

    .futures-section-top-5-1-1 {
        font-size: 12px;
    }

    .futures-section-top-5-1-2 {
        font-size: 13px;
    }

    .futures-section-top-5-1-3 {
        font-size: 13px;
        height: 30px;
    }

    .futures-section4-top-5-2 {
        gap: 3px;
    }

    .futures-section4-top-5-3-1-1 {
        font-size: 13px;
    }

    .futures-section4-top-5-3-1-2 {
        font-size: 13px;
    }

    .futures-section4-top-5-3 {
        height: 35px;
        gap: 5px;
    }

    .futures-section4-top-6-1-tit {
        font-size: 11px;
    }

    .futures-section4-top-6-1 {
        align-items: start;
    }

    .futures-section4-top-6 {
        height: 45px;
    }

    .futures-section4-top-7 {
        gap: 10px;
    }

    .futures-section4-top-7-tit {
        font-size: 11px;
    }

    .futures-section4-top-7-select {
        font-size: 10px;
        height: 20px;
        width: 100%;
    }

    .futures-section4-top-8 {
        display: block;
    }

    .futures-section4-top-8-1 {
        width: 100%;
    }

    .futures-section4-top-8-3 {
        width: 50%;
    }

    .futures-section4-top-8-4 {
        width: 50%;
    }

    .futures-section4-top-btn {
        font-size: 9px;
        width: 20%;
    }

    .futures-section4-top-btn-1 {
        width: 60%;
    }

    .futures-box {
        padding: 10px 5px 0;
    }

    .futures-mid-top-3 {
        margin: 0 5px;
        width: auto;
    }

    .futures-orderbook-box-1 {
        font-size: 9px;
    }

    .futures-orderbook-box-2 {
        font-size: 9px;
    }

    .futures-orderbook-2-3 {
        font-size: 10px;
    }

    .futures-orderbook-2-3-1 {
        font-size: 10px;
    }

    .futures-orderbook-2-4 {
        font-size: 15px;
    }

    .futures-orderbook-2-4-1 {
        font-size: 11px;
    }

    .futures-section4 {
        width: 55%;
        height: auto;
    }

    .futures-section7 {
        width: 45%;
        min-width: 45%;
    }

    .futures-section4-top {
        height: 40px;
    }

    .futures-section8-top-1-3 {
        width: 80px;
        height: 22px;
        padding: 0px;
        font-size: 9px;
    }

    .position {
        font-size: 9px;
    }

    .position-3 {
        height: 20px;
    }

    .position-4 {
        height: 25px;
        font-size: 9px;
    }

    .position-4-1 {
        height: 25px;
    }

    .position-table-bg-1-1 {
        height: 25px;
    }

    .position-table-bg-1-2 {
        height: 25px;
    }

    .futures-section8-top-1-1 {
        width: 50%;
        font-size: 9px;
    }

    .futures-margin-modal-1-1 {
        padding: 20px;
    }

    .futures-margin-modal-1-1-txt {
        width: 100%;
        padding: 0;
    }

    .futures-section4-top-5-4-2 {
        width: 33.3%;
    }

    .futures-section-top-5-1-4 {
        font-size: 9px;
    }

    .futures-section4-top-5-2-1 {
        height: 27px;
        font-size: 10px;
    }

    .futures-section4-top-8-2 {
        height: 22px;
        font-size: 10px;
    }

    .futures-section4-top-5-2-2 {
        height: 27px;
        font-size: 10px;
    }

    .futures-section4-top-5-4 {
        font-size: 14px;
    }

    .futures-section1 {
        top: 50px;
    }

    .futures-section4-top-1-btn {
        font-size: 11px;
    }

    .f-trade-history-1-date {
        font-size: 11px;
    }

    .futures-order-1-top {
        font-size: 11px;
    }

    .futures-order-2-top {
        font-size: 11px;
    }

    .futures-order-3 thead {
        font-size: 9px;
    }

    .futures-order-3-buy {
        font-size: 9px;
    }

    .futures-order-3-sell {
        font-size: 9px;
    }

    .futures-order-3 tr th:first-child {
        width: 20%;
    }

    .futures-order-3 tr th:nth-child(2) {
        width: 13%;
    }

    .futures-order-3 tr th:nth-child(3) {
        width: 25%;
    }

    .futures-order-3 tr th:nth-child(4) {
        width: 32%;
    }

    .futures-order-3 tr th:nth-child(5) {
        width: 10%;
    }

    .futures-section4-top-2-ico {
        font-size: 12px;
        padding-left: 8px;
    }

    .futures-section4-top-2-1 {
        width: 40%;
    }

    .futures-section4-top-2 {
        font-size: 10px;
    }
}