/* logo_color */
.ay_logo-1 {
    fill: #3f54d1;
}

.ay_logo-2 {
    fill: #5280e2;
}

.ay_logo-3 {
    fill: #4fe0b5;
}

.ay_logo-4 {
    fill: #0e0e0e;
}

html[color-scheme=dark] .ay_logo-4 {
    fill: #fff;
}

.ay_logo-5 {
    fill: #15cdcb;
}

/* header */
.header {
    width: 100%;
    position: absolute;
    top: 0px;
    background: transparent;
    /* transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; */
    z-index: 9997;
}

.header-box {
    width: 100%;
    height: 64px;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    background-color: #fff;
    /* border-bottom: 1px solid #ebebeb; */
}

.header-box.dark {
    background-color: #1f2029;
    /* border-bottom: 1px solid #cccccc16; */
    color: #fff;
}

.header-log {
    padding-left: 24px;
    padding-top: 5px;
}

.header-log img {
    width: 90px;
    /* height: 35px; */
}

.header-menu {
    width: 100%;
    padding-left: 24px;
}

.header-menu>ul {
    width: 100%;
    display: inline-flex;
    align-items: center;
    /* gap: 30px; */
    white-space: nowrap;
}

.header-menu>ul>li {
    position: relative;
    height: 64px;
    display: inline-flex;
    align-items: center;
    /* gap: 3px; */
    padding: 0 15px;
    cursor: pointer;
}

.header-menu-arrow {
    width: 12px !important;
    height: 12px !important;
    transition: all ease .5s;
    overflow: hidden;
}

.header-dropdown {
    display: none;
    height: 581px;
    padding-top: 16px;
    margin-top: 0px;
    padding-bottom: 16px;
    margin-bottom: 0px;
    position: absolute;
    z-index: 9998;
    top: 64px;
    left: 0;
    background: #fff;
    color: #121212;
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
    min-width: 360px;
    max-width: 360px;
    padding: 16px;
    border-radius: var(--px-15);
}

.header-dropdown>li {
    position: relative;
    width: 100%;
}

li {
    list-style: none;
}

ul,
ol {
    padding-right: 0px;
}

ul,
li {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.header-dropdown>li>a {
    display: block;
    padding: 12px 16px;
    border-radius: var(--px-15);
    white-space: normal;
}

.header-dorpdown-box {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.header-dropdown-box-ico {
    width: 24px !important;
    height: 24px !important;
    display: inline-flex;
    align-items: center;
}

.header-dropdown-box-ico svg {
    width: 20px !important;
    height: 20px !important;
}

.header-dropdown-box-wrap {
    width: 100%;
}

.header-dropdown-box-wrap-menu {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    white-space: normal;
}

.header-dropdown-box-wrap-txt {
    white-space: normal;
    font-size: 13px;
    font-weight: 300;
    opacity: .5;
}

.header-dropdown-box-arrow {
    transition: all .5s ease-in-out;
    opacity: 0;
    width: 18px;
    height: 18px;
}

.header-right {
    width: auto;
    display: inline-flex;
    align-items: center;
    /* gap: 36px; */
    gap: 25px;
    white-space: nowrap;
    padding-right: 24px;
}

.header-right>ul {
    width: 100%;
    display: inline-flex;
    align-items: center;
    /* gap: 30px; */
    white-space: nowrap;
}

.header-right-regist {
    background: var(--main-color);
    /* background: var(--main-color); */
    color: #fff !important;
    padding: 6px 16px;
    border-radius: var(--px-30);
}

.header-right-icon {
    max-width: 180px;
    width: 100%;
}

.header-right-icon>ul {
    width: 100%;
    display: inline-flex;
    align-items: center;
    /* gap: 20px; */
    white-space: nowrap;
}

.header-right-icon>ul>li {
    position: relative;
    height: 64px;
    display: inline-flex;
    align-items: center;
    gap: 3px;
}

.header-right-icon {
    max-width: 180px;
    width: 100%;
}

.header-right-icon>ul>li {
    position: relative;
    height: 64px;
    display: inline-flex;
    align-items: center;
    /* gap: 3px; */
    padding: 0 10px;
    cursor: pointer;
}

.header-right-icon-1 {
    width: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header-right-icon-1 svg {
    fill: #121212;
}

.header-right-icon-1.dark svg {
    fill: #fff;
}

.header-right-icon-1-dropdown {
    position: absolute;
    z-index: 9998;
    top: 55px;
    left: 0;
    background: #fff;
    color: #121212;
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
    padding: 16px;
    border-radius: var(--px-15);
    margin-top: 0px;
    margin-bottom: 0px;
    min-width: 130px !important;
    max-width: 130px !important;
    right: -40px;
    left: auto !important;
}

.header-right-icon-1-dropdown.dark {
    background: #2a2c37;
    color: #fff;
}

.header-right-icon-1-dropdown>li>a {
    display: block;
    padding: 12px 16px;
    border-radius: var(--px-15);
    white-space: normal;
}

.header-right-icon-1-dropdown>li>a:hover {
    background: #f9f9f9;
}

.header-right-icon-1-dropdown.dark>li>a:hover {
    background: #717e8812;
}

.header-right-icon-1-dropdown-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.header-right-icon-1-dropdown-ico {
    width: 24px !important;
    height: 24px !important;
    display: inline-flex;
    align-items: center;
}

.header-right-icon-1-dropdown-ico img {
    width: 100%;
}

.header-right-icon-1-dropdown-wrap {
    width: 100%;
    display: flex;
    align-items: center;
}

.header-right-icon-1-dropdown-tit {
    font-size: 15px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    white-space: normal;
}

.header-right-sun {
    --size: 20px;
    --icon-fill: #FFD619;
    --icon-fill-hover: #ffd200;
    background: none;
    border: none;
    padding: 0;
    inline-size: 20px;
    block-size: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    outline-offset: 0.25rem;
    cursor: pointer;
}

.header-right-moon {
    --icon-fill: #fff;
    --icon-fill-hover: #f9f9f9;
}

.header-right-sun>svg {
    inline-size: 100%;
    block-size: 100%;
    stroke-linecap: round;
}

.im_pt_dl_sun_moon> :is(.moon, .sun) {
    fill: #ffd619 !important;
}

.header-right-moon> :is(.moon, .sun) {
    fill: #fff !important;
}

.im_pt_dl_sun_moon> :is(.moon, .sun, .sun-beams) {
    transform-origin: center center;
}

.im_pt_dl_sun_moon>.sun-beams {
    stroke: #ffd619;
    stroke-width: 0.125rem;
}

.toggle-menu-sun-icon {
    margin-right: 20px;
    color: #f7a600;
    font-size: 20px;
}

.toggle-menu-moon-icon {
    margin-right: 20px;
    color: #fff;
    font-size: 20px;
}

.toggle-menu-sun-icon-1 {
    color: #f7a600;
    width: 20px !important;
    font-size: 19px;
}

.toggle-menu-moon-icon-1 {
    color: #fff;
    width: 20px !important;
    font-size: 19px;
}

@media (prefers-reduced-motion: no-preference) {
    @supports (cx: 1) {
        .im_pt_dl_sun_moon .moon>circle {
            transition: cx 0.25s cubic-bezier(0, 0, 0, 1);
        }
    }

    @media (prefers-reduced-motion: no-preference) {
        .im_pt_dl_sun_moon .moon>circle {
            transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
        }
    }
}

@media (prefers-reduced-motion: no-preference) {
    .im_pt_dl_sun_moon>.sun {
        transition: transform 0.5s cubic-bezier(0.5, 1.25, 0.75, 1.25);
    }
}

.header-menu-dropdown {
    position: absolute;
    z-index: 9998;
    top: 55px;
    left: -15px;
    background: #fff;
    color: #121212;
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
    min-width: 300px;
    max-width: 300px;
    padding: 15px;
    border-radius: var(--px-15);
    height: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
}

.header-menu-dropdown.dark {
    background: #2a2c37;
    color: #fff;
}

.header-menu-dropdown>li {
    position: relative;
    width: 100%;
}

.header-menu-dropdown>li>a {
    display: block;
    padding: 12px 16px;
    border-radius: var(--px-15);
    white-space: normal;
}

.header-menu-dropdown>li>div {
    display: block;
    padding: 12px 16px;
    border-radius: var(--px-15);
    white-space: normal;
}

.header-menu-dropdown>li>a:hover {
    background: #f9f9f9;
}

.header-menu-dropdown.dark>li>a:hover {
    background: #717e8812;
}

.header-menu-dropdown-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.header-menu-dropdown-icon {
    width: 24px !important;
    height: 24px !important;
    display: inline-flex;
    align-items: center;
}

.header-menu-dropdown-icon svg {
    width: 20px !important;
    height: 20px !important;
}

.header-menu-dropdown-wrap {
    width: 100%;
}

.header-menu-dropdown-wrap-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    white-space: normal;
}

.header-menu-dropdown-wrap-2 {
    white-space: normal;
    font-size: 13px;
    font-weight: 300;
    opacity: .5;
}

.header-menu-dropdown-arrow {
    transition: all .5s ease-in-out;
    width: 18px;
    height: 18px;
}

.st0 {
    fill: var(--main-color);
}

.st1 {
    opacity: 0.4;
    fill: #141414;
}

.dark .st1 {
    opacity: 0.4;
    fill: #f9f9f9;
}

.header-menu-dropdown-arrow .st0 {
    fill: none;
    stroke: var(--main-color);
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.header-menu-dropdown-arrow .st1 {
    fill: none;
    stroke: var(--main-color);
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.header-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top: 0;
    left: 0;
}

.header-alert {
    width: calc(100% - 30px);
    height: auto;
    padding: 15px;
    background-color: #fff;
    border-bottom: 1px solid #c4c7c5;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.161);
}

.header-alert-box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
}

.header-alert-box-left {
    height: auto;
    display: flex;
}

.header-alert-box-img {
    width: 80px;
}

.header-alert-box-text {
    text-align: left;
    color: #1f1f1f;
    line-height: 1.5;
    font-weight: 500;
}

.header-alert-box-text-1 {
    font-size: 18px;
    margin-bottom: 5px;
}

.header-alert-box-text-2 {
    font-size: 14px;
}

.header-alert-box-right {
    width: 90px;
    height: auto;
    display: flex;
    align-items: end;
}

.header-alert-box-cancel {
    width: 70px;
    height: 24px;
    border-radius: 5px;
    border: 2px solid #d5d4d4;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #141414;
    cursor: pointer;
}











.badge {
    display: inline-flex;
    align-items: center;
    max-height: 22px;
    font-size: 10px;
    padding: 2px 6px;
    background: var(--main-color);
    color: #fff;
    border-radius: var(--px-30);
    margin-left: 6px;
}

.coming {
    opacity: .3;
    cursor: no-drop;
}

.toggle-ham {
    position: absolute;
    top: 20px;
    right: 24px;
    display: block;
    cursor: pointer;
}

.toggle-ham svg {
    width: 24px;
    height: 24px;
    fill: var(--main-color);
    transition: all .2s ease-in-out;
}

.toggle-menu {
    height: 100%;
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    background: #fff;
    padding: 64px 0 24px;
    z-index: 9998;
    transition: all 0.3s;
    overflow-y: scroll;
}

.toggle-menu.dark {
    background: #1f2029;
    color: #fff;
}

.toggle-menu-x {
    position: absolute;
    top: 20px;
    right: 24px;
    font-size: 27px;
    cursor: pointer;
}

.toggle-menu-top {
    display: inline-grid;
    gap: 6px;
    padding: 0 16px;
}

.toggle-menu-top-1 {
    background: #242424;
    color: #fff;
    width: 790px;
    padding: 12px 16px;
    border-radius: var(--px-30);
    text-align: center;
}

.toggle-menu-top-1.dark {
    border: 1px solid #cccccc16;
    background: #2a2c37;
}

.toggle-menu-top-2 {
    background: var(--main-color);
    color: #fff;
    width: 790px;
    padding: 12px 16px;
    border-radius: var(--px-30);
    text-align: center;
}

.toggle-menu-mid-1 {
    margin-top: 12px;
    display: block;
    border-top: 1px solid rgb(241, 241, 241);
}

.toggle-menu-mid-1.dark {
    border-top: 1px solid #cccccc16;
}

.toggle-menu-mid-1>li {
    width: 100%;
}

.toggle-menu-mid-1>li>div {
    width: 90%;
    height: 50px;
    line-height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    padding: 0 16px;
    text-align: left;
    cursor: pointer;
}

.toggle-menu-mid-1>li>div:hover {
    color: var(--main-color);
}

.toggle-menu-mid-1>li>div svg {
    width: 21px;
}

.toggle-menu-mid-1.dark>li>div svg {
    fill: #fff;
}

.toggle-menu-mid-1>li>div p {
    width: 100%;
    padding-left: 10px;
}

.toggle-menu-mid-1-ico {
    width: 8px !important;
    height: 8px !important;
    transition: all ease .5s;
}

.toggle-menu-mid-1-menu {
    width: 100%;
    height: 42px;
    line-height: 42px;
    padding: 0 75px;
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.header-right>ul>li {
    position: relative;
    height: 64px;
    display: inline-flex;
    align-items: center;
    /* gap: 3px; */
    padding: 0 12px;
    cursor: pointer;
}



/* footer */
.footer {
    width: 100%;
    padding: 15px 0;
    border-top: 1px solid #f7f7f7;
}

.footer.dark {
    background: #1f2029;
    color: #fff;
    border-top: 1px solid #cccccc16;
}

.footer-box {
    max-width: 1000px;
    padding: 0 24px;
    margin: auto;
}

.footer-box-wrap {
    width: 100%;
    height: 300px;
    display: inline-flex;
    justify-content: space-between;
    gap: 36px;
    margin-top: 50px;
}

.footer-box-left {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.footer-box-left img {
    width: 120px;
    margin-bottom: 10px;
}

.footer-box-left-1 {
    width: 110px;
    margin-top: 7px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 3px;
    letter-spacing: -0.5px;
    border: 1px solid #e6e6e6;
    color: #919191;
    padding: 4px 5px;
    box-shadow: 1px 1px 1px 1px #dbdbdb;
    transition: background-color 0.3s, transform 0.1s;
}

.footer-box-left-1.dark {
    color: #fff;
    border: 1px solid #3f3e3e;
    box-shadow: 1px 1px 1px 1px #4e4e4e;
}

.footer-box-left-1:hover {
    background-color: #47474716;
}

.footer-box-left-1.dark:hover {
    background-color: #717e8812;
}

.footer-box-left-1:active {
    transform: translateY(2px);
}

.footer-box-right {
    display: inline-flex;
    gap: 36px;
}

.footer-box-right-menu {
    display: flex;
    text-align: left;
    gap: 50px;
}

.footer-box-right-box {
    width: 140px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer-box-right-txt-1 {
    height: 30px;
    font-size: 19px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.footer-box-right-txt-2 {
    height: 30px;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    gap: 5px;
}

.footer-box-right-txt-2.dark {
    color: #a5a5a5;
}

.footer-box-bottom {
    text-align: center;
    font-size: 14px;
}

.display-block {
    display: none;
}






/* sidebar */

.sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    z-index: 999;
    background-color: #fff;
    border-right: 1px solid #f3f5f7;
}

.sidebar.dark {
    background: #1f2029;
    color: #a5a5a5;
    border-right: 1px solid #cccccc16;
}

.sidebar-box {
    padding-top: 64px;
}

.sidebar-box-1 {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 15px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sidebar-box-1 img {
    width: 25px;
}

.sidebar-box-1:hover {
    background-color: #dfe2e4;
}

.sidebar-box-1.dark:hover {
    background-color: #717e8812;
}

.sidebar-box-1.current {
    background-color: #f3f5f7;
}

.sidebar-box-1.dark.current {
    background-color: #717e8812;
}

.sidebar-box-1.current.bn {
    width: calc(100% - 4px);
    border-left: 4px solid rgba(252, 213, 53);
}

.sidebar-box-1.current.okx {
    width: calc(100% - 4px);
    border-left: 4px solid rgb(0, 0, 0);
}

.sidebar-box-1.current.okx.dark {
    border-left: 4px solid #fff;
}
















@media (max-width: 970px) {
    .display-none {
        display: none;
    }

    .display-block {
        display: block;
    }
}

@media (max-width:1200px) {
    .footer {
        padding: 10px 0;
    }

    .footer-box-wrap {
        display: none;
    }
}

@media(max-width: 850px) {
    .toggle-menu-top-1 {
        width: 600px;
    }

    .toggle-menu-top-2 {
        width: 600px;
    }
}

@media(max-width: 670px) {
    .toggle-menu-top-1 {
        width: 400px;
    }

    .toggle-menu-top-2 {
        width: 400px;
    }

    .toggle-menu-mid-1-menu {
        padding: 0 65px;
    }
}

@media(max-width: 470px) {
    .toggle-menu-top-1 {
        width: 300px;
    }

    .toggle-menu-top-2 {
        width: 300px;
    }

    .toggle-menu-mid-1-menu {
        padding: 0 55px;
    }
}

@media(max-width: 370px) {
    .toggle-menu-top-1 {
        width: 250px;
    }

    .toggle-menu-top-2 {
        width: 250px;
    }
}

@media(max-width: 310px) {
    .toggle-menu-top-1 {
        width: 200px;
    }

    .toggle-menu-top-2 {
        width: 200px;
    }
}

@media(max-width: 500px) {
    .footer-box-bottom {
        font-size: 10px;
    }
}