@import url("https://use.typekit.net/not7hxu.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");

/* ---------------------------------------------------------
# reset
---------------------------------------------------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  /* box-sizing: border-box; */
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  vertical-align: top;
}

img,
picture,
video,
canvas,
svg {
  display: block;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  /* box-sizing: border-box; */
  vertical-align: middle;
  border: 0;
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 200ms ease-in-out forwards;
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }

  99% {
    visibility: visible;
    opacity: 0;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out 200ms ease-in-out forwards;
}

@keyframes rolloverani {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.anidiv {
  opacity: 0;
  transform: translateX(10%) translateY(0);
  transition: all 0.5s ease;
}

.anidiv:nth-of-type(even) {
  transform: translateX(0) translateY(60px);
}

.anidiv.show {
  opacity: 1;
  transform: translateX(0);
}

html {
  min-height: 100%;
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.wrap *,
.wrap *::before,
.wrap *::after {
  box-sizing: inherit;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  letter-spacing: -0.02em;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  background-color: #FFFFFF;
  color: #121212;
}

.dark-mode p {
  color: #121212;
}

.dark-mode a {
  color: #121212;
}

.dark-mode a:visited {
  color: #121212;
}

.dark-mode a:hover {
  color: #121212;
}

.dark-mode a.active {
  color: #121212;
}

.dark-mode {
  background-color: #171832;
  color: #FFFFFF;
}

.dark-mode p {
  color: #FFFFFF;
}

.dark-mode a {
  color: #FFFFFF;
}

.dark-mode a:visited {
  color: #FFFFFF;
}

.dark-mode a:hover {
  color: #FFFFFF;
}

.dark-mode a.active {
  color: #FFFFFF;
}

body.noscroll {
  overflow: hidden;
}

.wrap {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

section {
  position: relative;
  width: 100%;
}

small {
  font-size: 0.5em;
  font-weight: 500;
  opacity: 0.8;
}

.big-text {
  font-size: 1.8em;
  font-weight: 700;
}

.display-inlineBlock {
  display: inline-block;
  width: unset !important;
}

h1 {
  font-size: 3rem;
  font-weight: 900;
}

h2 {
  font-size: 2.5rem;
  font-weight: 900;
}

h3 {
  font-size: 1.75rem;
  font-weight: 700;
}

h4 {
  font-size: 1.125rem;
  font-weight: 700;
}

h1,
h2,
h3,
h4 {
  color: #5724C5;
}

.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4 {
  color: #FFFFFF;
}

.notice-color {
  color: #FFA300;
}

.alert-color {
  color: #EE2415;
}

.bold-text {
  font-weight: 700;
}

.heavy-text {
  font-weight: 900;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

@media (min-width: 760px) {
  .hide-up-540px {
    display: none;
  }
}

@media (max-width: 759px) {
  .hide-down-540px {
    display: none;
  }
}

.max-width-full {
  width: 100%;
  margin: 0 auto;
}

.max-width-1640 {
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 20px;
}

.mask {
  position: fixed;
  width: 100%;
  height: 100svh;
  top: 0;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all 0.5s ease-out;
  opacity: 0;
  z-index: 999;
}

.mask.show-nav {
  left: 0;
  opacity: 1;
}

.margin-top-s {
  margin-top: 0.875rem !important;
}

.margin-top-m {
  margin-top: 1rem !important;
}

.margin-top-l {
  margin-top: 1.75rem !important;
}

.margin-top-xl {
  margin-top: 2.5rem !important;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-20 {
  opacity: 0.2;
}

.btn {
  position: relative;
  cursor: pointer;
  min-width: 100px;
  padding: 0 10px 0 16px;
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  color: #FFFFFF;
  background: linear-gradient(0deg, #5724C5, #9658F8);
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  transition: all 0.5s ease-in;
}

.btn:active,
.btn:hover {
  background: linear-gradient(0deg, #6C38DC, #D07DFF);
}

.btn>i {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 24px;
  border-radius: 10px;
  font-size: 0.5em;
  background-color: rgba(255, 255, 255, 0.16);
  transform: translateY(5px);
}

.btn>i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-38%) translateY(-46%);
}

.green {
  color: #24AE64 !important;
}

.red {
  color: #EE2415 !important;
}

.svg-black {
  filter: invert(0%) sepia(0%) saturate(7458%) hue-rotate(185deg) brightness(0%) contrast(86%);
}

.warning-color {
  color: #FFA300 !important;
}

.warning-bg-color {
  background-color: #FFA300 !important;
}

.main-body {
  background: url("/public/img/images/header_bg_1920_1280.jpg") top center no-repeat;
}

@media (max-width: 889px) {
  .main-body {
    background: url("/public/img/images/header_bg_1280_768.jpg") top center no-repeat;
  }
}

@media (max-width: 759px) {
  .main-body {
    background: url("/public/img/images/main_visual_768_375.jpg") top center no-repeat;
  }
}

/* body {
  background: url("/public/img/images/header_bg_1920_1280.jpg") top center no-repeat;
}

@media (max-width: 889px) {
  body {
    background: url("/public/img/images/header_bg_1280_768.jpg") top center no-repeat;
  }
}

@media (max-width: 759px) {
  body {
    background: url("/public/img/images/main_visual_768_375.jpg") top center no-repeat;
  }
} */

nav {
  position: relative;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
}

nav .nav-wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav .nav-wrap .menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

nav .nav-wrap .menu .logo {
  position: relative;
  width: 70px;
  height: 64px;
  background-image: url("/public/img/images/GOEX_logo_light.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
  cursor: pointer;
}

nav .nav-wrap .menu .category {
  position: relative;
  margin-left: 40px;
  display: flex;
  align-items: center;
}

@media (max-width: 889px) {
  nav .nav-wrap .menu .category {
    margin-left: 20px;
  }
}

@media (max-width: 759px) {
  nav .nav-wrap .menu .category {
    display: none;
  }
}

nav .nav-wrap .menu .category>li {
  position: relative;
  height: 64px;
}

nav .nav-wrap .menu .category>li>a {
  display: block;
  line-height: 64px;
  padding: 0 30px 0 10px;
  transition: all 0.2s ease-out;
  font-weight: 700;
}

@media (max-width: 889px) {
  nav .nav-wrap .menu .category>li>a {
    padding: 0 10px 0 10px;
  }
}

nav .nav-wrap .menu .category>li>a i {
  font-size: 0.6875rem;
  margin-left: 4px;
}

nav .nav-wrap .menu .category>li>a:hover {
  transform: translateY(-3px);
}

nav .nav-wrap .menu .category>li .submenu {
  position: absolute;
  top: 54px;
  left: 0;
  min-width: 320px;
  height: 0;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 6px;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-100%);
  transition: all 0.2s ease-out;
  z-index: -2;
}

nav .nav-wrap .menu .category>li .submenu>li {
  position: relative;
  margin-bottom: 2px;
  overflow: hidden;
}

nav .nav-wrap .menu .category>li .submenu>li>a {
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  color: #121212;
  padding: 10px 0 8px 6px;
  border-radius: 4px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  column-gap: 16px;
  transition: all 0.2s ease-out;
}

nav .nav-wrap .menu .category>li .submenu>li>a:hover {
  background-color: #D5CEE2;
}

nav .nav-wrap .menu .category>li .submenu>li>a>i {
  font-size: 1.125rem;
  color: #121212;
  opacity: 0.6;
}

nav .nav-wrap .menu .category>li .submenu>li>a>span {
  line-height: 1rem;
}

nav .nav-wrap .menu .category>li .submenu>li>a>span>small {
  line-height: 0.75rem;
  font-size: 0.75rem;
  font-weight: 500 !important;
}

nav .nav-wrap .menu .category>li .submenu>li:last-child {
  margin-bottom: 0;
}

nav .nav-wrap .menu .category>li:hover {
  overflow: unset;
}

nav .nav-wrap .menu .category>li:hover .submenu {
  height: auto;
  opacity: 1;
  transform: translateY(0);
  z-index: 2;
}

nav .nav-wrap .personal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 26px;
}

@media (max-width: 759px) {
  nav .nav-wrap .personal {
    column-gap: 16px;
  }
}

nav .nav-wrap .personal .login-join {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 26px;
}

@media (max-width: 759px) {
  nav .nav-wrap .personal .login-join {
    column-gap: 16px;
  }
}

nav .nav-wrap .personal .login-join a {
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  transition: all 0.2s ease-out;
}

nav .nav-wrap .personal .login-join a:hover {
  transform: translateY(-3px);
}

nav .nav-wrap .personal .login-join .btn-join {
  position: relative;
  height: 36px;
  line-height: 33px;
  padding-left: 14px;
  padding-right: 14px;
  color: #FFFFFF;
  background-color: #5724C5;
  border-radius: 18px;
}

nav .nav-wrap .personal .login-join .btn-join:hover {
  background-color: #8357e3;
}

nav .nav-wrap .personal .after-login {
  position: relative;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0;
}

@media (max-width: 889px) {
  nav .nav-wrap .personal .after-login {
    margin-right: -26px;
  }
}

@media (max-width: 759px) {
  nav .nav-wrap .personal .after-login {
    display: none;
  }
}

nav .nav-wrap .personal .after-login li {
  position: relative;
  width: 52px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

nav .nav-wrap .personal .after-login li>a {
  position: relative;
  display: block;
  font-size: 1.125rem;
  color: #FFFFFF;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #5724C5;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.2s ease-out;
}

nav .nav-wrap .personal .after-login li>a.noBG {
  color: #121212;
  background-color: transparent;
}

nav .nav-wrap .personal .after-login li>.submenu {
  position: absolute;
  top: 64px;
  left: 50%;
  min-width: 320px;
  height: 0;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 6px;
  opacity: 0;
  transform: translateY(-100%) translateX(-50%);
  transition: all 0.3s ease-out;
  z-index: -2;
}

nav .nav-wrap .personal .after-login li>.submenu::after {
  content: "";
  position: absolute;
  top: -16px;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: translateX(-50%);
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FFFFFF;
}

nav .nav-wrap .personal .after-login li>.submenu>li {
  position: relative;
  width: 100%;
  height: unset;
  margin-bottom: 2px;
}

nav .nav-wrap .personal .after-login li>.submenu>li>a {
  display: block;
  width: 100%;
  height: unset;
  line-height: unset;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 700;
  color: #121212;
  padding: 10px 0 8px 6px;
  border-radius: 4px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  column-gap: 16px;
  transition: all 0.2s ease-out;
}

nav .nav-wrap .personal .after-login li>.submenu>li>a:hover {
  background-color: #D5CEE2;
}

nav .nav-wrap .personal .after-login li>.submenu>li>a>i {
  font-size: 1.125rem;
  color: #121212;
  opacity: 0.6;
}

nav .nav-wrap .personal .after-login li>.submenu>li>a>span {
  line-height: 1rem;
}

nav .nav-wrap .personal .after-login li>.submenu>li>a>span>small {
  line-height: 0.75rem;
  font-size: 0.75rem;
  font-weight: 500 !important;
}

nav .nav-wrap .personal .after-login li>.submenu>li:last-child {
  margin-bottom: 0;
}

nav .nav-wrap .personal .after-login li>span {
  position: absolute;
  display: block;
  bottom: 2px;
  left: 50%;
  width: 48px;
  height: 14px;
  line-height: 14px;
  border-radius: 8px;
  text-align: center;
  transform: translateX(-50%);
  font-size: 10px;
  color: #121212;
  background-color: #FFFFFF;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: all 0.2s ease-out;
}

nav .nav-wrap .personal .after-login li>span::after {
  content: "";
  position: absolute;
  top: -9px;
  left: 50%;
  width: 4px;
  height: 4px;
  transform: translateX(-50%);
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #FFFFFF;
}

nav .nav-wrap .personal .after-login li:hover {
  overflow: unset;
}

nav .nav-wrap .personal .after-login li:hover>span {
  opacity: 0;
  bottom: -10px;
}

nav .nav-wrap .personal .after-login li:hover>a {
  background-color: #8357e3;
}

nav .nav-wrap .personal .after-login li:hover.none-submenu>span {
  opacity: 1;
  bottom: 6px;
}

nav .nav-wrap .personal .after-login li:hover.none-submenu>a {
  transform: translateY(-4px);
}

nav .nav-wrap .personal .after-login li:hover>.submenu {
  z-index: 2;
  opacity: 1;
  height: auto;
  transform: translateY(0) translateX(-50%);
}

nav .nav-wrap .personal .lang-mode {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

@media (max-width: 759px) {
  nav .nav-wrap .personal .lang-mode {
    display: none;
  }
}

nav .nav-wrap .personal .lang-mode .lang-select {
  position: relative;
  font-size: 1.3125rem;
  height: 64px;
  line-height: 64px;
}

nav .nav-wrap .personal .lang-mode .lang-select .submenu {
  position: absolute;
  top: 54px;
  left: 50%;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  padding: 10px;
  opacity: 0;
  transform: translateY(-100%) translateX(-46%);
  transition: all 0.3s ease-out;
}

nav .nav-wrap .personal .lang-mode .lang-select .submenu>li {
  margin-bottom: 6px;
}

nav .nav-wrap .personal .lang-mode .lang-select .submenu>li>a {
  font-size: 0.75rem;
  color: #121212;
}

nav .nav-wrap .personal .lang-mode .lang-select .submenu>li:last-child {
  margin-bottom: 0;
}

nav .nav-wrap .personal .lang-mode .lang-select:hover .submenu {
  opacity: 1;
  transform: translateY(0) translateX(-46%);
}

nav .nav-wrap .personal .lang-mode .mode-select {
  position: relative;
  width: 35px;
  height: 64px;
}

nav .nav-wrap .personal .lang-mode .mode-select a {
  display: block;
  line-height: 64px;
  text-indent: -9999px;
  background-image: url("/public/img/images/icon/darkmode_icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

nav .nav-wrap .side-nav-btn {
  position: relative;
  display: none;
  cursor: pointer;
  font-size: 1.75rem;
}

nav .nav-wrap .side-nav-btn>span {
  display: none;
}

@media (max-width: 759px) {
  nav .nav-wrap .side-nav-btn {
    display: unset;
  }
}

nav.sp-nav {
  position: fixed;
  top: 0;
  right: -100%;
  width: 310px;
  height: 100svh;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  z-index: 1000;
  display: unset;
  transition: all 0.5s ease-out;
}

nav.sp-nav.show-nav {
  right: 0;
}

nav.sp-nav .close-btn {
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: -42px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 700;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
}

nav.sp-nav .menu {
  position: relative;
}

nav.sp-nav .menu .logo {
  position: relative;
  width: 70px;
  height: 64px;
  margin-left: 30px;
  background-image: url("/public/img/images/GOEX_logo_light.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
  cursor: pointer;
}

nav.sp-nav .menu .category {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

nav.sp-nav .menu .category>li {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

nav.sp-nav .menu .category>li>a {
  display: block;
  line-height: 38px;
  padding-left: 30px;
  transition: all 0.2s ease-out;
  font-weight: 700;
}

nav.sp-nav .menu .category>li>a i {
  font-size: 0.6875rem;
  margin-left: 4px;
}

nav.sp-nav .menu .category>li>a:hover {
  transform: translateY(-3px);
}

nav.sp-nav .menu .category>li .submenu {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FFFFFF;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

nav.sp-nav .menu .category>li .submenu>li>a {
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  color: #121212;
  padding: 8px 0 10px 30px;
  background-color: #FFFFFF;
}

nav.sp-nav .menu .category>li .submenu>li>a:hover {
  background-color: #D5CEE2;
}

nav.sp-nav .menu .category>li .submenu>li:last-child {
  margin-bottom: 0;
}

nav.sp-nav .personal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

nav.sp-nav .personal .login-join {
  position: relative;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

nav.sp-nav .personal .login-join a {
  display: block;
  font-size: 1rem;
  font-weight: 700;
  transition: all 0.2s ease-out;
  margin: 6px 0;
}

nav.sp-nav .personal .login-join a:hover {
  transform: translateY(-3px);
}

nav.sp-nav .personal .login-join .btn-join {
  position: relative;
  width: 100%;
  height: 36px;
  line-height: 34px;
  text-align: center;
  color: #FFFFFF;
  background-color: #5724C5;
  border-radius: 18px;
}

nav.sp-nav .personal .login-join .btn-join:hover {
  background-color: #8357e3;
}

nav.sp-nav .personal .login-join .btn-default {
  position: relative;
  width: 100%;
  height: 36px;
  line-height: 33px;
  text-align: center;
  color: #121212;
  background-color: #D5CEE2;
  border-radius: 18px;
}

nav.sp-nav .personal .login-join .btn-default:hover {
  background-color: #FFFFFF;
}

nav.sp-nav .personal .after-login {
  position: relative;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0;
}

nav.sp-nav .personal .after-login li {
  position: relative;
  width: 52px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

nav.sp-nav .personal .after-login li>a {
  position: relative;
  display: block;
  font-size: 1.125rem;
  color: #FFFFFF;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background-color: #5724C5;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.2s ease-out;
}

nav.sp-nav .personal .after-login li>a.noBG {
  color: #121212;
  background-color: rgba(255, 255, 255, 0.3);
}

nav.sp-nav .personal .after-login li>span {
  position: absolute;
  display: block;
  bottom: -5px;
  left: 50%;
  width: 48px;
  height: 14px;
  line-height: 14px;
  border-radius: 8px;
  text-align: center;
  transform: translateX(-50%);
  font-size: 10px;
  color: #121212;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.4);
  opacity: 1;
  transition: all 0.2s ease-out;
}

nav.sp-nav .personal .after-login li>span::after {
  content: "";
  position: absolute;
  top: -9px;
  left: 50%;
  width: 4px;
  height: 4px;
  transform: translateX(-50%);
  border-top: 5px solid transparent;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #FFFFFF;
}

nav.sp-nav .personal .after-login li:hover {
  transform: translateY(-4px);
}

nav.sp-nav .personal .after-login li:hover>a {
  background-color: #8357e3;
}

nav.sp-nav .personal .lang-mode {
  position: relative;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  margin-top: 20px;
}

nav.sp-nav .personal .lang-mode .lang-select {
  position: relative;
}

nav.sp-nav .personal .lang-mode .lang-select .submenu {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 24px;
}

nav.sp-nav .personal .lang-mode .lang-select .submenu>li>a {
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-size: 0.875rem;
  color: #121212;
  font-weight: 700;
  opacity: 0.4;
}

nav.sp-nav .personal .lang-mode .lang-select .submenu>li>a.active {
  opacity: 1;
}

nav.sp-nav .personal .lang-mode .mode-select {
  position: relative;
  width: 35px;
  height: 35px;
}

nav.sp-nav .personal .lang-mode .mode-select a {
  display: block;
  line-height: 35px;
  text-indent: -9999px;
  background-image: url("/public/img/images/icon/darkmode_icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dark-mode nav {
  background-color: transparent;
}

.dark-mode nav .nav-wrap .menu .logo {
  background-image: url("/public/img/images/GOEX_logo_dark.png");
}

.dark-mode nav .nav-wrap .personal .after-login li>a.noBG {
  color: #FFFFFF;
}

.dark-mode nav .nav-wrap .personal .lang-mode .mode-select a {
  background-image: url("/public/img/images/icon/lightmode_icon.png");
}

.dark-mode nav.sp-nav {
  background-color: #171832;
}

.dark-mode nav.sp-nav .close-btn {
  background-color: #171832;
}

.dark-mode nav.sp-nav .menu .logo {
  background-image: url("/public/img/images/GOEX_logo_dark.png");
}

.dark-mode nav.sp-nav .menu .category {
  border-top: 1px solid rgba(255, 255, 255, 0.16);
}

.dark-mode nav.sp-nav .menu .category>li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.dark-mode nav.sp-nav .menu .category>li .submenu {
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  background-color: rgba(0, 0, 0, 0.16);
}

.dark-mode nav.sp-nav .menu .category>li .submenu>li>a {
  color: #FFFFFF;
  background-color: transparent;
}

.dark-mode nav.sp-nav .menu .category>li .submenu>li>a:hover {
  background-color: rgba(0, 0, 0, 0.16);
}

.dark-mode nav.sp-nav .personal .lang-mode .lang-select .submenu>li>a {
  color: #FFFFFF;
}

.dark-mode nav.sp-nav .personal .lang-mode .mode-select a {
  background-image: url("/public/img/images/icon/lightmode_icon.png");
}

.dark-mode .mode-select a {
  background-image: url("/public/img/images/icon/lightmode_icon.png");
}

@media (max-width: 759px) {
  .dark-mode .reserve h2 {
    color: #FFFFFF;
  }
}

.headcopy {
  position: relative;
  width: 100%;
  margin-top: 178px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.headcopy h2 {
  color: #FFFFFF;
}

@media (max-width: 889px) {
  .headcopy h2 {
    font-size: 1.75rem;
  }
}

.headcopy p {
  font-size: 1.125rem;
  color: #FFFFFF;
}

@media (max-width: 889px) {
  .headcopy p {
    font-size: 1rem;
  }
}

@media (max-width: 889px) {
  .headcopy {
    margin-top: 55px;
  }
}

.headcopy-join-area {
  position: relative;
  width: 100%;
  max-width: 520px;
  height: 48px;
  border-radius: 24px;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 24px;
}

@media (max-width: 889px) {
  .headcopy-join-area {
    max-width: 350px;
  }
}

.headcopy-join-area input {
  flex: auto;
  height: 48px;
  line-height: 48px;
  border-radius: 24px;
  font-size: 1rem;
}

.headcopy-join-area input:focus {
  outline: none;
}

.headcopy-join-area button {
  cursor: pointer;
  width: 100px;
  height: 38px;
  line-height: 38px;
  border-radius: 19px;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  color: #FFFFFF;
  background: linear-gradient(0deg, #2B1C5F, #3D2B6D);
}

.headcopy-join-area button:active,
.headcopy-join-area button:hover {
  background: linear-gradient(0deg, #5724C5, #9658F8);
}

.reserve {
  margin-top: 72px;
}

.reserve h2 {
  font-size: 1.75rem;
  color: #FFFFFF;
  text-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
}

@media (max-width: 759px) {
  .reserve h2 {
    color: #5724C5;
  }
}

@media (max-width: 759px) {
  .reserve.max-width-1640 {
    padding: 0;
  }
}

.reserve .grid-colum-3 {
  grid-gap: 4%;
}

@media (max-width: 889px) {
  .reserve .grid-colum-3 {
    grid-template-columns: 500px;
    justify-content: center;
  }
}

@media (max-width: 759px) {
  .reserve .grid-colum-3 {
    grid-template-columns: 1fr;
    justify-content: center;
  }
}

.reserve .grid-colum-3 .item {
  position: relative;
  border: 1px solid rgba(163, 150, 232, 0.5);
  border-radius: 26px;
  height: 250px;
  overflow: hidden;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  background-image: url("/public/img/images/reserve_img_01.jpg");
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 759px) {
  .reserve .grid-colum-3 .item {
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}

.reserve .grid-colum-3 .item h3 {
  position: absolute;
  width: 100%;
  bottom: 50px;
  font-size: 1.125rem;
  color: #FFFFFF;
  padding: 2px 0;
  background-color: #5724C5;
}

.reserve .grid-colum-3 .item:nth-child(2) {
  background-image: url("/public/img/images/reserve_img_02.jpg");
}

.reserve .grid-colum-3 .item:nth-child(3) {
  background-image: url("/public/img/images/reserve_img_03.jpg");
}

.yourway {
  margin-top: 135px;
}

.yourway.grid-colum-4 {
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
}

.yourway .item {
  position: relative;
  border-radius: 26px;
  height: 430px;
  overflow: hidden;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
}

.yourway .item:first-child {
  padding: 0;
  background-color: unset;
  box-shadow: unset;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 889px) {
  .yourway .item:first-child {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .yourway .item:first-child h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 759px) {
  .yourway .item:first-child {
    height: auto;
    padding-bottom: 20px;
  }

  .yourway .item:first-child h2 {
    font-size: 1.75rem;
  }
}

.yourway .item h3 {
  position: relative;
  width: 100%;
  padding: 2px 0 4px;
  border-radius: 12px;
  text-align: center;
  font-size: 1.75rem;
  color: #FFFFFF;
  background-color: #5724C5;
  text-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
}

.provide {
  margin-top: 135px;
  margin-bottom: 180px;
}

@media (max-width: 889px) {
  .provide {
    margin-top: 60px;
  }
}

.provide h2 {
  font-size: 5rem;
  text-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
}

@media (max-width: 889px) {
  .provide h2 {
    font-size: 3rem;
  }
}

@media (max-width: 759px) {
  .provide h2 {
    font-size: 1.75rem;
  }
}

@media (max-width: 759px) {
  .provide .display-grid-area.grid-colum-2 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

.provide .display-grid-area .item {
  position: relative;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-image: url("/public/img/images/futures_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 26px 0 0 26px;
  overflow: hidden;
  padding: 50px;
  border-top: 1px solid rgba(163, 150, 232, 0.5);
  border-bottom: 1px solid rgba(163, 150, 232, 0.5);
  border-left: 1px solid rgba(163, 150, 232, 0.5);
  border-right: 0 solid rgba(163, 150, 232, 0.5);
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
}

.provide .display-grid-area .item h3,
.provide .display-grid-area .item p,
.provide .display-grid-area .item button,
.provide .display-grid-area .item img {
  z-index: 2;
  color: #FFFFFF;
}

.provide .display-grid-area .item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0), black);
  z-index: 1;
}

@media (max-width: 759px) {
  .provide .display-grid-area .item {
    transform: translateX(20px);
  }
}

.provide .display-grid-area .item:first-child {
  align-items: flex-end;
  text-align: right;
  background-image: url("/public/img/images/spot_bg.jpg");
  border-radius: 0 26px 26px 0;
  border-left: 0 solid rgba(163, 150, 232, 0.5);
  border-right: 1px solid rgba(163, 150, 232, 0.5);
}

@media (max-width: 759px) {
  .provide .display-grid-area .item:first-child {
    transform: translateX(-20px);
  }
}

.provide .display-grid-area .item:first-child::before {
  left: unset;
  right: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0), black);
}

.provide .display-grid-area .item .left-pos {
  position: absolute;
  bottom: 60px;
  left: 14.7%;
}

.provide .display-grid-area .item .right-pos {
  position: absolute;
  bottom: 60px;
  right: 14.7%;
}

@media (max-width: 889px) {
  .provide .display-grid-area .item .left-pos {
    position: relative;
    bottom: unset;
    left: unset;
    margin-top: 20px;
  }

  .provide .display-grid-area .item .right-pos {
    position: relative;
    bottom: unset;
    right: unset;
    margin-top: 20px;
  }
}

footer {
  position: relative;
  padding: 88px 0;
  background: linear-gradient(0deg, #2B1C5F, #3D2B6D);
  color: #FFFFFF;
}

@media (max-width: 889px) {
  footer {
    margin-top: 60px;
    padding: 42px 0;
  }

  footer .grid-colum-6 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 759px) {
  footer .grid-colum-6 {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

footer .item h4 {
  color: #FFFFFF;
}

footer .item ul li a,
footer .item ul li a:visited,
footer .item ul li a:link {
  color: #D5CEE2;
}

footer .item .copyright {
  font-size: 0.6875rem;
  color: #D5CEE2;
}

footer .item:last-child {
  text-align: right;
}

footer .item:last-child img {
  width: 120px;
  height: auto;
  display: inline;
}

footer .item .sns-icon-box {
  position: relative;
  display: flex;
  column-gap: 12px;
}

@media (max-width: 889px) {
  footer .item:nth-child(1) {
    display: none;
  }

  footer .item:nth-child(2) {
    display: none;
  }

  footer .item:nth-child(3) {
    display: none;
  }

  footer .item:nth-child(6) {
    grid-column: 3/5;
  }
}

@media (max-width: 759px) {
  footer .item:nth-child(4) h4 {
    display: none;
  }

  footer .item:nth-child(5) {
    width: 124px;
  }

  footer .item:nth-child(5) h4 {
    display: none;
  }

  footer .item:nth-child(6) {
    grid-column: 1;
    margin-top: 20px;
    text-align: center;
  }
}

.display-grid-area {
  display: grid;
}

.grid-colum-2 {
  grid-template-columns: 1fr 1fr;
}

.grid-colum-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-colum-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-colum-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid-colum-6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.grid-gab-16 {
  grid-gap: 16px;
}

.language-box {
  position: relative;
  width: 100%;
  max-width: 124px;
  transition: all 0.3s ease-out;
}

.language-box .current-lang {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid #D5CEE2;
  padding: 3px 8px 5px;
}

.language-box .current-lang i {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.6875rem;
}

.language-box .lang-select-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: -1px;
  background-color: #D5CEE2;
  overflow: hidden;
  transition: all 0.3s ease-out;
}

.language-box .lang-select-list li {
  position: relative;
  transition: all 0.3s ease-out;
}

.language-box .lang-select-list li a {
  color: #121212 !important;
  font-size: 0.875rem;
  display: block;
  padding: 3px 8px 5px;
}

.language-box .lang-select-list li a:active,
.language-box .lang-select-list li a:link {
  color: #121212 !important;
}

.language-box .lang-select-list li a:hover {
  background-color: #FFFFFF;
}

.language-box:hover .lang-select-list {
  height: auto;
}

.main1-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #121212;
  margin-top: 20px;
}

.main1-list-item-1 {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.main1-list-item-1-2 {
  font-size: 0.875rem;
}

.main1-list-item-1-3 {
  font-size: 0.875rem;
  font-weight: 900;
}

.main1-list-item-1-4 {
  font-size: 0.875rem;
  font-weight: 900;
}

.main1-list-item-1-5 {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.main1-list-item-1-5-1 {
  font-size: 0.875rem;
  color: #D5CEE2;
}

.main1-list-item-1-5-2 {
  font-size: 0.875rem;
}

.main1-list-item-2 {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  column-gap: 16px;
}