.history {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 64px);
    padding-top: 90px;
    padding-bottom: 50px;
    animation: start .4s ease-out;
}

.history.dark {
    background: #1f2029;
    color: #a5a5a5;
}

.history-container {
    max-width: 1248px;
    padding: 0 24px;
    margin: auto;
}

.history-wrap {
    width: 100%;
    display: inline-flex;
    gap: 48px;
}

.history-left {
    width: 225px;
}

.history-tit {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    opacity: .5;
}

.history-left li+li {
    margin-top: 12px;
}

.history-tit-coming {
    opacity: .3;
    cursor: no-drop !important;
}

.history-txt {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    background: #f9f9f9;
    cursor: pointer;
    border-radius: var(--px-30);
}

.history-txt.dark {
    background: #2a2c37;
}

.history-txt:hover {
    background: #ececec;
}

.history-txt.dark:hover {
    background: #717e8812;
}

.history-txt.current {
    background: var(--main-color);
    color: #fff;
}

.history-txt.dark.current:hover {
    background: var(--main-color);
    color: #fff;
}

.history-tit-box {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    padding: 13px 16px;
    text-align: left;
}


.history-tit-box svg {
    width: 14px;
    min-width: 14px;
    height: 14px;
    fill: var(--main-color);
}

.history-txt.current svg {
    fill: #fff;
}

.history-right {
    width: calc(100% - 220px - 48px);
}

.history-right-top {
    width: 100%;
    margin-bottom: 24px;
    text-align: left;
}

.history-right-top-1 {
    font-weight: 900;
    font-size: 23px;
    line-height: 1.2;
}

.history-right-top-2 {
    margin-top: 6px;
    font-weight: 400;
    font-size: 14px;
}

.history-right-bottom-1 {
    /* width: 320px; */
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow-x: scroll;
}

.history-right-bottom-1::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.history-right-bottom-1-btn {
    margin-right: 12px;
    width: auto;
    min-width: 85px;
    padding: 12px 16px;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: #f9f9f9;
    border-radius: var(--px-30);
    cursor: pointer;
    font-size: 14px;
}

.history-right-bottom-1-btn.dark {
    background: #2a2c37;
}

.history-right-bottom-1-btn:hover {
    background: #ececec;
}

.history-right-bottom-1-btn.dark:hover {
    background: #717e8812;
}

.history-right-bottom-1-btn.current {
    background: var(--main-color) !important;
    color: #fff;
}

.history-right-bottom-2 {
    margin: 16px 0;
}

.history-right-bottom-2-1 {
    margin-bottom: 24px;
}

.history-right-bottom-2-1-tit {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    line-height: 1.2;
}

.history-right-bottom-2-2 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.history-right-bottom-2-2-date {
    max-width: 310px;
    min-width: 272px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    background: #f9f9f9;
    border-radius: var(--px-15);
    font-size: 14px;
}

.history-right-bottom-2-2-date.dark {
    background: #2a2c37;
    color: #fff;
}

.history-right-bottom-2-2-data-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 6px 10px;
}

.history-right-bottom-2-2-date.dark .react-datepicker__input-container {
    background: #2a2c37 !important;
}

.history-right-bottom-2-2-date.dark .react-datepicker__input-container input {
    color: #fff;
    background: #2a2c37 !important;
}

.history-right-bottom-2-2-data-img {
    width: 17px;
    display: block;
    padding-top: 1px;
}

.history-right-bottom-2-2-search {
    cursor: pointer;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    padding: 6px 16px;
    font-size: 14px;
    background: var(--main-color) !important;
    color: #fff !important;
    border-radius: var(--px-30);
}

.history-right-bottom-2-2-search:hover {
    filter: brightness(1.2);
}

.history-right-bottom-2-3 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 12px;
}

.history-right-bottom-2-3 div {
    font-size: 13px;
    padding: 5px 8px;
    background: #f9f9f9;
    border: 1px solid #efefef;
    border-radius: 5px;
    cursor: pointer;
}

.history-right-bottom-2-3.dark div {
    background: #2a2c37;
    border: 1px solid #cccccc16;
}

.history-right-bottom-2-3 div:hover {
    background-color: #ececec;
}

.history-right-bottom-2-3.dark div:hover {
    background-color: #717e8812;
}

.history-right-bottom-2-3 div.on,
.history-right-bottom-2-3.dark div.on {
    background-color: var(--main-color);
    color: #fff;
}

.history-right-bottom-3 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.history-right-bottom-3-1 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.history-right-bottom-3-1-box {
    width: calc(100% - 32px);
    display: inline-grid;
    gap: 24px;
    padding: 16px;
    border: 1px solid #f9f9f9;
    border-radius: var(--px-15);
}

.history-right-bottom-3-1-box.dark {
    border: 1px solid #cccccc16;
}

.history-right-bottom-3-1-box-tit {
    font-size: 13px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.history-right-bottom-3-1-box-tit img,
.history-right-bottom-3-1-box-tit svg {
    width: 14px;
}

.history-right-bottom-3-1-box-txt {
    font-size: 24px;
    font-weight: 700;
    display: inline-grid;
    justify-content: right;
    width: 100%;
    display: inline-flex;
    align-items: end;
    gap: 3px;
    opacity: .5;
}

.history-right-bottom-3-1-box-txt-1 {
    font-size: 24px;
    font-weight: 700;
    display: inline-grid;
    justify-content: right;
    width: 100%;
    display: inline-flex;
    align-items: end;
    gap: 3px;
}

.history-right-bottom-4 {
    margin-top: 12px;
    text-align: center;
}

.history-right-bottom-4-top {
    width: 100%;
    display: inline-flex;
    align-items: center;
    background: #f9f9f9;
    border-radius: var(--px-15);
    margin-bottom: 10px;
}

.history-right-bottom-4-top.dark {
    background: #2a2c37;
    color: #fff;
}

.history-right-bottom-4-top-1 {
    width: 100%;
    font-size: 14px;
    opacity: .5;
    padding: 10px 6px;
}

.history-right-bottom-4-top-1.first {
    padding: 10px 0px 10px 12px;
    text-align: left;
}

.history-right-bottom-4-bottom {
    width: calc(100% - 2px);
    /* min-height: 320px; */
    border: 1px solid #f9f9f9;
    border-radius: var(--px-15);
    font-size: 14px;
    text-transform: uppercase;
}

.history-right-bottom-4-bottom.dark {
    border: 1px solid #cccccc16;
}

.history-right-bottom-4-bottom-1 {
    margin: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.history-right-bottom-4-bottom-1 img {
    width: 100px;
}

.history-right-bottom-4-bottom-1 p {
    opacity: .5;
    font-size: 14px;
}

.history-right-bottom-4-bottom-2 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #f9f9f9;
    border-bottom: 1px solid #f9f9f9;
}

.history-right-bottom-4-bottom-2.dark {
    border-top: 1px solid #cccccc16;
    border-bottom: 1px solid #cccccc16;
}

.history-right-bottom-4-bottom-2.on {
    background-color: var(--main-color1);
    border-radius: 5px;
}

.history-right-bottom-4-bottom-2-2 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f9f9f9;
}

.history-right-bottom-4-bottom-2-2.dark {
    border-bottom: 1px solid #cccccc16;
}

.history-right-bottom-4-bottom-2-1 {
    padding: 10px 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* gap: 6px; */
    width: 100%;
}

.history-right-bottom-4-bottom-2-1.first {
    padding: 10px 6px 10px 15px;
    justify-content: flex-start;
}

.history-right-bottom-4-bottom-3 {
    margin: 12px 0;
    text-align: center;
}

.history-right-bottom-4-bottom-3 table {
    border-collapse: collapse;
    font-size: 13px;
    border-bottom: 1px solid #f9f9f9;
    font-weight: 400;
    width: 100%;
}

.history-right-bottom-4-bottom-3 table th {
    border-collapse: collapse;
    font-size: 13px;
    border-bottom: 1px solid #f9f9f9;
    font-weight: 400;
    height: 26px;
    width: 14%;
    background-color: #f5f5f5;
}

.history-right-bottom-4-bottom-3.dark table th {
    border-bottom: 1px solid #cccccc16;
    font-weight: 400;
    height: 26px;
    width: 14%;
    background-color: #2a2c37;
}

.history-right-bottom-4-bottom-3 table tbody tr {
    border-collapse: collapse;
    font-size: 13px;
    border-bottom: 1px solid #f9f9f9;
    font-weight: 400;
    width: 14%;
    height: 35px;
}

.history-right-bottom-4-bottom-3.dark table tbody tr {
    border-bottom: 1px solid #cccccc16;
}



.react-datepicker__input-container input {
    width: 100px;
    height: 10px;
    border: none;
    outline: none;
    background: #f9f9f9;
    cursor: pointer;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: var(--main-color);
    color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: var(--main-color);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    background-color: #f0f0f0;
}


.history .swiper-slide {
    width: auto;
}

@media (max-width:1080px) {
    .history-wrap {
        display: block;
    }

    .history-right {
        margin-top: 20px;
        width: 100%;
    }

    .history-left {
        width: 100%;
    }
}

@media (max-width: 820px) {
    .history-right-bottom-3-1 {
        flex-wrap: wrap;
    }
}

@media (max-width: 600px) {
    .history-right-bottom-4-bottom {
        font-size: 12px;
    }

    .history-right-bottom-4-bottom-3 table th {
        font-size: 12px;
    }

    .history-right-bottom-4-bottom-3 table tbody tr {
        font-size: 10.5px;
    }

    .history-right-bottom-4-bottom-2-1.first {
        padding: 10px 6px;
        justify-content: flex-start;
    }

    .wallet-right-bottom-3-2 {
        width: 65px !important;
        display: flex;
        height: 25px;
        font-size: 10px;
    }

    .history-right-bottom-3-1-box-txt-1 {
        font-size: 22px;
    }
}

@media (max-width: 500px) {
    .history-container {
        padding: 0 10px;
    }

    .history-right-bottom-2-2-data-1 {
        padding: 6px 5px;
    }

    .react-datepicker__input-container input {
        width: 75px;
    }

    .history-right-bottom-2-2 {
        gap: 5px;
    }

    .history-right-bottom-2-2-date {
        /* min-width: 200px; */
        gap: 0px;
    }

    .history-right-bottom-4-top-1 {
        font-size: 12px;
        padding: 6px 5px;
    }

    .history-right-bottom-4-bottom-1 img {
        width: 150px;
    }

    .history-right-bottom-4-bottom-1 p {
        opacity: .5;
        font-size: 12px;
    }

    .history-right-bottom-3-1-box {
        gap: 10px;
    }

    .history-right-bottom-3-1-box-txt {
        font-size: 22px;
    }
}