.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  text-decoration: none;
  color: inherit;
  background-color: inherit;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}


:root {
  color-scheme: light dark;

  /* --color-point1: #a273f4; */
  --color-point1: #5381e3;
  --color-point2: #3c32a3;
  --color-point3: #42d3d8;
  --color-point4: #75a0fc;

  /* --color-point1-op: #a273f41b; */
  --color-point1-op: #1d68f51b;
  --color-point2-op: #3b32a314;
  --color-point3-op: #42d3d827;
  --color-point4-op: #ffc16532;

  --color-red: #eb7575;
  --color-green: #72cf8f;
  --color-yellow: #ffdd1e;
  --color-blue: #1ea9ff;

  --color-red-op: #eb757522;
  --color-green-op: #72cf902b;
  --color-yellow-op: #ffdd1e22;
  --color-blue-op: #1ea9ff22;

  --color-light1: #fff;
  --color-light2: #f9f9f9;
  --color-light3: #ececec;
  --color-light4: #a5a5a5;

  --color-light1-op: #ffffff06;
  --color-light2-op: #f9f9f917;
  --color-light3-op: #ececec0e;
  --color-light4-op: #cccccc16;

  --color-dark1: #717e88;
  --color-dark2: #3f4251;
  --color-dark3: #2a2c37;
  --color-dark4: #1f2029;
  --color-dark5: #1b1c24;

  --color-dark1-op: #717e8812;
  --color-dark2-op: #3f425114;
  --color-dark3-op: #2a2c3714;
  --color-dark4-op: #1f20290f;

  --color-dim: #1011146e;
  --filter-blur: blur(6px);

  --color-tr-light1: #d5d5d5;
  --color-tr-light2: #929292;

  --color-tr-dark1: #949da3;
  --color-tr-dark2: #42484d;

  /* goex */
  --main-color: #F5AC1D;
  --green-color: #24ae64;
  --red-color: crimson;
  --main-color1: rgb(245 172 29 / 25%);
  --px-15: 3px;
  --px-30: 3px;
}