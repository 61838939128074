.newmarket {
    max-width: 1200px;
    height: auto;
}

.newmarket_top {
    width: calc(100% - 20px);
    height: 48px;
    background: #fff;
    opacity: .8;
    box-shadow: inset 0 -1px 0 #f2f2f2;
    border-top: 1px solid #f5f7fa;
    border-radius: 3px;
    padding: 0 10px;
}

.newmarket-top-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.newmarket-top-box-1 {
    width: 75px;
    font-size: 15px;
    color: #81858c;
    line-height: 48px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: bold;
    height: 100%;
}

.newmarket-top-box-1.current.bn {
    color: #121214;
    border-bottom: 2px solid #f7a600;
    position: relative;
}

.newmarket-top-box-1.current.okx {
    color: #fff;
    border-bottom: 2px solid #f7a600;
    position: relative;
}

.newmarket-mid {
    max-width: 1200px;
    margin: 0 auto;
}

.newmarket-mid-box {
    width: 100%;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.newmarket-mid-box-1 {
    width: calc(370px - 48px);
    height: calc(296px - 48px);
    background: #fff;
    border-radius: 20px;
    padding: 24px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
}

.newmarket-mid-box-2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.newmarket-mid-box-3 {
    width: calc(100% - 48px);
    position: absolute;
    z-index: 10;
    background-color: #fff;
    top: 24px;
    left: 24px;
}

.newmarket-mid-box-2 p,
.newmarket-mid-box-3 p {
    font-size: 18px;
    font-weight: 600;
    color: #121214;
    text-align: left;
    height: 30px;
}

.newmarket-mid-box-4 {
    margin-top: 18px;
}

.newmarket-mid-box-4-1 {
    display: flex;
    width: 100%;
    height: 25px;
    font-size: 12px;
    color: #81858c;
}

.newmarket-mid-box-4-2 {
    width: 45%;
    text-align: left;
    display: flex;
    align-items: center;
}

.newmarket-mid-box-4-2 img {
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-right: 7px;
    width: 15px;
    height: 15px;
    flex-basis: 15px;
    display: inline-block;
}

.newmarket-mid-box-4-3 {
    width: 30%;
    text-align: left;
}

.newmarket-mid-box-4-4 {
    width: 25%;
    text-align: right;
}

.newmarket-mid-box-4-5 {
    width: 100%;
    height: 29px;
    display: flex;
    align-items: center;
    font-size: 14px;
}