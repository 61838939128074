.regist {
    width: 100%;
    min-height: calc(100vh);
    /* min-height: calc(100vh - 64px); */
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    position: relative;
    padding-top: 64px;
    animation: start .4s ease-out;
}

.regist.dark {
    background-color: #1f2029;
}

.regist-box {
    max-width: 900px;
    min-width: 900px;
    display: inline-flex;
    box-shadow: rgb(135 51 255 / 8%) 0px 0px 20px;
    border-radius: var(--px-15);
}

.regist-box-left {
    width: 50%;
    padding: 36px;
    text-align: left;
    font-size: 14px;
    color: #121212;
}

.regist-box-left.dark {
    background: #2a2c37;
    border-top-left-radius: var(--px-15);
    border-bottom-left-radius: var(--px-15);
    color: #a5a5a5;
}

.regist-box-left-title {
    font-weight: 700;
    margin-bottom: 24px;
    font-size: 23px;
    line-height: 1.2;
}

.regist-box-left-ul {
    width: 100%;
    display: inline-flex;
    /* align-items: center; */
    gap: 24px;
}

.regist-box-left-li {
    cursor: pointer;
    height: 30px;
}

.regist-box-left-li.current {
    border-bottom: 3px solid var(--main-color);
}

.regist-box-left-form {
    margin: 12px 0;
}

.regist-box-left-form-1 {
    display: inherit;
}

.regist-box-left-form-1-1 {
    margin-bottom: 12px;
}

.regist-box-left-form-1-tit {
    width: 325px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    border: 1px solid #f7f7f7;
    border-radius: var(--px-30);
    padding: 0 24px;
    position: relative;
}

.regist-box-left-form-1-tit.dark {
    border: 1px solid #cccccc16;
}

.regist-box-left-form-1-ico {
    content: '';
    width: 4px;
    height: 4px;
    background: red;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 10px;
}

.regist-box-left-form-1-ico-1 {
    content: '';
    width: 4px;
    height: 4px;
    background: green;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 10px;
}

.regist-box-left-form-1-input {
    width: 100%;
    padding: 12px 0;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.regist-box-left-form-1-p {
    padding: 10px 0;
}

.regist-box-left-form-1-tit:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.regist-box-left-form-country {
    max-width: 75px;
}

.regist-box-left-form-country-input {
    width: 75px;
    padding: 12px 0;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-right: 1px solid #f7f7f7;
}

.regist-box-left-form-2 {
    width: 100%;
    display: inline-grid;
    margin-bottom: 10px;
}

.regist-box-left-form-2-1 {
    margin-bottom: 12px;
}

.regist-box-left-form-2-2 {
    width: 100%;
}

.regist-box-left-form-2-btn {
    width: 100%;
    display: inline-flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 6px;
    cursor: pointer;
    font-size: 14px;
}

.regist-box-left-form-2-btn:hover {
    color: var(--main-color);
}

.regist-box-left-form-2-3 {
    display: block;
    margin-top: 12px;
    border: 1px solid #f7f7f7;
    border-radius: var(--px-30);
    padding: 0 24px;
    width: 325px;
}

.regist-box-left-form-2-3.dark {
    border: 1px solid #cccccc16;
}

.regist-box-left-form-3-label {
    /* width: 100%; */
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.regist-box-left-form-2-3:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.regist-box-left-form-3 {
    position: relative;
    margin-bottom: 12px;
}

.regist-box-left-form-3-span2 {
    font-size: 12px;
}

.regist-box-left-form-3-span2 a {
    color: var(--main-color);
}

.regist-box-left-form-3-input {
    display: none;
}

.regist-box-left-form-3-span1.checked {
    border-color: var(--main-color);
}

.regist-box-left-form-3-span1.checked::before {
    height: 5px;
    transition: all 0.15s ease;
}

.regist-box-left-form-3-span1.checked::after {
    height: 9px;
    transition: all 0.15s ease 0.15s;
}

.regist-box-left-form-3-span1 {
    display: inline-block;
    width: 18px;
    min-width: 18px;
    height: 18px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}

.regist-box-left-form-3-span1::before {
    top: 9px;
    left: 4px;
    transform: rotate(-45deg);
    content: '';
    display: inline-block;
    width: 1px;
    background: var(--main-color);
    position: absolute;
    transform-origin: left top;
}

.regist-box-left-form-3-span1::after {
    top: 12px;
    left: 8px;
    transform: rotate(228deg);
    content: '';
    display: inline-block;
    width: 1px;
    height: 0;
    background: var(--main-color);
    position: absolute;
    transform-origin: left top;
}

.regist-box-left-form-4 {
    width: 345px;
    padding: 12px 16px;
    background: var(--main-color) !important;
    color: #fff !important;
    border-radius: var(--px-30);
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    text-align: center;
    cursor: pointer;
}

.regist-box-left-form-4:hover {
    filter: brightness(1.2);
}

.regist-box-left-form-5 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    margin-top: 6px;
    font-size: 12px;
}

.regist-box-left-form-5-span {
    color: var(--main-color);
    cursor: pointer;
}

.regist-box-left-form-hr {
    border-top: 1px solid #efefef;
    margin: 20px 0;
}

.regist-box-left-form-hr.dark {
    opacity: .1;
}

.regist-box-left-form-6 {
    opacity: .5;
    width: 345px;
    display: inline-grid;
    gap: 12px;
    cursor: pointer;
}

.regist-box-left-form-6-1 {
    filter: blur(2px);
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    /* border: 1px solid #e3e3e3; */
    background: #e3e3e3;
    border-radius: var(--px-30);
    padding: 12px 16px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.regist-box-left-form-6-1 img {
    width: 100%;
    max-width: 18px;
}

.regist-box-left-form-6-btn {
    cursor: pointer;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.regist-box-right {
    width: 50%;
    background: var(--main-color);
    color: #fff;
    border-radius: 0 var(--px-15) var(--px-15) 0;
    padding: 36px 0;
    text-align: center;
}

.regist-box-right-1 {
    height: 100%;
}

.regist-box-right-1-1 {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.regist-box-right-1-p {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 12px;
}

.im_pt_marketing_wrap {
    padding: 20px 20px 36px 20px;
}

.im_pt_marketing_tit_wrap {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.im_pt_marketing_tit_num {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 700;
    background: #fff;
    color: var(--main-color);
    border-radius: 50%;
}

.im_pt_marketing_tit_desc {
    font-size: 16px;
}

.im_pt_marketing_tit_wrap+.im_pt_marketing_txt_wrap {
    margin-top: 3px;
}

.im_pt_marketing_txt_wrap {
    width: 100%;
    display: inline-flex;
    align-items: end;
    justify-content: center;
    gap: 6px;
}

.im_pt_marketing_txt_wrap span {
    font-size: 20px;
    font-weight: 700;
}

.signup_password_modal {
    width: 50%;
    position: absolute;
    top: 45px;
    right: -1px;
    z-index: 100;
    background: #fff;
    padding: 16px;
    padding-bottom: 11px;
    border-radius: var(--px-15);
    box-shadow: rgb(18 18 18 / 8%) 0px 5px 12px;
    max-width: 275px;
}

.signup_password_modal.dark {
    background: #2a2c37;
    color: #fff;
    border: 1px solid #cccccc16;
}

.signup_password_condition {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    margin-bottom: 5px;
    width: 100%;
}

.signup_password_condition_check {
    width: 14px;
    height: 14px;
    background-size: 11px;
    padding-right: 10px;
    color: #ccc;
}

.signup_password_condition_check.on {
    color: var(--main-color);
}

.regist-eye {
    cursor: pointer;
    font-size: 17px;
    color: #ccc;
}



@media (max-width:1000px) {
    .regist {
        padding: 24px;
        width: calc(100% - 48px);
        min-height: calc(100vh - 48px);
    }

    .regist-box {
        max-width: 100%;
        min-width: 100%;
        display: inline-block;
    }

    .regist-box-left {
        padding: 24px;
        width: calc(100% - 48px);
    }

    .regist-box-left-form-1-tit {
        width: calc(100% - 48px);
    }

    .regist-box-left-form-2-3 {
        width: calc(100% - 48px);
    }

    .regist-box-left-form-4 {
        width: calc(100% - 32px);
    }

    .regist-box-left-form-6 {
        width: calc(100% - 32px);
    }

    .regist-box-right {
        width: 100%;
        border-radius: 0 0 var(--px-15) var(--px-15);
    }

    .regist-box-right-1-1 {
        position: relative;
        top: 50%;
        left: 0;
        transform: inherit;
    }
}

@media (max-width:500px) {
    .regist {
        margin-top: 64px;
    }
}

.swiper-pagination-bullet-active {
    background: #121214 !important;
}


/* OTP */
.otp-spinner {
    position: fixed !important;
    top: 64px;
    width: 100% !important;
    background-color: inherit !important;
}

.otp {
    width: 100%;
    min-height: calc(100vh - 64px);
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    text-align: left;
    animation: start .4s ease-out;
    margin-top: 64px;
}

.otp.dark {
    background-color: #1f2029;
    color: #fff;
}

.otp-1 {
    max-width: 420px;
    min-width: 420px;
    display: inline-flex;
    box-shadow: rgb(135 51 255 / 8%) 0px 0px 20px;
    border-radius: var(--px-15);
}

.otp-1.dark {
    background-color: #2a2c37;
    border: 1px solid #cccccc16;
    box-shadow: rgba(255, 255, 255, 0.03) 2px 5px 8px;
}

.otp-2 {
    width: calc(100% - 72px);
    padding: 36px;
    width: 100%;
}

.otp-h4 {
    font-weight: 700;
    margin-bottom: 24px;
    font-size: 23px;
    line-height: 1.2;
}

.otp-2-wrap {
    margin-bottom: 12px;
}

.otp-2-wrap-p {
    font-size: 14px;
    margin-bottom: 6px;
}

.otp-2-3 {
    width: calc(100% - 48px);
    display: inline-flex;
    align-items: center;
    gap: 6px;
    border: 1px solid #f7f7f7;
    border-radius: var(--px-30);
    padding: 0 24px;
    position: relative;
    /* width: 100%; */
}

.otp-2-3.dark {
    border: 1px solid #cccccc16;
}

.otp-2-3:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.otp-2-3 input {
    width: calc(100% - 24px);
    padding: 12px 0;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.otp-2-4 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    gap: 6px;
    margin-top: 6px;
    font-size: 14px;
}

.otp-2-4-1 {
    color: var(--main-color);
    background: none;
    border: none;
    transition: all .2s ease-in-out;
}

.otp-2-4-2 {
    color: var(--main-color);
    cursor: pointer;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
}

.otp-2-4 p {
    font-weight: 300;
    opacity: .3;
}

.otp-2-5 {
    width: 100%;
    display: inline-grid;
}

.otp-2-5-btn {
    width: calc(100% - 32px);
    padding: 12px 16px;
    cursor: pointer;
    background: var(--main-color) !important;
    color: #fff !important;
    border-radius: var(--px-30);
    text-align: center;
    /* width: 100%; */
}



@media (max-width:500px) {
    .otp {
        width: calc(100% - 40px);
        padding: 0 20px;
    }

    .otp-1 {
        min-width: 300px;
    }

    .otp-2 {
        width: calc(100% - 60px);
        padding: 30px;
    }
}