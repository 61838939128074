.buy-modal {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  height: 60px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 15px;
  padding: 20px;
  z-index: 10000;
}

.buy-modal.dark {
  color: #121212;
}

.buy-modal.show {
  display: flex;
  animation: Modal_opacityIn .4s ease-in-out;
}

.buy-modal.hide {
  display: none;
}

.buy-modal.copy {
  border: 1px solid #d5d5d5;
  background-color: #fff;
  animation: Modal_opacityIn .4s ease-in-out;
}

.buy-modal.copy.dark {
  border: 1px solid #cccccc16;
  background-color: #2b2c37;
  color: #fff;
  animation: Modal_opacityIn .4s ease-in-out;
}

.modal-red {
  border: 1px solid #ff9999;
  background: #ffc1c1;
}

.modal-green {
  border: 1px solid #259700;
  /* background-color: #23b500; */
  background-color: #3acc7e;
}

.message-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 384px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-size: 17px;
  padding: 20px;
  z-index: 10000;
  box-shadow: 1px 1px 4px 1px #656565;
  background-color: #fff;
  border-radius: 3px;
  text-align: center;
}

.message-modal.dark {
  color: #121212;
}

.message-modal.show {
  display: flex;
  animation: opacityIn .7s ease-in-out;
}

.message-modal.hide {
  display: none;
}

.message-modal-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 25px;
}

.message-modal-top-1 {
  font-size: 1.05rem;
  font-weight: 500;
  line-height: 1.3;
}

.message-modal-top-2 {
  white-space: nowrap;
  line-height: 1.3;
  color: #a5a5a5 !important;
  cursor: pointer;
}

.message-modal-2 {
  font-size: 16px;
  padding-bottom: 25px;
  width: 100%;
}

.message-modal-btn {
  width: calc(100% - 24px);
  padding: 12px !important;
  background: #a273f4;
  border: 1px solid #a273f4;
  color: #fff;
  fill: #fff;
  border-radius: 3px !important;
  line-height: 1;
  cursor: pointer;
}

.message-modal-btn:hover {
  filter: brightness(1.2);
}

.wallet-message-modal-1 {
  font-size: 15px;
  padding-bottom: 3px;
}

.tpsl-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
  font-size: 17px;
  padding: 20px 30px 40px 30px;
  z-index: 10000;
  box-shadow: 1px 1px 4px 1px #656565;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}

.tpsl-modal.dark {
  border: 1px solid #2f2f2f;
  background-color: #1f2029;
  box-shadow: rgba(255, 255, 255, 0.03) 2px 5px 8px;
}

.tpsl-modal-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 25px;
}

.tpsl-modal-2 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tpsl-modal-2-1 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}

.tpsl-modal-2-2 {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  font-size: 17px;
}

.tpsl-modal-2-2-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tpsl-modal-2-2-2 {
  width: 18px;
  height: 18px;
  margin-right: 7px;
  cursor: pointer;
}

.tpsl-modal-2-2-3 {
  padding: 5px 15px;
  background-color: #454545;
  border: 1px solid #656565;
  color: #fff;
  border-radius: 2px;
  font-size: 15px;
  cursor: pointer;
}

.tpsl-modal-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.tpsl-modal-3-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tpsl-modal-3-2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-size: 15px;
}

.tpsl-modal-3-2-1 {
  width: 50%;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #e9e9e9;
  border: 1px solid #d9d8d8;
  border-radius: 2px;
}

.tpsl-modal-3-2-1.dark {
  background-color: #2a2c37;
  border: 1px solid #2a2c37;
}

.tpsl-modal-input {
  border: none;
  outline: none;
  background-color: inherit;
  width: 100%;
  text-align: left;
}

.tpsl-modal-3-2-2 {
  width: 50%;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e9e9e9;
  border: 1px solid #d9d8d8;
  border-radius: 2px;
  cursor: pointer;
}

.tpsl-modal-3-2-2.dark {
  background-color: #2a2c37;
  border: 1px solid #2a2c37;
}

.tpsl-modal-3-2-3 {
  cursor: pointer;
  position: relative;
}

.tpsl-modal-3-3 {
  width: calc(100% - 20px);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e9e9e9;
  border: 1px solid #d9d8d8;
  border-radius: 2px;
  font-size: 15px;
}

.tpsl-modal-3-3.dark {
  background-color: #2a2c37;
  border: 1px solid #2a2c37;
}

.tpsl-modal-4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-size: 15px;
}

.tpsl-modal-4-1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tpsl-modal-5 {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cfa704;
  background-color: #F5AC1D;
  border-radius: 2px;
  cursor: pointer;
  color: #121212;
}

.select-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
}

.select-box {
  position: absolute;
  width: 100px !important;
  height: auto !important;
  top: 30px;
  right: -10px;
  border: 1px solid #afadad;
  background-color: #fff;
  border-radius: 5px;
  animation: select_drowdown .5s ease-in-out;
  z-index: 21;
  flex-direction: column;
  align-items: flex-start !important;
}

.select-box.dark {
  background: #383a45;
  color: #fff;
  border: 1px solid #2f2f2f;
  box-shadow: rgba(255, 255, 255, 0.03) 2px 5px 8px;
}

.select-box div {
  text-align: left;
  padding: 8px 12px;
  font-size: 15px !important;
  width: calc(100% - 24px) !important;
  justify-content: flex-start !important;
}

.select-box div:hover {
  background-color: #e9e9e9;
}

.select-box.dark div:hover {
  background-color: #5e5e5e;
}


.spot-kyc-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.spot-kyc-modal-1 {
  width: 95%;
  max-width: 300px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ffaa00;
  border-radius: 20px;
  padding: 20px;
}

.spot-kyc-modal-1.dark {
  background-color: #242424;
}

.spot-kyc-modal-text {
  font-size: 17px;
  font-weight: bold;
}

.spot-kyc-modal-btn {
  background-color: #F5AC1D;
  border: 1px solid #ffaa00;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  border-radius: 10px;
  padding: 7px 0;
}


.banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 430px;
  padding: 15px 0;
  background-color: #fff;
  border: 2px solid #9b9b9b;
  color: #121214;
  border-radius: 3px;
  z-index: 1000000;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  font-size: 14px;
  overflow-y: scroll;
}

.banner::-webkit-scrollbar {
  display: none;
}

.banner-top {
  width: 100%;
  height: auto;
}

.banner-img {
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.banner-1 {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0;
  font-weight: 600;
}

.banner-1-1 {
  width: 50%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #e3e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.banner-1-1.current {
  background-color: #ffd27a;
  border: 1px solid #ffbd3a;
}

.banner-1-1:hover {
  background-color: #ffd27a;
  border: 1px solid #ffbd3a;
}

.banner-2 {
  padding: 7px 15px;
  text-align: left;
}

.banner-3 {
  padding: 7px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: calc(100% - 30px);
}

.banner-3-1 {
  background-color: #f9c45c;
  border-radius: 5px;
  width: 100%;
  padding: 7px 0px;
  text-align: center;
  cursor: pointer;
}

.banner-3-1:hover {
  background-color: #F5AC1D;
}

.banner-4 {
  width: calc(100% - 30px);
  padding: 7px 15px;
}

.banner-4-box {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-4-1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.banner-4-txt {
  padding: 10px 0;
  text-align: left;
  color: #9d9999;
}

.banner-4-table {
  width: 100%;
  padding-bottom: 10px;
}

.banner-4-table table {
  width: 100%;
  font-size: 12px;
}

.banner-4-table th,
.banner-4-table td {
  background-color: #dadadb;
  text-align: left;
}

.banner-4-table th:first-child,
.banner-4-table td:first-child {
  width: 25%;
}

.banner-4-table th:nth-child(2),
.banner-4-table td:nth-child(2) {
  width: 21%;
  border-left: 1px solid #fff;
}

.banner-4-table th:nth-child(3),
.banner-4-table td:nth-child(3) {
  width: 14%;
  border-left: 1px solid #fff;
}

.banner-4-table th:nth-child(4),
.banner-4-table td:nth-child(4) {
  width: 40%;
  border-left: 1px solid #fff;
}

.banner-4-3 {
  text-decoration: underline;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  width: 110px;
}

.banner-4-3:hover {
  color: #004cff;
}

.banner1 {
  position: fixed;
  bottom: 3px;
  left: 1px;
  width: calc(100% - 6px);
  background-color: #fff;
  border: 2px solid #9b9bd9;
  color: #121214;
  border-radius: 3px;
  z-index: 1000000;
}

.banner1-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 13px 20px;
}

.banner1-1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.banner1-2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  gap: 10px;
}

.banner1-2-1 {
  width: 120px;
  text-decoration: underline;
  cursor: pointer;
  padding: 0 10px;
}

.banner1-2-1:hover {
  color: #F5AC1D;
}

.banner1-2-2 {
  background-color: #f9c45c;
  border-radius: 5px;
  width: 205px;
  padding: 10px 0px;
  text-align: center;
  cursor: pointer;
}

.event-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  height: auto;
  border-radius: 3px;
  z-index: 1000000;
  color: #121214;
}

.event-modal-1 {
  width: 100%;
  height: 45px;
  background-color: #fff;
  border-top: 1px solid #9b9b9b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}

.event-modal-2 {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid #e3e3e3;
}

.event-modal-2:hover {
  background-color: #afbcd759;
}

.event-modal-3 {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.event-modal-3:hover {
  background-color: #afbcd759;
}


.color {
  color: var(--main-color);
}

.bg-color {
  background-color: var(--main-color);
  background: var(--main-color);
}

.fill-color {
  fill: var(--main-color);
}







@keyframes select_drowdown {
  from {
    opacity: 0.9;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes opacityIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes Modal_opacityIn {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}


@media (max-width:600px) {
  .tpsl-modal {
    width: 80%;
    max-width: none;
    font-size: 14px;
    padding: 20px 20px 30px 20px;
    gap: 20px;
  }

  .tpsl-modal-1 {
    font-size: 22px;
  }

  .tpsl-modal-2 {
    flex-direction: column;
    gap: 10px;
    height: auto;
  }

  .tpsl-modal-2-1 {
    width: 100%;
    justify-content: flex-start;
  }

  .tpsl-modal-2-2 {
    gap: 14px;
    font-size: 14px;
    width: 100%;
    justify-content: end;
  }

  .tpsl-modal-2-2-2 {
    width: 14px;
    height: 14px;
  }

  .tpsl-modal-2-2-3 {
    padding: 4px 10px;
    font-size: 12px;
  }

  .tpsl-modal-input {
    font-size: 14px;
  }

  .tpsl-modal-4 {
    font-size: 14px;
  }
}

@media (max-width:550px) {
  .buy-modal {
    max-width: 80%;
    height: 50px;
  }

  .spot-kyc-modal-1 {
    width: 70%;
  }
}