.dashboard {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 64px);
    padding-top: 90px;
    padding-bottom: 50px;
    animation: start .4s ease-out;
}

.dashboard.dark {
    background: #1f2029;
    color: #a5a5a5;
}

.dashboard-right-bottom {
    margin-top: 24px;
}

.dashboard-right-bottom-1 {
    width: calc(100% - 48px);
    display: inline-flex;
    align-items: center;
    gap: 24px;
    padding: 24px;
    background: #f9f9f9;
    border-radius: var(--px-15);
}

.dashboard-right-bottom-1.dark {
    background: #2a2c37;
}

.dashboard-right-bottom-1-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 24px;
}

.dashboard-right-bottom-1-2 {}

.dashboard-right-bottom-1-3 {
    width: 64px;
    height: 64px;
    background: #ccc;
    border-radius: 50%;
    background-image: url(/public/img/icon/person.png);
    background-position: center bottom -1px;
    background-repeat: no-repeat;
    background-size: 60px;
    position: relative;
}

.dashboard-right-bottom-1-4 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background: #f9f9f9 !important;
    border-radius: 50%;
    background-image: url(/public/img/ico_camera.svg) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: 12px !important;
    cursor: pointer;
}

.dashboard-right-bottom-1-5 {
    font-size: 14px;
    text-align: left;
}

.dashboard-right-bottom-2 {
    width: 100%;
    display: inline-grid;
    gap: 6px;
    text-align: left;
    font-size: 13px;
}

.dashboard-right-bottom-2-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.dashboard-right-bottom-2-2 {
    min-width: 100px;
    font-size: 13px;
    text-transform: uppercase;
    opacity: .5;
    white-space: nowrap;
}

.dashboard-right-bottom-2-3 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-weight: 500;
}

.dashboard-right-bottom-2-4 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.dashboard-right-bottom1 {
    width: 100%;
    display: inline-grid;
    gap: 24px;
    margin-top: 48px;
}

.dashboard-right-bottom1-1 {
    width: 100%;
    display: inline-flex;
    gap: 16px;
    font-size: 14px;
}

.dashboard-right-bottom1-1-1 {
    width: 100%;
    display: inline-flex;
    gap: 16px;
    align-items: center;
    justify-content: left;
    text-align: left;
}

.dashboard-right-bottom1-1-1-ico {
    width: 24px;
    min-width: 24px;
    height: 20px;
    display: inline-flex;
    align-items: center;
}

.dashboard-right-bottom1-1-1-ico img {
    display: block;
    height: 100%;
    width: 100%;
}

.dashboard-right-bottom1-1-1-tit {
    font-weight: 700;
}

.dashboard-right-bottom1-1-1-desc {
    font-size: 14px;
    opacity: .5;
}

.dashboard-right-bottom1-1-2 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: left;
    text-align: left;
}

.dashboard-right-bottom1-1-2-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.dashboard-right-bottom1-1-2-1 img {
    display: inline-flex;
    width: 20px;
    height: 20px;
}

.dashboard-right-bottom1-1-2-2 {
    display: inline-flex;
}

.dashboard-right-bottom1-1-3 {
    display: inline-flex;
    align-items: center;
    justify-content: end;
}

.dashboard-right-bottom1-1-3-1 {
    font-size: 13px;
    padding: 6px 16px;
    border-radius: var(--px-30);
    background: #f9f9f9;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    border: none;
}

.dashboard-right-bottom1-1-3-1.dark {
    background: #2a2c37;
}

.dashboard-right-bottom1-modal {
    background: rgba(0, 0, 0, .3);
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    text-align: left;
}

.dashboard-right-bottom1-modal-1 {
    max-width: 350px;
    width: 100% !important;
    padding: 0 24px;
    margin: auto;
}

.dashboard-right-bottom1-modal-1-1 {
    background: #fff;
    padding: 36px;
    border-radius: var(--px-15);
    position: relative;
}

.dashboard-right-bottom1-modal-1-1.dark {
    background: #2a2c37;
    border: 1px solid #cccccc16;
}

.dashboard-right-bottom1-modal-1-1-tit {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.2;
    position: relative;
}

.dashboard-right-bottom1-modal-1-1-tit svg {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
}

.dashboard-right-bottom1-modal-1-1-wrap {
    margin-top: 12px;
    width: 100% !important;
    display: inline-grid !important;
    background: #fff;
    border-radius: var(--px-15);
    position: relative;
    text-align: left;
}

.dashboard-right-bottom1-modal-1-1-wrap.dark {
    background: #2a2c37;
    border: 1px solid #cccccc16;
}

.dashboard-right-bottom1-modal-1-1-txt {
    width: calc(100% - 48px);
    display: inline-flex;
    align-items: center;
    gap: 6px;
    border: 1px solid #f7f7f7;
    border-radius: var(--px-15);
    padding: 12px 24px;
    position: relative;
}

.dashboard-right-bottom1-modal-1-1-txt.dark {
    border: 1px solid #cccccc16;
    background-color: #2a2c37;
    color: #fff;
}

.dashboard-right-bottom1-modal-1-1-txt:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.dashboard-right-bottom1-modal-1-1-txt input {
    width: 100%;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
    appearance: none;
    opacity: .5;
}

.dashboard-right-bottom1-modal-1-1-box {
    position: absolute;
    top: 50px;
    right: -1px;
    z-index: 100;
    background: #fff;
    padding: 24px;
    border-radius: var(--px-15);
    box-shadow: rgb(18 18 18 / 8%) 0px 5px 12px;
}

.dashboard-right-bottom1-modal-1-1-box.dark {
    background: #2a2c37;
    color: #fff;
    border: 1px solid #cccccc16;
}

.dashboard-right-bottom1-modal-1-1-btn {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-size: 16px !important;
    margin-top: 24px;
    text-align: center;
}

.dashboard-right-bottom1-modal-1-1-btn-1 {
    background: var(--main-color) !important;
    width: 100%;
    color: #fff !important;
    padding: 12px 16px;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    font-size: 14px;
    cursor: pointer;
}

.dashboard-right-bottom1-modal-1-1-btn-2 {
    background: #242424 !important;
    width: 100%;
    color: #fff !important;
    padding: 12px 16px;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    font-size: 14px;
    cursor: pointer;
}

.dashboard-right-bottom1-modal-1-1-btn-2.dark {
    background: #383a45 !important;
    border: 1px solid #cccccc16;
    color: #fff !important;
}

.dashboard-right-bottom1-modal-2 {
    max-width: 420px;
    width: 100% !important;
    padding: 0 24px;
    margin: auto;
}

.dashboard-modal-2-form {
    margin-top: 12px;
}

.dashboard-modal-2-tit {
    width: calc(100% - 48px);
    display: inline-flex;
    align-items: center;
    gap: 6px;
    border: 1px solid #f7f7f7;
    border-radius: var(--px-30);
    padding: 0 24px;
    position: relative;
}

.dashboard-modal-2-btn {
    margin-top: 12px;
    background: #dfdfdf;
    width: calc(100% - 32px);
    padding: 12px 16px;
    border-radius: var(--px-30);
    font-size: 14px;
    text-align: center;
    cursor: pointer;
}

.dashboard-modal-2-btn.dark {
    background: #454858;
}

.dashboard-coming {
    opacity: .3;
    cursor: no-drop !important;
}

.dashboard-right-form-1-box-1-label {
    width: 75px;
    height: 30px;
    display: block;
    position: relative;
    border-radius: var(--px-30);
    color: #fff;
    background: #f9f9f9;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
}

.dashboard-right-form-1-box-1-label.dark {
    color: #fff;
    background: #2a2c37;
}

.dashboard-right-form-1-box-1-btn {
    transition: all .2s ease-in-out;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    border-radius: 50%;
    background: var(--main-color);
}

.toggled {
    background-color: var(--main-color);
}

.toggled .dashboard-right-form-1-box-1-btn.slider {
    transition: all .2s ease-in-out;
    left: calc(100% - 26px);
    background: #fff;
}

.dashboard-right-bottom1-1-3-on {
    position: absolute;
    color: #fff;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
}

.dashboard-right-bottom1-1-3-off {
    position: absolute;
    color: #121212;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    opacity: .3;
}

.dashboard-right-bottom1-1-3-off.dark {
    color: #fff;
}

.dashboard-right-bottom-3 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.dashboard-right-bottom-3 p {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
}

.dashboard-right-bottom-3-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.dashboard-right-bottom-3-1 span.Low {
    color: #eb7575;
    font-weight: 700;
    white-space: nowrap;
}

.dashboard-right-bottom-3-1 span.Middle {
    color: #ffa37c;
    font-weight: 700;
    white-space: nowrap;
}


.dashboard-right-bottom-3-1 span.High {
    color: #72cf8f;
    font-weight: 700;
    white-space: nowrap;
}


.dashboard-right-bottom-3-2 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.dashboard-right-bottom-3-2-low {
    width: 60px;
    height: 6px;
    border-radius: 3px;
    background: #eb7575;
}

.dashboard-right-bottom-3-2-middle {
    width: 60px;
    height: 6px;
    border-radius: 3px;
    background: #ffa37c;
}

.dashboard-right-bottom-3-2-middle.none {
    background: #ececec !important;
}

.dashboard-right-bottom-3-2-high {
    width: 60px;
    height: 6px;
    border-radius: 3px;
    background: #72cf8f;
}

.dashboard-right-bottom-3-2-high.none {
    background: #ececec !important;
}

.dashboard-right-bottom-3-3 {
    width: 100%;
    display: inline-grid;
    gap: 24px;
    margin-top: 20px;
    font-size: 15px;
}

.dashboard-right-bottom-3-4 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.dashboard-right-bottom-3-4-1 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.dashoboard-right-bottom-3-4-2 {
    width: 100%;
    display: inline-grid;
    justify-content: center;
    gap: 12px;
    background: #f9f9f9;
    border-radius: var(--px-15);
    padding: 24px;
    text-align: center;
}

.dashoboard-right-bottom-3-4-2.dark {
    background: #2a2c37;
    border: 1px solid #cccccc16;
    color: #fff;
}

.dashboard-right-bottom-3-4-3 {
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.dashboard-right-bottom-3-4-3 img {
    width: 26px;
    height: 26px;
    background-size: 26px;
}

.dashboard-right-bottom-3-4-3.dark img {
    color: #ccc;
}

.dashoboard-right-bottom-3-4-2 p {
    width: 100%;
    min-height: 42px;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
}

.dashboard-right-bottom1 p {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    text-align: left;
}

hr {
    margin: 0;
    border: 0;
    border-top: 1px solid #f7f7f7;
    overflow: visible;
    text-align: inherit;
}

.dark hr {
    border-top: 1px solid #cccccc16;
}

.dashboard-right-bottom-4 {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 20px;
}

.dashboard-right-bottom-4-1 {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 12px;
}

.dashboard-right-bottom-4-2 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
    position: relative;
}

.dashboard-right-bottom-4-3 {
    width: 100%;
    border: 1px solid #f9f9f9;
    border-radius: var(--px-15);
    padding: 16px;
    min-width: 130px;
}

.dashboard-right-bottom-4-3.dark {
    border: 1px solid #cccccc16;
}

.dashboard-right-bottom-4-4 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
    margin-bottom: 6px;
}

.dashboard-right-bottom-4-5 {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--main-color);
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
}

.dashboard-right-bottom-4-6 {
    font-weight: 700;
    font-size: 14px;
    text-align: left;
}

.dashboard-right-bottom-4-7 {
    padding-left: 32px;
    font-size: 12px;
    word-break: break-all;
    text-align: left;
    margin-top: 10px;
}

.dashboard-right-bottom-5 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
    margin-bottom: 15px;
}

.dashboard-right-bottom-5-1 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.dashboard-right-bottom-5-2 {
    width: 100%;
    display: inline-grid;
    gap: 24px;
    padding: 16px;
    border: 1px solid #f9f9f9;
    border-radius: var(--px-15);
}

.dashboard-right-bottom-5-2.dark {
    border: 1px solid #cccccc16;
}

.dashboard-right-bottom-5-3 {
    font-size: 13px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.dashboard-right-bottom-5-3 img,
.dashboard-right-bottom-5-3 svg {
    width: 14px;
}

.dashboard-right-bottom-5-4 {
    font-size: 24px;
    font-weight: 700;
    text-align: right;
    color: #72cf8f;
}

.dashboard-right-bottom-5-4 svg {
    font-size: 15px;
    margin-right: 10px;
}

.dashboard-right-bottom-5-4 span {
    opacity: .3;
}

.dashboard-right-bottom-5-5 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.dashboard-right-bottom-5-6 {
    font-size: 18px;
    font-weight: 600;
    display: inline-grid;
    justify-content: right;
}

.dashboard-right-bottom-5-7 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 3px;
}

.dashboard-right-bottom-5-8 {
    width: 100%;
    border: 0px;
    background: none;
    font-size: 16px;
    height: 30px;
    text-align: left;
    padding-left: 5px;
    position: relative;
    display: flex;
    align-items: center;
}

.dashboard-right-bottom-5-8.dark {
    color: #fff;
}

.dashboard-right-bottom-5-9 {
    background: #ddd;
    font-size: 13px;
    padding: 6px 16px;
    border-radius: var(--px-30);
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 0;
}

.dashboard-right-bottom-5-9.dark {
    background: #2a2c37;
}

.dashboard .swiper-slide {
    width: auto;
}

.dashboard-modal-security-1 {
    background: rgba(0, 0, 0, .3);
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    text-align: left;
    overflow: scroll;
    padding: 30px 0;
}

.dashboard-modal-security-1-box {
    width: calc(100% - 48px) !important;
    max-width: 760px;
    padding: 0 24px;
    margin: auto;
}

.dashboard-modal-security-1-box-1 {
    width: calc(100% - 72px);
    background: #fff;
    padding: 36px;
    border-radius: var(--px-15);
    position: relative;
    color: #121212;
}

.dashboard-modal-security-1-box-1.dark {
    color: #a5a5a5;
    background-color: #2a2c37;
}

.dashboard-modal-security-1-box-2 {
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dashboard-modal-security-1-box-2 svg {
    width: 21px;
    height: 21px;
}

.dashboard-modal-security-1-box-1-tit {
    font-weight: 900;
    font-size: 20px;
}

.dashboard-modal-security-1-box-3 {
    width: 100% !important;
    display: inline-grid !important;
    background: #fff;
    border-radius: var(--px-15);
    position: relative;
    text-align: left;
    margin-top: 20px;
}

.dashboard-modal-security-1-box-3.dark {
    background: #2a2c37;
    color: #a5a5a5;
}

.dashboard-modal-security-1-box-3-hr {
    width: 100%;
    height: 1px;
    background: #ececec;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: 1;
}

.dashboard-modal-security-1-box-3 ul {
    width: 100%;
    display: inline-flex;
    overflow: hidden;
    color: #ccc;
}

.dashboard-modal-security-1-box-3 ul li {
    width: 20%;
    text-align: center;
    font-size: 14px;
    position: relative;
    z-index: 2;
}

.dashboard-modal-security-1-box-3 ul li.active {
    width: 20%;
    text-align: center;
    font-size: 14px;
}

.dashboard-modal-security-1-box-3 ul li.active .dashboard-modal-security-1-box-3-1 {
    background-color: var(--main-color);
    transition: all .4s ease-in-out;
}

.dashboard-modal-security-1-box-3-1 {
    background-color: #ececec;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin: 0 auto;
    margin-bottom: 5px;
}

.dashboard-modal-security-1-box-3-1.dark {
    background-color: #4a4d5d;
}

.dashboard-modal-security-1-box-3-1 img {
    width: 20px;
    position: relative;
    z-index: 2;
}

.dashboard-modal-security-1-box-3-2 {
    font-size: 11px !important;
    white-space: pre-wrap !important;
    text-align: center !important;
}

.dashboard-modal-security-1-box-4 {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
}

.dashboard-modal-security-1-box-4-1 {
    max-width: 400px;
    margin: 48px auto;
    text-align: center;
}

.dashboard-modal-securtiy-1-box-4-2 {
    margin-bottom: 24px;
}

.dashboard-modal-securtiy-1-box-4-2-p {
    font-weight: 700 !important;
    white-space: pre-wrap !important;
    text-align: center !important;
    font-size: 15px !important;
}

.dashboard-modal-securtiy-1-box-4-2-p1 {
    font-weight: 500 !important;
    margin-top: 3px;
    white-space: pre-wrap !important;
    text-align: center !important;
    font-size: 15px !important;
}

.dashboard-modal-security-1-box-4-3 {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
}

.dashboard-modal-security-1-box-4-3-1 {
    width: 100%;
    display: inline-grid;
    justify-content: center;
    gap: 12px;
    background: #f9f9f9;
    border-radius: var(--px-15);
    padding: 16px;
    text-align: center;
}

.dashboard-modal-security-1-box-4-3-1.dark {
    background: #30323d;
}

.dashboard-modal-security-1-box-4-3-1-img {
    width: 100%;
}

.dashboard-modal-security-1-box-4-3-1-img img {
    max-width: 30px;
    margin: auto;
}

.dashboard-modal-security-1-box-4-3-1-span span {
    font-size: 14px;
    opacity: .5;
}

.dashboard-modal-security-1-box-4-3-1-btn {
    background: #ddd !important;
    font-size: 13px;
    padding: 6px 16px;
    border-radius: var(--px-30);
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

.dashboard-modal-security-1-box-4-3-1-btn.dark {
    background: #2a2c37 !important;
}

.dashboard-modal-security-1-box-4-3-1-btn:hover {
    filter: brightness(1.2);
}

.dashboard-modal-security-1-box-5 {
    max-width: 120px;
    background: var(--main-color) !important;
    width: 100%;
    color: #fff !important;
    padding: 12px 16px;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
}

.dashboard-modal-security-1-box-5-1 {
    max-width: 120px;
    background: #121214 !important;
    width: 100%;
    color: #fff !important;
    padding: 12px 16px;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
}

.dashboard-modal-security-1-box-4-4 {
    display: inline-grid;
    gap: 12px;
    text-align: center;
    padding: 5px;
    background: #fff;
}

.dashboard-modal-security-1-box-4-4.dark {
    background: #2a2c37;
}

.dashboard-modal-security-1-box-4-4 img {
    width: 150px;
    height: 150px;
}

.dashboard-modal-security-1-box-4-5 {
    width: calc(100% - 48px);
    border: 1px solid #f7f7f7;
    border-radius: var(--px-30);
    padding: 0 24px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 15px;
}

.dashboard-modal-security-1-box-4-5.dark {
    border: 1px solid #cccccc16;
}

.dashboard-modal-security-1-box-4-5 input {
    width: 100%;
    padding: 12px 0;
    text-align: center;
    border: none;
    outline: none;
    background-color: inherit;
}

.dashboard-modal-security-1-box-4-5:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.dashboard-modal-security-1-box-4-6 {
    width: calc(100% - 48px);
    border: 1px solid #f7f7f7;
    border-radius: var(--px-30);
    padding: 0 24px;
    position: relative;
    margin-top: 5px;
    margin-bottom: 15px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-modal-security-1-box-4-6.dark {
    border: 1px solid #cccccc16;
}

.dashboard-modal-security-1-box-4-6-1 {
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    height: 35px;
    word-wrap: break-word;
}

.dashboard-modal-security-1-box-4-6:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.dashboard-modal-securtiy-1-box-4-2-img {
    width: 300px;
}

.dashboard-right-bottom2 {
    width: 100%;
    height: auto;
    margin-top: 48px;
    max-width: 970px;
}

.dashboard-right-bottom2-1 {
    font-size: 17px;
    font-weight: 700;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
    margin-bottom: 24px;
}

.dashboard-right-bottom2-2 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.dashboard-right-bottom2-2-1 {
    width: 270px;
    cursor: pointer;
}

.dashboard-right-bottom2-2-1 img {
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e1e1e1;
}

.dashboard-right-bottom2-2-1.dark img {
    border: 1px solid #555;
}

.dashboard-right-bottom2-2-2 {
    border-radius: 0 0 3px 3px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 3%) 2px 5px 8px;
    width: calc(100% - 32px);
    padding: 16px;
    overflow: hidden;
    transition: all .2s ease-in-out;
    margin-top: 5px;
    text-align: left;
}

.dashboard-right-bottom2-2-2.dark {
    background: #2a2c37;
}

.dashboard-right-bottom2-2-2-1 {
    font-weight: 500 !important;
    margin-bottom: 12px;
}

.dashboard-right-bottom2-2-2-2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #717e88;
    margin-bottom: 24px;
}

.dashboard-right-bottom2-2-2-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-right-bottom2-2-2-4 {
    color: #717e88;
    font-size: 14px;
}

.dashboard-right-bottom2-2-2-5 {
    color: #42d3d8;
    font-size: 14px;
}

.swiper {
    padding-bottom: 15px;
}

.dashboard-right-event {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 48px;
}

.dashboard-right-bottom2.dark .swiper-scrollbar-drag {
    background: #ababab;
}

.dashboard-right-bottom2.dark .swiper-scrollbar {
    background: #454545;
}














@media (max-width:1080px) {
    .dashboard-right-bottom1-1 {
        display: inline-grid;
        justify-content: normal;
        gap: 12px
    }

    .dashboard-right-bottom1-1-2 {
        width: calc(100% - 40px);
        padding-left: 40px;
    }

    .dashboard-right-bottom-1 {
        flex-wrap: wrap;
    }
}

@media (max-width: 920px) {
    .dashboard-right-bottom-4-2 {
        overflow-x: scroll;
    }
}


@media (max-width: 820px) {
    .dashboard-right-bottom-3-4 {
        flex-wrap: wrap;
    }

    .dashboard-right-bottom-5 {
        flex-wrap: wrap;
    }
}

@media (max-width: 600px) {
    .dashboard-modal-security-1-box-1 {
        width: calc(100% - 50px);
        padding: 30px 25px;
    }

    .dashboard-modal-security-1-box-1-tit {
        font-size: 18px;
    }

    .dashboard-modal-security-1-box-2 {
        top: 12px;
        right: 12px;
    }

    .dashboard-modal-security-1-box-3-1 {
        width: 40px;
        height: 40px;
    }

    .dashboard-modal-security-1-box-3-1 img {
        width: 18px;
    }

    .dashboard-modal-security-1-box-3-2 {
        font-size: 10px !important;
    }

    .dashboard-modal-security-1-box-4-1 {
        margin: 30px auto;
    }

    .dashboard-modal-securtiy-1-box-4-2 {
        margin-bottom: 20px;
    }

    .dashboard-modal-securtiy-1-box-4-2-p1 {
        font-size: 13px !important;
    }

    .dashboard-modal-security-1-box-4-6-1 {
        margin-bottom: 30px;
        height: 20px;
    }

    .dashboard-right-bottom1-1 {
        font-size: 12px;
    }

    .dashboard-right-bottom-5-2 {
        gap: 15px;
    }

    .dashboard-right-bottom-5-8 {
        font-size: 12px;
    }

    .dashboard-right-bottom-5-9 {
        font-size: 10px;
        padding: 6px 11px;
    }
}

@media (max-width: 500px) {
    .dashboard-right-bottom-2 {
        gap: 15px;
    }

    .dashboard-right-bottom-2-3 {
        flex-wrap: wrap;
        gap: 3px;
    }

    .dashboard-right-bottom-2-1 {
        align-items: flex-start;
    }

    .dashboard-right-bottom-3 {
        flex-wrap: wrap;
    }

    .history-right-bottom-1-btn {
        padding: 10px;
        font-size: 13px;
    }

    .dashboard-right-bottom1-modal-1-1 {
        padding: 36px 30px;
    }
}