.spot {
    width: 100%;
    min-height: calc(100vh - 64px);
    padding-bottom: 30px;
    padding-top: 64px;
    display: flex;
    gap: 10px;
    animation: start .4s ease-out;
}

.spot.dark {
    background: #1f2029;
    color: #a5a5a5;
}

.spot-left {
    width: calc(100% - 445px);
    padding-left: 10px;
}

.spot-left.check {
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
}

.spot-left-box {
    width: 100%;
}

.spot-left-top {
    padding-left: 10px;
    width: calc(100% - 10px);
    height: 70px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.spot-left-top.dark {
    color: #fff;
}

.spot-left-top-1 {
    width: calc(43% - 25px);
    gap: 10px;
    display: flex;
    align-items: center;
}

.spot-left-top-1 img {
    width: 30px;
    padding-top: 1px;
}

.spot-left-top-1-symbol {
    font-weight: 600;
    font-size: 25px;
    width: 200px;
    text-align: left;
}

.spot-left-top-1-box {
    font-size: 1rem;
    text-align: left;
    width: 170px;
}

.spot-left-top-1-box-price {
    font-size: 23px;
    line-height: 30px;
    height: 30px;
}

.spot-left-top-1-box-1 {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.spot-left-top-1-box-1-1 {
    width: 50%;
    text-align: left;
}

.spot-left-top-1-box-1-2 {
    width: 50%;
    text-align: right;
}

.spot-left-top-2 {
    width: 27%;
    display: flex;
    align-items: center;
}

.spot-left-top-2-box {
    font-size: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.spot-left-top-2-box-1,
.spot-left-top-2-box-2 {
    width: 50%;
}

.spot-left-top-2-box-1 {
    text-align: left;
}

.spot-left-top-2-box-2 {
    text-align: right;
}

.spot-left-top-2-box-1-tit,
.spot-left-top-3-box-1-tit {
    height: 25px;
    line-height: 25px;
}

.spot-left-top-3 {
    width: 30%;
    display: flex;
    align-items: center;
}

.spot-left-top-3-box {
    font-size: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
}

.spot-left-top-3-box-1 {
    text-align: left;
    width: 30%;
}

.spot-left-top-3-box-2 {
    text-align: right;
    width: 50%;
}

.spot-left-top-3-box-3 {
    text-align: left;
    width: 20%;
}

.spot-left-top-4 {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 23px;
    color: #5f5f5f;
    cursor: pointer;
}

.spot-right-check {
    display: none;
}

.chart {
    height: 610px;
}

.spot-left-mid {
    width: 100%;
    display: flex;
    align-items: center;
    height: 600px;
    gap: 15px;
    padding-top: 10px;
}

.spot-left-mid-1 {
    width: 20%;
    height: 100%;
    border: 1px solid #d7d7d7;
}

.spot-left-mid-1.dark {
    border: 1px solid #cccccc16;
}

.spot-left-mid-2 {
    width: 34%;
    height: 100%;
    border: 1px solid #d7d7d7;
}

.spot-left-mid-2.dark {
    border: 1px solid #cccccc16;
}

.spot-left-mid-3 {
    width: 46%;
    height: 100%;
    border: 1px solid #d7d7d7;
    position: relative;
}

.spot-left-mid-3.dark {
    border: 1px solid #cccccc16;
}

.spot-left-mid-1-top {
    width: 100%;
    gap: 5px;
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 11px;
    border-bottom: 1px solid #d7d7d7;
}

.spot-left-mid-1-top.dark {
    border-bottom: 1px solid #2a2c37;
}

.spot-left-mid-1-top-1 {
    width: 20%;
    text-align: left;
    padding: 0 10px;
}

.spot-left-mid-1-top-2 {
    width: 40%;
    text-align: right;
}

.spot-left-mid-1-top-3 {
    width: 40%;
    text-align: right;
    padding: 0 10px;
}

.spot-left-mid-1-bottom {
    width: 100%;
    gap: 5px;
    display: flex;
    align-items: center;
    height: 29px;
    font-size: 12px;
}

.spot-left-mid-1-bottom-1 {
    width: 20%;
    text-align: left;
    padding: 0 10px;
}

.spot-left-mid-1-bottom-2 {
    width: 40%;
    text-align: right;
}

.spot-left-mid-1-bottom-3 {
    width: 40%;
    text-align: right;
    padding: 0 10px;
}

.spot-left-mid-2-top {
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 13px;
    padding: 0 10px;
    border-bottom: 1px solid #d7d7d7;
    justify-content: end;
}

.spot-left-mid-2-top.dark {
    border-bottom: 1px solid #cccccc16;
}

.spot-left-mid-2-mid {
    width: 100%;
    height: calc(100% - 68px);
    font-size: 14px;
    overflow: hidden;
}

.spot-left-mid-2-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 30px;
    font-size: 14px;
    border-top: 1px solid #d7d7d7;
}

.spot-left-mid-2-bottom.dark {
    border-top: 1px solid #cccccc16;
}

.spot-left-mid-2-mid-1 {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    height: 46%;
}

.spot-left-mid-2-mid-2 {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 46%;
}

.spot-left-mid-2-mid-1.on {
    height: 100%;
    align-items: flex-start;
}

.spot-left-mid-2-mid-2.on {
    height: 100%;
    align-items: end;
}

.spot-left-mid-2-mid-1-1 {
    position: relative;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    text-align: center;
    cursor: pointer;
}

.spot-left-mid-2-mid-1-2 {
    position: relative;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.spot-left-mid-2-mid-1-3 {
    position: relative;
    width: 40%;
    height: 100%;
    text-align: left;
}

.spot-left-mid-2-mid-2-1 {
    position: relative;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spot-left-mid-2-mid-2-2 {
    position: relative;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.spot-left-mid-2-mid-2-3 {
    position: relative;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
}

.spot-left-mid-2-mid-3 {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1; */
}

.spot-left-mid-2-mid-3.dark {
    border-top: 1px solid #cccccc16;
    border-bottom: 1px solid #cccccc16;
}

.spot-left-mid-2-top-1 {
    width: 55%;
}

.spot-left-mid-2-top-1-box {
    gap: 10px;
    display: flex;
    align-items: center;
    font-size: 11px;
}

.spot-left-mid-2-top-1-box-img {
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
}

.spot-left-mid-2-top-2 {
    width: 45%;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
}


.orderbook-bg {
    position: absolute;
    height: 100%;
}

.orderbook-box {
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 10px;
}

.spot-left-mid-2-bottom-1 {
    width: 40%;
    text-align: right;
}

.spot-left-mid-2-bottom-2 {
    width: 20%;
}

.spot-left-mid-2-bottom-3 {
    width: 40%;
    text-align: left;
}


.spot-left-mid-3-top {
    height: 40px;
    display: flex;
}

.spot-left-mid-3-top-1 {
    width: 33.3%;
    border-bottom: 1px solid #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.spot-left-mid-3-top-1.dark {
    border-bottom: 1px solid #cccccc16;
}

.spot-left-mid-3-top-2 {
    width: 33.3%;
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.spot-left-mid-3-top-2.dark {
    border-right: 1px solid #cccccc16;
    border-bottom: 1px solid #cccccc16;
    border-left: 1px solid #cccccc16;
}

.spot-left-mid-3-top-3 {
    width: 33.3%;
    border-bottom: 1px solid #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.spot-left-mid-3-top-3.dark {
    border-bottom: 1px solid #cccccc16;
}

.spot-left-mid-3-top-1.active {
    width: 33.3%;
    border-bottom: none;
    border-top: 3px solid var(--green-color);
    color: var(--green-color);
}

.spot-left-mid-3-top-2.active {
    width: 33.3%;
    border-bottom: none;
    border-top: 3px solid var(--red-color);
    color: var(--red-color);
}

.spot-left-mid-3-top-3.active {
    width: 33.3%;
    border-bottom: none;
    border-top: 3px solid var(--main-color);
    color: var(--main-color);
}

.spot-left-mid-3-body {
    padding: 20px 30px;
}

.spot-left-mid-3-body-1 {
    display: flex;
    align-items: center;
    text-align: left;
    height: 50px;
    padding-bottom: 15px;
}

.spot-left-mid-3-body-2 {
    text-align: left;
    height: auto;
    padding-bottom: 15px;
}

.spot-left-mid-3-body-2-box {
    display: flex;
    align-items: center;
    text-align: left;
    height: 30px;
}

.spot-left-mid-3-body-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    height: 50px;
    padding-bottom: 15px;
}

.spot-left-mid-3-body-3-box {
    display: flex;
    justify-content: end;
    align-items: center;
}

.spot-left-mid-3-body-3-tit {
    font-size: 12px;
    display: flex;
    align-items: center;
}

.spot-left-mid-3-body-3-tit input {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

.spot-left-mid-3-body-3-input {
    margin-left: 10px;
    width: 69%;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    height: 30px;
    text-align: right;
    padding-right: 10px;
}

.spot-left-mid-3-body-3-input.dark {
    border: 1px solid #2a2c37;
    background: #2a2c37;
    color: #fff;
}

.spot-left-mid-3-body-4 {
    height: auto;
    padding-bottom: 15px;
}

.spot-left-mid-3-body-4-box {
    display: flex;
    align-items: center;
    justify-content: end;
    /* justify-content: space-between; */
    text-align: left;
    height: 40px;
}

.spot-left-mid-3-body-4-box-3 {
    width: 30%;
    font-size: 11px;
}

.spot-left-mid-3-body-4-box-1 {
    display: flex;
    align-items: center;
    width: 70.5%;
    gap: 10px;
}

.spot-left-mid-3-body-4-input {
    width: 69%;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    height: 30px;
    text-align: right;
    padding-right: 15px;
}

.spot-left-mid-3-body-4-input.dark {
    border: 1px solid #2a2c37;
    background: #2a2c37;
    color: #fff;
}

.spot-left-mid-3-body-4-box-1-btn {
    width: 33.3%;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    padding: 3px 0;
}

.spot-left-mid-3-body-4-box-1-btn:hover {
    background: #f3f5f7;
}

.spot-left-mid-3-body-4-box-1-btn.dark {
    border: 1px solid #cccccc16;
}

.spot-left-mid-3-body-4-box-1-btn.dark:hover {
    background-color: #717e8812;
}

.spot-left-mid-3-body-4-box-2 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.spot-left-mid-3-body-5 {
    height: 50px;
    padding-bottom: 15px;
}

.spot-left-mid-3-body-5-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-size: 11px;
    padding-bottom: 5px;
    gap: 5px;
}

.spot-left-mid-3-body-5-1 {
    text-align: left;
}

.spot-left-mid-3-body-5-2 {
    text-align: right;
}

.spot-left-mid-3-body-5-btn {
    height: 40px;
    width: 100%;
    cursor: pointer;
    background-color: var(--green-color);
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spot-left-mid-3-body-5-btn.dark {
    background-color: var(--green-color);
}

.spot-left-mid-3-body-6-btn {
    height: 40px;
    width: 100%;
    cursor: pointer;
    background-color: var(--red-color);
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spot-left-mid-3-body-6-btn.dark {
    background-color: var(--red-color);
}

.spot-left-mid-3-body-5-btn.orange {
    background: var(--main-color) !important;
    color: #fff !important;
}

.spot-left-mid-3-body-6-btn.orange {
    background: var(--main-color) !important;
    color: #fff !important;
}

.balance {
    padding: 20px 30px;
}

.balance-top {
    border-bottom: 1px solid #d7d7d7;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.balance-top-1 {
    width: 33.3%;
    padding: 5px 0;
    font-size: 14px;
}

.balance-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.spot-left-bottom {
    width: calc(100% - 2px);
    height: 550px;
    margin-top: 10px;
    border: 1px solid #d7d7d7;
}

.spot-left-bottom.dark {
    border: 1px solid #cccccc16;
}

.spot-left-bottom-top {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}

.spot-left-bottom-top-1 {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 1px solid #d7d7d7;
}

.spot-left-bottom-top-1.dark {
    border-bottom: 1px solid #cccccc16;
}

.spot-left-bottom-top-2 {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-left: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
}

.spot-left-bottom-top-2.dark {
    border-left: 1px solid #cccccc16;
    border-bottom: 1px solid #cccccc16;
}

.spot-left-bottom-top-1.active {
    width: 50%;
    border-top: 3px solid var(--red-color);
    border-bottom: none;
    color: var(--red-color);
}

.spot-left-bottom-top-2.active {
    width: 50%;
    border-top: 3px solid var(--red-color);
    border-bottom: none;
    color: var(--red-color);
}

.spot-left-bottom-body {}

.spot-left-bottom-body-1 {
    height: 50px;
    display: flex;
    align-items: center;
}

.spot-left-bottom-body-1-1 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.spot-left-bottom-body-1-1-1 {
    width: 101px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    padding: 0 10px;
    margin-left: 10px;
}

.spot-left-bottom-body-1-1-1:hover {
    border: 1px solid var(--main-color);
    background-color: var(--main-color);
    color: #fff;
}

.spot-left-bottom-body-1-1.dark .react-datepicker__input-container {
    background: #2a2c37;
}

.spot-left-bottom-body-1-1.dark .react-datepicker__input-container input {
    background: #2a2c37 !important;
    color: #fff;
}

.spot-left-bottom-body-1-1-btn {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    margin-left: 10px;
    padding: 5px 10px;
}

.spot-left-bottom-body-1-1-btn.dark {
    border: 1px solid #cccccc16;
    background-color: #2a2c37;
    color: #fff;
}

.spot-left-bottom-body-1-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.spot-left-bottom-body-1-2-btn {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    margin-right: 10px;
    padding: 5px 10px;
}

.spot-left-bottom-body-1-2-btn.dark {
    border: 1px solid #2a2c37;
    background-color: #2a2c37;
}

.spot-left-bottom-body-1-2-btn.dark {
    border: 1px solid #2a2c37;
    background-color: #2a2c37;
    color: #fff;
}

.spot-left-bottom-body-1-2-select {
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    padding: 5px;
}

.spot-left-bottom-body-1-2-select.dark {
    border: 1px solid #2a2c37;
    background: #2a2c37;
    color: #fff;
}

.spot-left-bottom-body-2 {
    width: 100%;
}

.spot-left-bottom-body-2 .spot-table-head {
    width: 100%;
    height: 35px;
    border-spacing: 0;
    background-color: #f3f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spot-left-bottom-body-2.dark .spot-table-head {
    background-color: #2a2c37;
    color: #fff;
}

.spot-table-th {
    width: 17%;
    font-weight: 500;
    font-size: 15px;
    border: none;
    text-align: center;
}

.spot-table-th:last-child {
    /* width: 5%; */
    border: none;
}


.spot-left-bottom-body-3 {
    width: 100%;
    height: 460px;
    overflow-y: scroll;
}

.spot-left-bottom-body-3::-webkit-scrollbar {
    width: 5px;
}

.spot-left-bottom-body-3::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.spot-left-bottom-body-3 .spot-table-head {
    width: 100%;
    height: 35px;
    border-spacing: 0;
    background-color: #f3f5f7;
    display: flex;
    align-items: center;
}

.spot-left-bottom-body-3.dark .spot-table-head {
    background-color: #2a2c37;
    color: #fff;
}

.spot-left-bottom-body-3 .spot-table-th {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    border: none;
}

.spot-left-bottom-body-3 .spot-table-th {
    width: 17%;
    border: none;
}

.react-datepicker__input-container input {
    text-align: center;
    border-radius: 5px;
    padding: 2px;
}

.spot-right {
    width: 445px;
    min-width: 445px;
    top: 3px;
    padding-right: 10px;
    padding-top: 20px;
    height: calc(100vh - 74px);
    position: relative;
    background-color: #fff;
}

.spot-right.dark {
    background-color: #1f2029;
    border: 1px solid #cccccc16;
}

.spot-right-box {
    height: 100%;
    margin-top: 10px;
    padding: 0 10px;
}

.spot-right-box-1 {
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #d7d7d7;
    position: relative;
}

.spot-right-box-1.dark {
    border-bottom: 1px solid #cccccc16;
}

.spot-right-box-1-1 {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.spot-right-box-1-2 {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.spot-right-box-1-img {
    width: 25px;
    padding-left: 10px;
}

.spot-right-box-1-3 {
    margin-left: 15px;
}

.spot-right-box-1-4 {
    font-size: 13px;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.spot-right-box-1-4.dark {
    border: 1px solid #cccccc16;
}

.spot-right-box-1-4.on {
    background-color: var(--main-color);
    color: #fff;
}

.spot-right-box-1-4:hover {
    background-color: var(--main-color);
    color: #fff;
}

.spot-right-box-2 {
    padding-top: 10px;
    width: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
}

.spot-right-box-2 input {
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    padding: 8px;
    padding-left: 35px;
    width: 100%;
    font-size: 14px;
}

.spot-right-box-2.dark input {
    border: 1px solid #2a2c37;
    background-color: #2a2c37;
    color: #fff;
    outline: none;
}

.spot-right-box-2-ico {
    position: absolute;
    top: 20px;
    left: 10px;
    font-size: 14px;
    color: #5f5f5f;
}

.spot-right-box-3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 92px;
    font-size: 12px;
}

.spot-right-box-3::-webkit-scrollbar {
    width: 5px;
}

.spot-right-box-3::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.spot-right-box-3 .spot-3-table {
    width: 100%;
    background-color: #fff;
    z-index: 9;
}

.spot-right-box-3.dark .spot-3-table {
    background-color: #1f2029;
}

.spot-right-box-3 .spot-3-head {
    display: flex;
    align-items: end;
    height: 30px;
    position: relative;
    padding-right: 10px;
}

.spot-right-box-3 .spot-3-th {
    font-weight: 500;
    display: flex;
    align-items: center;
}

.spot-right-box-3 .spot-3-th span:first-child {
    cursor: pointer;
}

.spot-right-box-3 .spot-3-th:first-child {
    width: 32%;
    justify-content: left;
}

.spot-right-box-3 .spot-3-th:nth-child(2) {
    width: 28%;
    justify-content: end;
}

.spot-right-box-3 .spot-3-th:nth-child(3) {
    width: 20%;
    justify-content: end;
}

.spot-right-box-3 .spot-3-th:nth-child(4) {
    width: 20%;
    justify-content: end;
}

.spot-item-body {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #121214;
    font-weight: 600;
    font-family: IBM Plex Sans;
    font-style: normal;
}

.spot-item-body.dark {
    color: #bbbaba;
}

.spot-item-img {
    width: 15px;
    padding-right: 5px;
}

.spot-right-box-3 .spot-3-body .ReactVirtualized__List::-webkit-scrollbar {
    width: 5px;
}

.spot-right-box-3 .spot-3-body .ReactVirtualized__List::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.spot-right-box-3 .spot-3-tr {
    display: flex;
    align-items: center;
    height: 50px;
}

.spot-right-box-3 .spot-3-tr:hover {
    background-color: #f3f5f7;
}

.spot-right-box-3 .spot-3-tr.on {
    background-color: #ededed;
    border-radius: 2px;
}

.spot-right-box-3 .spot-3-tr.click {
    background-color: #f3f5f7;
}

.spot-right-box-3 .spot-3-tr.dark:hover {
    background-color: #292835;
}

.spot-right-box-3 .spot-3-tr.on.dark {
    background-color: #313341;
    border-radius: 2px;
}

.spot-right-box-3 .spot-3-tr.click.dark {
    background-color: #292835;
}

.spot-right-box-3 .spot-3-td:first-child {
    width: 32%;
    text-align: left;
    justify-content: left;
}

.spot-right-box-3 .spot-3-td:nth-child(2) {
    width: 28%;
    text-align: right;
    justify-content: end;
}

.spot-right-box-3 .spot-3-td:nth-child(3) {
    width: 20%;
    text-align: right;
    justify-content: end;
}

.spot-right-box-3 .spot-3-td:nth-child(4) {
    width: 20%;
    text-align: right;
    justify-content: end;
}

.spot-right-box-position {
    position: absolute;
    width: calc(100% - 30px);
    height: auto;
    z-index: 10;
    background-color: #fff;
    top: 30px;
}

.spot-right-box-position.dark {
    background-color: #1f2029;
}

.spot-opeenorder {
    font-size: 13px;
    height: 30px;
    display: flex;
    align-items: center;
}

.spot-opeenorder-td {
    width: 15%;
}

.spot-right-bottom-4-bottom-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.spot-right-bottom-4-bottom-1 img {
    width: 100px;
}

.spot-left-mid-3-body-4-box-4 {
    width: 83.4%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spot-left-mid-3-body-4-box-2-btn {
    width: 16.6%;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    padding: 3px 0;
}

.spot-left-mid-3-body-4-box-2-btn:hover {
    background: #f3f5f7;
}

.spot-left-mid-3-body-4-box-2-btn.dark {
    border: 1px solid #cccccc16;
}

.spot-left-mid-3-body-4-box-2-btn.dark:hover {
    background-color: #353535;
}

.spot-loading {
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
}





@media (max-width: 1500px) {
    .spot {
        width: calc(100% - 20px);
        padding-left: 10px;
        padding-right: 10px;
    }

    .spot-left {
        width: 100%;
        padding-left: 0px;
    }

    .spot-right {
        position: absolute;
        top: 160px;
        right: 0;
        z-index: 1000;
        border: 1px solid #d7d7d7;
    }

    .spot-left.check {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (max-width: 1200px) {
    .spot-left-mid-3-body {
        padding: 20px 15px;
    }
}

@media (max-width: 950px) {
    .spot-left-top-1 {
        width: 100%;
    }

    .spot-left-top-2 {
        display: none;
    }

    .spot-left-top-3 {
        display: none;
    }

    .spot-left-mid-1 {
        display: none;
    }

    .spot-left-mid-2 {
        width: 40%;
    }

    .spot-left-mid-3 {
        width: 60%;
    }

    .spot-display-none-950 {
        display: none;
    }

    .spot-left-mid {
        gap: 5px;
    }

    .spot-left-mid-2-mid-2-1 {
        width: 33.3%;
    }

    .spot-left-mid-2-mid-2-2 {
        width: 33.3%;
    }

    .spot-left-mid-2-mid-2-3 {
        width: 33.3%;
    }
}

@media (max-width:750px) {
    .spot-left-mid-2-mid {
        font-size: 12px;
    }

    .spot-left-mid-2-bottom {
        font-size: 12px;
    }

    .spot-left-mid-3-body {
        font-size: 14px;
    }

    .spot-left-mid-3-body-4-box-1 {
        gap: 5px;
        flex-wrap: wrap;
    }

    .spot-left-mid-3-body-4-box-2 {
        width: 100%;
    }

    .spot-left-mid-3-body-4-box {
        height: 50px;
    }

    .spot-left-mid-3-body {
        padding: 10px
    }

    .spot-left-mid-3-body-1 {
        height: 35px;
        padding-bottom: 10px;
    }

    .spot-left-mid-3-body-2 {
        padding-bottom: 10px;
    }

    .spot-left-mid-3-body-3-input {
        width: 50%;
    }

    .spot-left-mid-3-body-3 {
        height: 35px;
        padding-bottom: 10px;
    }

    .spot-left-mid-3-body-4 {
        padding-bottom: 10px;
    }

    .spot-left-mid-3-body-5 {
        padding-bottom: 10px;
    }

    .spot-left-bottom-body-1-1 {
        font-size: 12px;
    }

    .spot-left-bottom-body-1-2 {
        width: 100%;
    }
}

@media (max-width:600px) {
    .spot-left-top-1 img {
        width: 24px;
    }

    .spot-left-top-1-symbol {
        font-size: 20px;
        width: 170px;
    }

    .spot-left-top-1-box {
        display: none;
    }

    .spot-left-top-1-box-price {
        font-size: 20px;
    }

    .spot-left-top-1-box-1 {
        font-size: 12px;
    }

    .spot-left-top-1 {
        gap: 5px;
    }

    .spot-left-top-4 {
        width: 22px;
        font-size: 20px;
    }

    .spot-left-top-1-box-1-1 {
        width: 45px;
    }

    .spot-left-top-1-box-1-2 {
        width: auto;
    }

    .spot-right-box-position {
        width: calc(100% - 20px);
    }

    .spot-right {
        width: calc(100% - 2px);
        min-width: auto;
        padding-right: 0px;
        top: 125px;
    }

    .spot-right-box-3 table thead {
        width: calc(100% - 20px);
    }

    .spot-left.check {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .spot-left-top {
        height: 60px;
    }

    .display-none-600 {
        display: none;
    }
}

@media (max-width:500px) {
    /* .chart {
        height: 450px;
    } */

    .spot-left-mid-3-body {
        font-size: 13px;
    }

    .spot-left-mid-3-body-3-tit {
        font-size: 10px;
    }

    .spot-left-bottom-body-1-2-select {
        height: 25px;
        margin-right: 5px;
        padding: 3px;
        font-size: 11px;
    }

    .spot-left-bottom-body-1-2-btn {
        margin-right: 5px;
        padding: 3px 5px;
        font-size: 12px;
    }

    .spot-left-mid-3-top {
        font-size: 14px;
    }

    .spot-left-bottom-top {
        font-size: 14px;
    }

    .spot-left-bottom-body-2 .spot-table-th {
        font-size: 12px;
    }

    .spot-left-mid-3-body-4-box {
        display: block;
        height: auto;
    }

    .spot-left-mid-3-body-4-box div {
        width: 100%;
    }

    .spot-left-mid-3-body-4-input {
        width: calc(100% - 15px);
        margin-bottom: 5px;
        margin-top: 5px;
        font-size: 12px;
    }

    .spot-left-mid-3-body-4-box-1-btn {
        font-size: 11px;
    }

    .spot-left-mid-3-body-4-box-1 {
        gap: 5px;
        padding-top: 5px;
    }

    .orderbook-box {
        gap: 4px;
    }

    .spot-opeenorder {
        font-size: 11px;
    }

    .spot-left-bottom-body-3 .spot-table-th {
        font-size: 12px;
    }

    .spot-right-bottom-4-bottom-1 img {
        width: 150px;
    }

    .spot-left-bottom-body-1-1-1 {
        height: 25px;
        font-size: 11px;
    }
}