.main1 {
    display: block;
    padding-bottom: 64px;
    padding-top: 64px;
    background-color: #efefef;
    animation: start .4s ease-out;
}

@keyframes start {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.main1-1 {
    display: flex;
    background-color: #202020;
    width: 100%;
    min-height: 472px;
}

.main1-1-img {
    width: 100%;
    flex-grow: 1;
    background-image: url(/public/img/main1/main_img_pc.png);
    background-size: auto 100%;
    background-position: 50%;
    background-repeat: no-repeat;
}

.main1-1-box {
    width: 1400px;
    margin: 0 auto;
    padding-top: 45px;
    text-align: left;
}

.main1-1-box-1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 72px;
    color: #fff;
    margin-bottom: 40px;
}

.main1-1-box-2 {
    width: 155px;
    height: 64px;
    padding: 0 50px;
    line-height: 64px;
    font-weight: 600;
    font-size: 28px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    user-select: none;
    background: #f7a600;
    color: #242629 !important;
    margin-bottom: 60px;
}

.main1-1-box-3 {
    display: flex;
    align-items: center;
}

.main1-1-box-3-1 {
    width: 367px;
    height: 56px;
}

.main1-1-box-3-1 input {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    width: 100%;
    height: 100%;
    line-height: 100%;
    padding: 20px 24px;
    box-sizing: border-box;
    background: #242629;
    border: 1px solid #f7a600;
    color: #fff;
    outline: none;
}

.main1-1-box-3-2 {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0 24px;
    height: 56px;
    font-weight: 600;
    font-size: 16px;
    line-height: 56px;
    color: #121214;
    text-align: center;
    background: #f7a600;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
}

.main1-2 {
    width: 1400px;
    margin: 40px auto 0;
}

.main1-2-1 {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
}

.main1-2-1-1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.main1-2-1-1-text {
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    height: 28px;
    width: 845px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 6px 0;
    color: #121214;
    text-align: left;
    padding-left: 30px;
}

.main1-2-1-2 {
    display: inline-block;
    width: auto;
    white-space: nowrap;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    color: #81858c;
    cursor: pointer;
}

.main1-2-2 {
    margin: 0 auto;
    margin-top: 10px;
}

.main1-2-2-box {
    flex: 1;
    height: 270px;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    border-radius: 16px;
    overflow: hidden;
    cursor: pointer;
}

.main1-2-2-box:hover img {
    transform: scale(1.05);
    transition: transform 0.3 linear;
}

.main1-2-2-box img {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s;
}

.main1-2-2-box-text {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 65px;
    padding: 13px 24px 0;
    border-radius: 0 0 16px 16px;
    background: rgba(255, 255, 255, 0.86);
    backdrop-filter: blur(40px);
    text-align: left;
}

.main1-2-2-box-text-1 {
    width: 100%;
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #121214;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.main1-2-2-box-text-2 {
    font-weight: 400;
    font-size: 14px;
    font-size: 14px;
    line-height: 22px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #575b61;
    overflow: hidden;
    text-align: left;
}

.swiper-button-prev {
    background: url(/public/img/main1/left-arrow.png) no-repeat;
    background-size: 90% auto;
    background-position: center;
}

.swiper-button-next {
    background: url(/public/img/main1/right-arrow.png) no-repeat;
    background-size: 90% auto;
    background-position: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    display: none;
}

.main1-3 {
    width: 1400px;
    margin: 40px auto 0;
}

.main1-3-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 80px 0;
}

.main1-3-1-1 {
    min-height: 56px;
    line-height: 56px;
    font-size: 48px;
    font-weight: 700;
    color: #121214;
    text-align: left;
}

.main1-3-1-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 24px;
    border-radius: 8px;
    width: auto;
    gap: 4px;
    background: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #f7a600;
    cursor: pointer;
}

.main1-3-2 {
    margin: 0 auto;
    margin-top: 10px;
}

.main1-3-2-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 2px 24px rgba(24, 32, 82, 0.05);
    border-radius: 16px;
}

.main1-3-2-1-1 {
    width: 100%;
    height: 28px;
    margin-bottom: 16px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    line-height: 28px;
    color: #121214;
    font-weight: 600;
}

.main1-3-2-1-1-txt {
    display: inline-flex;
    align-items: center;
}

.main1-3-2-1-2 {
    width: calc(100% - 40px);
    padding: 0 20px;
    border-top: 1px solid #f8fafc;
}











.main1-list-box {
    display: flex;
    height: 64px;
    justify-content: space-between;
    align-items: center;
}

.main1-list-box-1 {
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 8px;
    text-align: left;
}

.main1-list-box-1-2 {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #81858c;
}

.main1-list-box-1-3 {
    font-size: 16px;
    font-weight: 600;
    color: #121214;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.main1-list-box-2 {
    text-align: right;
}

.main1-list-box-1-4 {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 16px;
}

.main1-list-box-1-5 {
    font-size: 14px;
    font-weight: 400;
    color: #121214;
}

.main1-list-box-1-5-1 {
    color: #adb1b8;
}

.main1-list-box-1-5-2 {
    margin-left: 4px;
}

.main1-4-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 48px;
    width: 100%;
}

.main1-4-2-1 {
    padding: 48px 0 0 48px;
    cursor: pointer;
    cursor: default;
    box-sizing: border-box;
    width: 100%;
    height: 360px;
    background-color: #fff;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.main1-4-2-1:hover {
    box-shadow: 0px 2px 32px rgba(24, 32, 82, 0.2);
}

.main1-4-2-1:hover .main1-4-2-2-3 {
    width: auto;
    min-width: 100px;
    padding: 0 25px;
    transition: padding 0.3s linear;
    overflow: hidden;
    background-color: #fffbf0;
}

.main1-4-2-1:hover .main1-4-2-3 {
    transform: scale(1.05);
    transition: transform 0.3s linear;
}

.main1-4-2-2 {
    padding: 48px 0 0 48px;
    left: 0;
    right: 312px;
    z-index: 2;
    position: absolute;
    box-sizing: border-box;
    border-radius: 16px;
    top: 0;
    width: calc(100% - 312px);
    height: 100%;
    text-align: left;
}

.main1-4-2-2-1 {
    width: 100%;
    height: 58px;
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
    color: #121214;
    margin-bottom: 14px;
    white-space: nowrap;
}

.main1-4-2-2-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    height: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #81858c;
    word-break: break-word;
}

.main1-4-2-2-3 {
    left: 48px;
    height: 48px;
    width: 48px;
    /* padding: 0 12px; */
    min-width: 48px;
    background-color: #f8fafc;
    border-radius: 24px;
    border: none;
    text-align: center;
    color: #f7a600;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: min-width 0.3s linear, background-color 0.3s linear;
    position: absolute;
    bottom: 48px;
}

.main1-4-2-2-3 img {
    width: 24px;
}

.main1-4-2-2-4 {
    margin-right: 12px;
    display: none;
    overflow: hidden;
    line-height: 48px;
    font-weight: 600;
    height: 48px;
    font-size: 18px;
}

.main1-4-2-1:hover .main1-4-2-2-4 {
    display: block;
}

.main1-4-2-3 {
    background-position: 100% 100%;
    transform-origin: bottom right;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 312px;
    height: 100%;
    position: absolute;
    transition: transform 0.3s ease-out;
    bottom: 0;
}

.main1-4-2-3 img {
    height: 360px;
}

.main1-5-2 {
    display: flex;
    flex-direction: row;
    border-radius: 16px;
    background-color: #fff;
    margin-bottom: 80px;
}

.main1-5-2-1 {
    flex-shrink: 0;
    position: relative;
    width: 640px;
    height: 360px;
    border-radius: 16px;
    overflow: hidden;
}

.main1-5-2-1 {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.main1-5-2-2 {
    box-sizing: border-box;
    margin: auto;
    height: 100%;
    padding: 0 80px;
}

.main1-5-2-2-1 {
    color: black;
    font-weight: 400;
    font-size: 24px;
    width: 450px;
    overflow: hidden;
    line-height: 46px;
    text-align: left;
}

.main1-5-2-2-2 {
    margin-top: 19px;
    margin-bottom: 40px;
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 16px;
}

.main1-5-2-2-2 img {
    width: 40px;
    cursor: pointer;
}

.main1-5-2-2-3 {
    background-color: #fff;
    transform-origin: center;
    display: grid;
    grid-template-columns: 40px auto;
    grid-column-gap: 8px;
    overflow: hidden;
    padding: 16px 24px;
    width: 450px;
    height: 108px;
    border: 1px solid #e9edf2;
    box-sizing: border-box;
    box-shadow: 0 2px 24px rgba(24, 32, 82, 0.05);
    border-radius: 8px;
    transform: translateZ(20px);
    animation: Community_zoomIn__lFzdl 0.4s forwards;
    opacity: 1;
    text-align: left;
}

@keyframes Community_zoomIn__lFzdl {
    0% {
        opacity: 0.6;
        transform: scale(0.8);
    }

    60% {
        opacity: 0.8;
        transform: scale(0.9);
    }
}

.main1-5-2-2-3-1 {
    color: black;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    margin-bottom: 4px;
}

.main1-5-2-2-3-2 {
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
}

.main1-5-2-1 img {
    height: 100%;
    border-radius: 16px;
}









@media (max-width: 1500px) {
    .main1-1-img {
        background-position-y: 10%;
    }

    .main1-1-box {
        width: calc(100% - 100px);
        padding: 50px 50px 0;
        margin: 0;
    }

    .main1-2 {
        width: 877px;
    }

    .main1-2-1-1-text {
        width: auto;
    }

    .main1-3 {
        width: 877px;
    }

    .main1-4-2 {
        grid-template-columns: repeat(1, 1fr);
    }

    .main1-5-2 {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .main1-5-2-1 {
        width: 100%;
    }

    .main1-5-2-2 {
        padding-bottom: 50px;
    }

    .main1-5-2-2-1 {
        width: 100%;
    }

    .main1-5-2-2-3 {
        width: 100%;
    }

    .main1-3-1 {
        margin: 80px 0 40px;
    }

    .main1-3-1-1 {
        min-height: 35px;
        line-height: 35px;
        font-size: 35px;
    }

    .main1-3-1-2 {
        padding: 15px;
    }
}

@media (max-width: 1000px) {
    .main1-1 {
        min-height: 350px;
    }

    .main1-1-box {
        width: calc(100% - 100px);
        padding: 30px 50px 0;
        margin: 0;
    }

    .main1-1-box-1 {
        margin-bottom: 35px;
    }

    .main1-1-box-2 {
        width: 140px;
        height: 55px;
        padding: 0 30px;
        line-height: 55px;
        font-size: 22px;
        margin-bottom: 40px;
    }

    .main1-2 {
        width: 327px;
    }

    .main1-2-1 {
        height: auto;
        display: block;
    }

    .main1-2-1-2 {
        display: none;
    }

    .main1-2-2-box {
        height: 180px;
    }

    .main1-3 {
        width: 327px;
    }

    .main1-4-2 {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .main1-5-2 {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .main1-5-2-1 {
        width: 100%;
        height: 260px;
    }

    .main1-5-2-2 {
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 30px;
    }

    .main1-5-2-2-1 {
        width: 100%;
        font-size: 17px;
        line-height: 30px;
    }

    .main1-5-2-2-3 {
        width: 100%;
    }

    .main1-3-1 {
        margin: 60px 0 30px;
        display: block;
    }

    .main1-3-1-1 {
        min-height: 28px;
        line-height: 28px;
        font-size: 28px;
    }

    .main1-3-1-2 {
        display: none;
    }

    .main1-2-2-box-text {
        height: 58px;
        padding: 6px 24px 0;
    }

    .main1-5-2-2-2 {
        margin-bottom: 20px;
    }

    .main1-5-2-2-2 img {
        width: 30px;
    }

    .main1-4-2-1 {
        padding: 24px 0 0 32px;
        height: 180px;
    }

    .main1-4-2-2 {
        padding: 24px 0 0 32px;
        right: 150px;
        width: calc(100% - 150px);
    }

    .main1-4-2-3 {
        background-size: 156px;
        width: 156px;
    }

    .main1-4-2-3 img {
        height: 180px;
    }

    .main1-4-2-2-1 {
        font-size: 24px;
        line-height: 32px;
        height: 32px;
        margin-bottom: 0;
        white-space: nowrap;
    }

    .main1-4-2-2-2 {
        margin-top: 12px;
        line-height: 16px;
        height: 70px;
        font-size: 12px;
        width: 100%;
    }

    .main1-4-2-2-3 {
        left: 32px;
        padding: 0 3px;
        height: 24px;
        width: auto;
        bottom: 24px;
        background-color: #fffbf0;
        min-width: 20px;
    }

    .main1-4-2-2-3 img {
        width: 12px;
    }

    .main1-4-2-1:hover .main1-4-2-2-3 {
        min-width: 35px;
        padding: 0 10px;
    }

    .main1-4-2-2-4 {
        line-height: 24px;
        font-weight: 600;
        height: 24px;
        font-size: 12px;
        font-weight: 500;
        margin: 0 6px;
    }
}

@media (max-width: 480px) {
    .main1-1-box {
        width: calc(100% - 40px);
        padding: 30px 20px 0;
        margin: 0;
    }
    .main1-1-box-3-1 {
        width: 367px;
        height: 50x;
    }
    .main1-1-box-3-1 input {
        padding: 20px 13px;
    }
    .main1-2 {
        width: 300px;
    }
    .main1-3 {
        width: 300px;
    }
}