.wallet {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 64px);
    padding-top: 90px;
    padding-bottom: 50px;
    animation: start .4s ease-out;
}

.wallet.dark {
    background: #1f2029;
    color: #a5a5a5;
}

.wallet .wallet-small.swiper-slide {
    width: auto;
}

.wallet .wallet-big.swiper-slide {
    width: auto;
}

.wallet-right-bottom-1 {
    width: 100%;
    display: inline-flex;
    gap: 24px;
    margin-bottom: 10px;
}

.wallet-right-bottom-1-1 {
    position: relative;
    display: block;
    padding: 6px 0;
    font-weight: 700;
    cursor: pointer;
}

.wallet-right-bottom-1-1:hover {
    color: var(--main-color);
}

.wallet-right-bottom-1-1.current {
    border-bottom: 3px solid var(--main-color);
}

.wallet-right-bottom-2 {
    margin-top: 24px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.wallet-right-bottom-2-1 {
    width: 100%;
    min-width: 272px;
    max-width: 272px;
    border: 1px solid #f9f9f9;
    border-radius: var(--px-15);
    padding: 6px 12px;
}

.wallet-right-bottom-2-1.dark {
    border: 1px solid #cccccc16;
    background: #2a2c37;
}

.wallet-right-bottom-2-1 input {
    width: 100%;
    font-size: 13px;
    border: none;
    outline: none;
    background: #fff;
}

.wallet-right-bottom-2-1.dark input {
    background: #2a2c37;
}

.wallet-right-bottom-2-1:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.wallet-right-bottom-2-2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
}

.wallet-right-bottom-2-2-span {
    font-size: 12px;
}

.wallet-right-bottom-4-bottom {
    width: 100%;
    height: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid #f9f9f9;
    border-radius: var(--px-15);
    font-size: 14px;
    margin-top: 10px;
}

.wallet-right-bottom-4-bottom.dark {
    border: 1px solid #cccccc16;
}

.wallet-right-bottom-4-top-1 {
    width: 100%;
    font-size: 12px;
    opacity: .5;
    padding: 6px 12px;
}

.wallet-right-bottom-3 {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f9f9f9;
    /* padding: 12px 0; */
    font-size: 14px;
    display: flex;
    align-items: center;
}

.wallet-right-bottom-3.dark {
    border-bottom: 1px solid #cccccc16;
}

.wallet-right-bottom-3-1-0 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0px 12px;
}

.wallet-right-bottom-3-1-1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
}

.wallet-right-bottom-3-1-2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 0px 12px;
}

.wallet-right-bottom-3-1-22 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.wallet-right-bottom-3 img {
    width: 25px;
}

.wallet-right-bottom-3-2 {
    width: auto !important;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--main-color) !important;
    color: #fff !important;
    height: 24px;
    border-radius: 3px;
    font-size: 11px;
    padding: 0 2px;
}

.wallet-right-form {
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.wallet-right-form-1 {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    gap: 24px;
}

.wallet-right-form-1-box {
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.wallet-right-form-1-box-1 {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    white-space: nowrap;
}

.wallet-right-form-1-box-1-label {
    transition: all 0.3s ease-in;
    width: 38px;
    height: 22px;
    display: block;
    position: relative;
    border-radius: var(--px-30);
    border: 1px solid #ececec;
    cursor: pointer;
}

.wallet-right-form-1-box-1-label.dark {
    border: 1px solid #cccccc16;
}

.wallet-right-form-1-box-1-btn {
    transition: all 0.3s ease-in;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    border-radius: 50%;
    background: var(--main-color);
}

.toggled {
    background-color: var(--main-color);
}

.toggled .wallet-right-form-1-box-1-btn.slider {
    left: 20px;
    background-color: #fff;
}

.wallet-right-form-2 {
    width: 100%;
    margin-bottom: 30px;
}

.wallet-right-form-2-1 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
    font-size: 14px;
}

.wallet-right-form-2-2 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.wallet-right-form-2-3 {
    width: 100%;
    border: 1px solid #f9f9f9;
    border-radius: var(--px-15);
    padding: 16px;
}

.wallet-right-form-2-3.dark {
    border: 1px solid #cccccc16;
}

.wallet-right-form-2-3-tit {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.wallet-right-form-2-3-txt {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--main-color);
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
}

.wallet-right-form-2-3-txt1 {
    font-weight: 700;
    min-height: 38px;
    text-align: left;
}

.wallet-right-form-2-3-img {
    margin: 12px 0;
}

.wallet-right-form-2-3-img img {
    width: 88px;
    height: 88px;
}

.wallet-right-form-2-3-text {
    padding-left: 32px;
    text-align: left;
}

.wallet-right-form-3 {
    width: 100%;
    margin-bottom: 30px;
    text-align: left;
}

.wallet-right-form-3-1 {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    gap: 24px;
}

.wallet-right-form-3-2 {
    margin-top: 12px;
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 16px;
}

.wallet-right-form-3-2::-webkit-scrollbar {
    width: 5px;
}

.wallet-right-form-3-2::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.wallet-right-form-3-2-1:first-child {
    padding: 0 0 30px 0;
    border-bottom: 1px solid #ececec;
}

.wallet-right-form-3-2-1.dark:first-child {
    border-bottom: 1px solid #cccccc16;
}

.wallet-right-form-3-2-1 {
    padding: 30px 0;
    border-bottom: 1px solid #ececec;
}

.wallet-right-form-3-2-1.dark {
    border-bottom: 1px solid #cccccc16;
}

.wallet-right-form-3-2-2 {
    font-weight: 700;
    transition: all .2s ease-in-out;
    font-size: 15px;
}

.wallet-right-form-3-2-3 {
    margin-top: 3px;
    font-size: 14px;
    opacity: .5;
}

.wallet-right-form-3-2-4 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    margin-top: 12px;
    font-size: 14px;
}

.wallet-right-form-3-2-5 {
    color: var(--main-color);
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.wallet-right-form-3-2-5 svg {
    width: 7px;
    height: 7px;
    fill: none;
    stroke: var(--main-color);
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.wallet-right-form-4 {
    width: calc(100% - 32px);
    background: #f9f9f9;
    padding: 16px;
    border-radius: var(--px-15);
    text-align: left;
    position: relative;
    font-size: 15px;
}

.wallet-right-form-4.dark {
    background: #2a2c37;
}

.wallet-right-form-4-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    font-weight: 700;
}

.wallet-right-form-4-1-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 6px;
    font-size: 14px;
    gap: 6px;
}

.wallet-right-form-4-1-1-span {
    font-size: 14px;
    opacity: .5;
    line-height: 1;
    font-weight: 400;
}

.wallet-right-form-4-2 {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    gap: 20px;
}

.wallet-right-form-4-3 {
    width: calc(100% - 32px);
    border: 1px solid #ececec;
    background: transparent !important;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 16px;
    border-radius: var(--px-30);
    position: relative;
    outline: none;
    cursor: pointer;
}

.wallet-right-form-4-3.dark {
    border: 1px solid #cccccc16;
    color: #dbdbdb;
}

.wallet-right-form-4-3-1 {
    width: calc(100% - 32px);
    height: 5px;
    border: 1px solid #fff;
    background: transparent !important;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 16px;
    /* border-radius: 10px; */
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    outline: none;
}

.wallet-right-form-4-3-1 select {
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: inherit;
}

.wallet-right-form-4-3-1.dark select {
    color: #dbdbdb;
}

.wallet-right-form-4-3-1.dark {
    border: 1px solid #cccccc16;
    background: #2a2c37 !important;
}

.wallet-right-form-4-3-input {
    border: none;
    outline: none;
    width: 100%;
    background: inherit;
    color: inherit;
}

.wallet-right-form-4-3-span {
    font-size: 14px;
    opacity: .5;
    line-height: 1;
}

.wallet-right-form-4-3-btn {
    background: var(--main-color) !important;
    color: #fff !important;
    border-radius: var(--px-15);
    font-size: 12px;
    padding: 3px 6px;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
}

.wallet-right-form-4-4 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    margin-top: 6px;
}

.wallet-right-form-4-5 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.wallet-right-form-4-6 {
    cursor: pointer;
    width: 100%;
    background: #ddd;
    border-radius: var(--px-15);
    padding: 6px 16px;
    font-size: 13px;
    text-align: center;
}

.wallet-right-form-4-6.dark {
    background: #2a2c37;
}

.wallet-right-form-5 {
    font-size: 13px;
    opacity: .5;
    text-align: left;
}

.wallet-right-form-6 {
    width: 100%;
    display: inline-flex;
    gap: 12px;
}

.wallet-right-form-4-7 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.wallet-right-form-4-7-1 {
    padding: 5px;
    /* background: #fff; */
}

.wallet-right-form-4-7-1 img {
    max-width: 102px;
    max-height: 102px;
    margin: auto;
}

.wallet-right-form-4-8 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: 6px;
}

.wallet-right-form-4-8-1 {
    cursor: pointer;
    background: var(--main-color) !important;
    color: #fff !important;
    border-radius: var(--px-15);
    padding: 6px 12px;
    text-transform: uppercase;
    font-size: 14px;
}

.wallet-right-form-4-7-no {
    width: 100%;
    height: 142px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--px-15);
    background: rgb(0 0 0 / 50%);
    color: #121214;
    text-transform: uppercase;
    font-size: 14px;
    opacity: .5;
}

.wallet-right-form-4-7-1-no {
    padding: 5px;
    background: #979595;
}

.wallet-right-form-4-7-1-no div {
    width: 102px;
    height: 102px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wallet-right-form-7 {
    margin-top: 48px;
}

.wallet-right-form-7-1 {
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.wallet-right-form-7-2 {
    text-align: right !important;
    font-size: 25px;
    padding-right: 10px;
}

.wallet-right-form-7-2 svg {
    cursor: pointer;
}

.wallet-right-form-7-3 {
    width: 100%;
    margin-top: 10px;
}

.wallet-right-form-7-3-top {
    width: calc(100% - 24px);
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    font-size: 12px;
    background: #f9f9f9;
    border-radius: var(--px-15);
    text-align: center;
    gap: 10px;
}

.wallet-right-form-7-3-top.dark {
    background: #2a2c37;
}

.wallet-right-form-7-3-top-1 {
    width: 30%;
    text-align: left;
    display: inline-flex;
    align-items: center;
    text-align: center;
}

.wallet-right-form-7-3-top-1-1 {
    width: 33%;
    text-align: left;
    display: inline-flex;
    align-items: center;
    text-align: center;
}

.wallet-right-form-7-3-top-2 {
    width: 50%;
    opacity: .5;
    text-align: center;
}

.wallet-right-form-7-3-top-2-1 {
    width: 100%;
    opacity: .5;
    text-align: center;
}

.wallet-right-form-7-3-top-3 {
    width: 25%;
    display: inline-flex;
    align-items: center;
    text-align: center;
}

.wallet-right-form-7-3-top-4 {
    width: 12%;
    opacity: .5;
    text-align: center;
}

.wallet-right-form-7-3-bottom-1 {
    width: calc(100% - 24px);
    display: inline-flex;
    align-items: center;
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #f9f9f9;
    font-size: 13px;
    gap: 10px;
}

.wallet-right-form-7-3-bottom-1.dark {
    border-bottom: 1px solid #cccccc16;
}

.wallet-right-form-7-3-bottom-2 {
    width: 30%;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.wallet-right-form-7-3-bottom-2-1 {
    width: 33%;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.wallet-right-form-7-3-bottom-3 {
    width: 50%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.wallet-right-form-7-3-bottom-3-1 {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #cfcdcd;
    background-color: #fff;
    padding: 5px 10px;
    z-index: 1;
    max-width: 70vw;
    white-space: wrap;
    word-wrap: break-word;
}

.wallet-right-form-7-3-bottom-3-1.dark {
    background-color: #2a2c37;
    border: 1px solid #cccccc16;
}

.wallet-right-form-7-3-bottom-3-2 {
    width: 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.wallet-right-form-7-3-bottom-4 {
    width: 25%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
}

.wallet-right-form-7-3-top-5 {
    width: 12%;
    text-align: center;
    position: relative;
    white-space: nowrap;
}

.wallet-right-form-7-3-top-6 {
    width: 100%;
    text-align: left;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.wallet-right-form-7-3-bottom-5 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: .5;
}

.wallet-right-form-7-4 {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 12px;
    margin: 20px 0;
}

.wallet-right-form-7-4-1 {
    padding: 12px 16px;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    background: #f9f9f9;
    border-radius: var(--px-30);
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.wallet-right-form-7-4-1.dark {
    background: #2a2c37;
}

.wallet-right-form-7-4-1.current {
    background: var(--main-color) !important;
    color: #fff;
}

.wallet-right-form-8 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin-top: 20px;
}

.wallet-right-form-8-1 {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 5px;
    position: relative;
    margin-top: 0;
    width: 30px;
    height: 30px;
}

.wallet-right-form-8-1.dark {
    border: 1px solid #cccccc16;
}

.wallet-right-form-8-1:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.wallet-right-form-8-2 {
    text-align: center;
    width: 100%;
    border: none;
    outline: none;
    background-color: #f9f9f9;
}

.wallet-right-form-8-2.dark {
    background-color: #2a2c37;
}

.wallet-right-form-9 {
    width: 100%;
}

.wallet-right-form-9-1 {
    cursor: pointer;
    width: calc(100% - 48px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #242424 !important;
    color: #fff !important;
    font-size: 14px;
    padding: 12px 24px;
    border-radius: var(--px-30);
    text-transform: uppercase;
}

.wallet-right-form-9-1:hover {
    background: #454545 !important;
}

.wallet-right-form-9-1.dark {
    background: #2a2c37 !important;
    color: #fff !important;
}

.wallet-right-form-9-1.dark:hover {
    background: #717e8812 !important;
}

.wallet-right-form-10 {
    background: #f9f9f9;
    padding: 16px;
    border-radius: var(--px-15);
    font-size: 14px;
}

.wallet-right-form-10.dark {
    background: #2a2c37;
}

.wallet-right-form-10-1 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    gap: 12px;
}

.wallet-right-form-10-2 {
    width: 100%;
    text-align: left;
}

.wallet-right-form-10-3 {
    width: 100%;
    text-align: right;
}

.wallet-right-form-10-4 {
    width: 100%;
    background: #fff;
    padding: 6px 12px;
    /* border-radius: 10px; */
    border-radius: 3px;
    text-align: left;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wallet-right-form-10-4.dark {
    background: #2a2c37;
    border: 1px solid #cccccc16;
}

.wallet-right-form-10-5 {
    font-size: 3rem;
    cursor: pointer;
}

.wallet-right-form-10-1-1 {
    width: 100%;
    font-weight: 700;
    font-size: 15px;
    color: #999;
    display: flex;
    align-items: center;
}

.wallet-right-form-10-1-1 img {
    width: 21px;
    padding-right: 5px;
}

.wallet-right-form-4-9 {
    width: calc(100% - 32px);
    display: inline-grid;
    padding: 12px 16px;
    border: 1px solid #ececec;
    /* border-radius: 10px; */
    border-radius: 3px;
    align-items: center;
}

.wallet-right-form-4-9.dark {
    border: 1px solid #cccccc16;
}

.wallet-right-form-4-9-1 {
    height: 25px;
    resize: none;
    width: 100%;
    background: none;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    color: inherit;
}

.wallet-right-form-4-9-2 {
    font-size: 14px;
    opacity: .5;
    text-align: right;
}

.wallet-display-none-800-ok {
    display: none;
}

.history-right-bottom-span {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 3px;
}

.wallet-right-bottom1-modal {
    background: rgba(0, 0, 0, .3);
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    text-align: left;
}

.wallet-right-bottom1-modal-1 {
    max-width: 420px;
    width: 100% !important;
    padding: 0 24px;
    margin: auto;
}

.wallet-right-bottom1-modal-1-1 {
    background: #fff;
    padding: 36px;
    border-radius: var(--px-15);
    position: relative;
    min-height: 412px;
}

.wallet-right-bottom1-modal-1-1.dark {
    background: #2a2c37;
    border: 1px solid #cccccc16;
}

.wallet-right-bottom1-modal-1-1-tit {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.2;
    position: relative;
    margin-bottom: 24px;
}

.wallet-right-bottom1-modal-1-1-tit svg {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
    cursor: pointer;
}

.wallet-right-bottom1-modal-1-1-wrap {
    width: 100%;
    /* border-radius: 10px; */
    border-radius: 3px;
}

.wallet-right-bottom1-modal-1-1-wrap.dark {
    background: #2a2c37;
}

.wallet-right-bottom1-modal-1-1-txt {
    width: calc(100% - 32px);
    display: inline-flex;
    align-items: center;
    gap: 6px;
    background: #f9f9f9;
    /* border-radius: 10px; */
    border-radius: 3px;
    padding: 10px 16px;
    position: relative;
    margin-bottom: 6px;
}

.wallet-right-bottom1-modal-1-1-txt.dark {
    border: 1px solid #cccccc16;
    background-color: #2a2c37;
}

.wallet-right-bottom1-modal-1-1-txt:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.wallet-right-bottom1-modal-1-1-txt input {
    width: 100%;
    font-size: 14px;
    border: none;
    transition: all .2s ease-in-out;
    outline: none;
    appearance: none;
    opacity: .5;
    background: inherit;
    color: inherit;
}

.wallet-right-bottom1-modal-1-1-txt svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
}

.wallet-right-bottom1-modal-1-2 {
    max-height: 320px;
    overflow-y: auto;
}

.wallet-right-bottom1-modal-1-2::-webkit-scrollbar {
    width: 10px;
}

.wallet-right-bottom1-modal-1-2::-webkit-scrollbar-thumb {
    background-color: #d5d5d5;
}

.wallet-right-bottom1-modal-1-2-1 {
    width: calc(100% - 12px);
    display: inline-flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;
    padding: 6px;
    /* border-radius: 10px; */
    border-radius: 3px;
    transition: all .2s ease-in-out;
}

.wallet-right-bottom1-modal-1-2-2 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.wallet-right-bottom1-modal-1-2-img {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    overflow: hidden;
    border: 1px solid rgb(249 249 249 / 10%);
    border-radius: 50%;
}

.wallet-right-bottom1-modal-1-2-img img {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
}

.wallet-right-bottom1-modal-1-2-name {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: 3px;
}

.wallet-right-bottom1-modal-1-2-name p {
    opacity: .5;
}

.wallet-right-form-4-3:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.dashboard-modal-2-tit:focus-within {
    box-shadow: var(--main-color1) 0px 0px 24px;
    border: 1px solid var(--main-color1);
    transition: all .2s ease-in-out;
}

.wallet-right-form-4-3-box {
    width: 100%;
    height: auto;
    background-color: #fff;
    /* border-radius: 10px; */
    border-radius: 3px;
    border: 1px solid #dbdbdb;
    position: absolute;
    z-index: 10;
    top: 44px;
}

.wallet-right-form-4-3-box.dark {
    background-color: #2a2c37;
    border: 1px solid #cccccc16;
}

.wallet-right-form-4-3-box-1 {
    width: calc(100% - 40px);
    padding: 15px 20px;
    cursor: pointer;
    position: relative;
    /* border-radius: 10px; */
    border-radius: 3px;
}

.wallet-right-form-4-3-box-1:hover {
    background-color: #efeeee;
}

.wallet-right-form-4-3-box-1.dark:hover {
    background-color: #717e8812;
}

.withdraw-country {
    max-width: 75px;
    position: absolute;
    left: 20px;
    z-index: 10;
    border-right: 1px solid #ececec;
}

.withdraw-country.dark {
    border-right: 1px solid #cccccc16;
}













@media (max-width:1080px) {
    .wallet-right-form-2-1 {
        flex-wrap: wrap;
    }
}

@media (max-width: 800px) {
    .wallet-display-none-800 {
        display: none;
    }

    .wallet-display-none-800-ok {
        display: flex;
    }

    .wallet-right-bottom-3-1-2 {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
    }

    .wallet-right-bottom-3-1-22 {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
    }

    .wallet-right-bottom-3-2 {
        width: 70px !important;
        display: flex;
        height: 30px;
        font-size: 12px;
    }

    .wallet-right-form-7-3-top-1 {
        flex-wrap: wrap;
        width: 35%;
    }

    .wallet-right-form-7-3-top-3 {
        flex-wrap: wrap;
        width: 30%;
    }

    .wallet-right-form-7-3-bottom-2 {
        flex-wrap: wrap;
        width: 35%;
    }

    .wallet-right-form-7-3-bottom-4 {
        flex-wrap: wrap;
        width: 30%;
    }

    .wallet-right-form-7-3-top-2 {
        width: 100%;
    }

    .wallet-right-form-7-3-bottom-3 {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .wallet-right-bottom-2-1 {
        width: 100%;
        min-width: 150px;
        max-width: 150px;
        border: 1px solid #f9f9f9;
        border-radius: var(--px-15);
        padding: 6px 12px;
    }

    .wallet-right-form-2-2 {
        flex-wrap: wrap;
    }

    .wallet-right-form-2-3 {
        width: calc(100% - 32px);
    }

    .wallet-right-form-4-4 {
        width: 100%;
        display: inline-flex;
        align-items: center;
        gap: 6px;
        margin-top: 6px;
    }

    .wallet-right-form-5 {
        font-size: 12px;
    }

    .wallet-right-form-4-8 {
        font-size: 12px;
    }

    .wallet-right-form-4-8-1 {
        padding: 6px 10px;
        font-size: 12px;
    }

    .wallet-right-form-6 {
        flex-direction: column;
    }

    .wallet-right-form-7-3-bottom-1 {
        font-size: 11px;
    }

    .wallet-right-form-10-4 {
        font-size: 13px;
    }

    .wallet-right-form-10-5 {
        font-size: 2rem;
    }

    .wallet-right-form-10-1 {
        font-size: 13px;
    }

    .wallet-right-form-4 {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .wallet-right-form-4-4 {
        flex-wrap: wrap;
    }

    .regist-box-left-form-3-span1 {
        width: 15px;
        min-width: 15px;
        height: 15px;
    }

    .regist-box-left-form-3-span1::before {
        top: 7px;
        left: 3px;
    }

    .regist-box-left-form-3-span1::after {
        top: 11px;
        left: 7px;
        transform: rotate(220deg);
    }

    .wallet-right-bottom-2-2-span {
        font-size: 11px;
    }

    .wallet-right-bottom-2-1 input {
        font-size: 11px;
    }

    .wallet-right-bottom-2-1 {
        min-width: 120px;
        max-width: 120px;
    }

    .history-right-bottom-3-1-box {
        gap: 5px;
        padding: 12px;
    }

    .history-right-bottom-3-1-box-txt-1 {
        font-size: 18px;
    }

    .history-right-bottom-span {
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 3px;
    }

    .history-right-bottom-3-1-box-txt {
        font-size: 18px;
    }

    .wallet-right-bottom-3 {
        font-size: 12px;
    }

    .wallet-right-bottom-3 img {
        width: 18px;
    }
}